import {createActionGroup, emptyProps, props} from '@ngrx/store';

export const form = createActionGroup({
    source: 'CAMPAIGN FORM',
    events: {
        init: props<{ user: any }>(),
        setChecked: emptyProps(),
        setValue: props<{ form: any }>(),
        setInitialValue: props<{ form: any }>(),
        submit: emptyProps(),
        createSuccess: props<{ campaign: any }>(),
        createFailure: props<{ message: any }>(),
        clear: emptyProps(),
    },
});
export const getCampaignById = createActionGroup({
    source: 'CAMPAIGN FORM - GET BY ID',
    events: {
        request: props<{ id: number }>(),
        success: props<{ campaign: any }>(),
        failure: props<{ message: string }>(),
    },
});

export const dialog = createActionGroup({
    source: 'RECIPIENTS LIST DETAILS',
    events: {
        openDetailsRecipientsList: props<{
            id: number;
        }>(),
    },
});
