import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface RecipientsTableFilters {
	campaigns?: SelectFilterDefinition;
	search?: FilterDefinition;
}

export const INITIAL_RECIPIENTS_FILTERS: RecipientsTableFilters = {
	campaigns: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: null,
		label: 'Campaigns',
		multiple: true,
		options: [],
	},
	...SEARCH_FILTER,
};
