<div #formField class="picker">
    <div class="picker__input">
        <rds-form-field [formGroup]="form">
            <rds-control-label>
                <span>{{label}}</span>
            </rds-control-label>
            <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
            <input
                    [disabled]="control.disabled ? true : false"
                    rdsInput
                    (focus)="focus()"
                    (blur)="blur()"
                    formControlName="search"
            />
        </rds-form-field>
        <ng-container *ngIf="autocomplete.loading">
            <div class="picker__input--loader">
                <rds-progress-bar></rds-progress-bar>
            </div>
        </ng-container>
    </div>

    <div rdsDropdown class="picker__autocomplete">
        <button rds-dropdown-toggle-button size="l" class="picker__autocomplete--trigger"
                [rdsMenuTriggerFor]="menu"
                #trigger="rdsMenuTrigger"
                [isOpen]="trigger.menuOpen"
        >Toggle
        </button>
        <rds-menu #menu="rdsMenu" class="picker__autocomplete--options">
            <ng-container *ngIf="!autocomplete.loading">
                <cdk-virtual-scroll-viewport itemSize="56" [ngStyle]="{'width.px': formField.offsetWidth, 'height.px': [300,56*autocomplete.suggestions.length] | min}">
                    <div *cdkVirtualFor="let option of autocomplete.suggestions" rds-menu-item
                    (click)="selectedIncludesRada(option)? remove(option) : select(option)"
                    [class.is-selected]="selectedIncludesRada(option)">
                   <div class="row row-nowrap">
                       <div class="col">
                           <p class="ui-label-m text-ellipsis"
                              [innerHTML]="option.name | highlight:form.controls.search.value"></p>
                           <p class="ui-label-s text-ellipsis">{{option.description}}</p>
                       </div>
                   </div>

               </div>
                  </cdk-virtual-scroll-viewport>

            </ng-container>
        </rds-menu>
    </div>
    <div class="picker__selected">
        <rds-chip-list size="s" type="filter">
            <rds-chip *ngIf="selectedRadaGroups.length === 0" [disabled]="true">{{chipPlaceholder}}</rds-chip>
            <rds-chip-selectable *ngFor="let selected of selectedRadaGroups; let index = index;"
                                 [closable]="!control.disabled ? true : null"
                                 (closeChip)="remove(index)" (click)="details.emit(selected)"
            >{{selected.displayName || selected.name}}</rds-chip-selectable>
        </rds-chip-list>
    </div>
</div>
<rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
    <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
    <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rh-subscript-wrapper>
