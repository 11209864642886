import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import * as fromActions from './suggestions.actions';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { CatalogsService } from '@app/core/services/catalogs.service';
import { ApiClient } from '@app/core/services/api-newsboard/api-client.service';
import { ContentService } from '@app/core/services/content.service';
import { EventsService } from '@app/core/services/managing-events.service';
import { NewsletterService } from '@app/core/services/newsletter.service';

@Injectable()
export class SuggestionsEffects {
	public loadSuggestion$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestion),
				filter(({ suggestionType, prop, phrase }) => phrase?.length > 2),
				distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
				debounceTime(200),
				map(({ suggestionType, prop, phrase }) => fromActions.loadSuggestionRequest({ suggestionType, prop, phrase }))
			),
		{ dispatch: true }
	);

	public loadUserSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'user'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.nbclient
						.get(`/users/${encodeURIComponent(phrase)}`)
						.pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadTopicNewsSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'topic-news'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.nbclient
						.get(`/news/topics?phrase=${encodeURIComponent(phrase)}`)
						.pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadRecipientsListsSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'recipients-list'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.newsletterService.getAllRecipientsLists().pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadRadaSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'rada-groups'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.newsletterService
						.searchRada(phrase)
						.pipe(map((response) => ({ prop, suggestions: response.googleGroups })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadTopicEventsSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'topic-event'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.eventsService.getTopicSearch(phrase).pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadCatalogSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'catalog'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.catalogsService.getCatalogSearch(phrase).pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadCatalogKeywordsSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'catalog-keyword'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.catalogsService.getKeywordSearch(phrase).pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadChannelSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'channel'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.contentService
						.putFilterAllChannels({
							name: phrase,
							departments: [],
							locations: [],
							functions: [],
						})
						.pipe(map((response) => ({ prop, suggestions: response })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public loadEventsSuggestionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestionRequest),
				filter(({ suggestionType, phrase }) => suggestionType === 'event'),
				debounceTime(200),
				switchMap(({ prop, phrase }) =>
					this.eventsService
						.getGrid({
							departments: [],
							endDate: null,
							functions: [],
							locations: [],
							pageIndex: 0,
							pageSize: 1000,
							search: phrase,
							startDate: new Date(0),
							types: [],
						})
						.pipe(map((response) => ({ prop, suggestions: response.events })))
				),
				map(({ prop, suggestions }) => fromActions.loadSuggestionSuccess({ prop, suggestions })),
				catchError(({ prop, error }) => of(fromActions.loadSuggestionFailure({ prop, error })))
			),
		{ dispatch: true }
	);

	public clearSuggestion$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.loadSuggestion),
				filter(({ suggestionType, prop, phrase }) => !phrase || phrase.length === 0),
				debounceTime(200),
				map(({ suggestionType, prop, phrase }) => fromActions.clearSuggestion({ suggestionType, prop, phrase }))
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private nbclient: ApiClient,
		private catalogsService: CatalogsService,
		private contentService: ContentService,
		private eventsService: EventsService,
		private newsletterService: NewsletterService,
		private store$: Store<any>
	) {}
}
