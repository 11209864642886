import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';
import { combineLatest } from 'rxjs';
import { toDateString } from '@core/services/utils.service';
import { validateIsFuture, validateTime } from '@shared/form-controls/validators/validator.function';

// import * as fromTimeZones from '@core/core-store/timezones/timezones.selectors';

@Component({
	selector: 'rh-scheduler',
	templateUrl: './scheduler.component.html',
	styles: [],
})
export class SchedulerComponent {
	// @Input() inputForm: FormGroup;
	@Input() timezoneDict;
	// timezoneDict$: Observable<any> = this.store$.pipe(select(fromTimeZones.selectAll));
	form: FormGroup = new FormGroup({
		scheduleTime: new FormControl('', [Validators.required, validateTime]),
		scheduleDate: new FormControl('', [Validators.required, validateIsFuture]),
		timeZone: new FormControl('', [Validators.required]),
		scheduleSentDate: new FormControl('', [Validators.required]),
	});

	_inputForm: FormGroup;

	@Input() set inputForm(form: FormGroup) {
		this._inputForm = form;
		this.form.patchValue(form.value, { emitEvent: false });
	}

	get inputForm(): FormGroup {
		return this._inputForm;
	}

	filterBy: RdsSingleSelectFilterByFunc<string> = (
		text: string | null,
		item: RdsSingleSelectOptionComponent<string>
	) => {
		if (text === null || text === '') {
			return true;
		} else {
			return (
				item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase())
			);
		}
	};

	constructor() {
		this.form.valueChanges.subscribe((value) => {
			this._inputForm.patchValue({
				...value,
			});
		});

		combineLatest([this.form.get('scheduleDate').valueChanges, this.form.get('scheduleTime').valueChanges]).subscribe(
			([date, time]) => {
				const dateString = toDateString(date, time);
				this._inputForm.patchValue({
					scheduleSentDate: this._inputForm.get('scheduleSentDate')?.value || dateString || null,
				});
				this.form.patchValue(
					{
						scheduleSentDate: this._inputForm.get('scheduleSentDate')?.value || dateString || null,
					},
					{ emitEvent: false }
				);
				this.form.markAllAsTouched();
				this.form.updateValueAndValidity();
			}
		);
		this.form.get('scheduleDate').valueChanges.subscribe((value) => {
			const defaultTime = '00:00';
			if (!this.form.get('scheduleTime').value) {
				this.form.patchValue({ scheduleTime: defaultTime }, { emitEvent: true });
			}
		});
	}
}
