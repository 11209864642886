import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {debounceTime, filter, map, tap} from 'rxjs/operators';
import * as fromActions from './toast.actions';

import * as fromCatalogEntry from '@app/catalog-entry/store';
import * as fromGenericAccountsForm
  from '@app/generic-accounts/store/generic-account-form/generic-account-form.actions';
import * as fromGenericAccountsTable
  from '@app/generic-accounts/store/generic-accounts-table/generic-accounts-table.actions';
import * as fromChannelsSetForm from '@app/manage-sets/store/channels-sets-form';
import * as fromChannelsSetTable from '@app/manage-sets/store/channels-sets-table';
import * as fromQuicklinksSetForm from '@app/manage-sets/store/quicklinks-sets-form';
import * as fromQuicklinksSetTable from '@app/manage-sets/store/quicklinks-sets-table';
import * as fromNews from '@app/news-details/store/news/news.actions';
import * as fromNewsComments from '@app/news-details/store/comments/comments.actions';
import * as fromGlobalSettings from '@app/user-settings/store/global-settings';
import * as fromNotificationSettings from '@app/user-settings/store/notification-settings';
import * as fromSearchingPreferences from '@app/user-settings/store/searching-preferences';
import * as fromUserNewsArticles from '@app/workday-user/store/user-news-articles';

import * as fromAlerts from '@app/alerts/store/alerts-form/alerts-form.actions';
import * as fromAllContentTable from '@app/content-manager/store/all-content/all-content.actions';
import * as fromMyContentTable from '@app/content-manager/store/my-content/my-content.actions';
import * as fromContentItems from '@app/core/core-store/content-items/content-items.actions';
import * as fromEventDetails from '@app/events/store/details';
import * as fromFiltersEvents from '@app/events/store/filters/filters.actions';
import * as fromNewsfeedSubscriptions from '@app/home/store/newsfeed/newsfeed.actions';
import * as fromSubscriptions from '@app/home/store/subscriptions/subscriptions.actions';
import * as fromHeaderCatalogs from '@app/layout/header/store/catalogs/catalogs.actions';
import * as fromHeaderQuickLinks from '@app/layout/header/store/quick-link/quick-link.actions';
import * as fromManagingEventsForm from '@app/managing-events/store/form';
import * as fromManagingEventsTable from '@app/managing-events/store/table';
import * as fromNewsRelated from '@app/news-details/store/related-section/related-section.actions';
import * as fromNewsletterDashboard from '@app/newsletter/store/newsletter-dashboard';
import * as fromNewsletterInline from '@app/newsletter/store/newsletter-inline';
import * as fromNewsletterOther from '@app/newsletter/store/newsletter-other';
import * as fromNewsletterSavedTemplates from '@app/newsletter/store/newsletter-saved-templates';
import * as fromNewsletterShare from '@app/newsletter/store/newsletter-share/newsletter-share.actions';
import * as fromNewsletterWebSocket from '@app/newsletter/store/newsletter-websocket';
import * as fromRhSearchResults from '@app/search/store/rh-search/rh-search.actions';

import * as fromApiKeys from '@app/newsboard/admin/store/api-keys';
import * as fromChannelDetails from '@app/newsboard/channel/store/channel-details';
import * as fromChannels from '@app/newsboard/channel/store/channel-table';
import * as fromChannelForm from '@app/newsboard/channel/store/form';
import * as fromNewsForm from '@app/newsboard/news/store/form';
import * as fromNewsTable from '@app/newsboard/news/store/news-table';
import * as fromTemplateForm from '@app/newsboard/news/store/template-form';
import * as fromTemplates from '@app/newsboard/news/store/templates';
import * as fromRoleAssignForm from '@app/role-assign/store/form';
import * as fromTickerForm from '@app/ticker/store/ticker-form/ticker-form.actions';
import * as fromTickerTable from '@app/ticker/store/ticker-table/ticker-table.actions';

import * as fromNewsletterTable from '@app/newsletter-new/store/newsletter-table';
import * as fromRecipientsTable from '@app/newsletter-new/store/recipients-table';
import * as fromRecipientsForm from '@app/newsletter-new/store/recipients-form';
import * as fromCampaignForm from '@app/newsletter-new/store/campaign-form';
import * as fromCampaignTable from '@app/newsletter-new/store/campaign-table';
import * as fromNewNewsletterForm from '@app/newsletter-new/store/newsletter-form';

import {SubscriptionType} from '@app/home/store/subscriptions';
import {Store} from '@ngrx/store';
import {RdsToastService} from '@rds/angular-components';

@Injectable()
export class ToastEffects {
  public showToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.showToast),
        tap(({message, title, options}) => [this.toastService.show(message, title, options)])
      ),
    {dispatch: false}
  );

  public apiKeyCopied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromApiKeys.apiKeyCopiedToClipboard),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `API-Key copied to clipboard`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteChannelsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromChannels.deleteMyChannelsSuccess,
        fromChannels.deleteAllChannelsSuccess,
        fromChannelForm.deleteChannelSuccess
      ),
      map(({count}) =>
        fromActions.showToast({
          title: 'Success',
          message: `Channel${count > 1 ? 's were' : ' was'}  deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public pinNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelDetails.pinNewsSuccess),
      map(({isPinned}) =>
        fromActions.showToast({
          title: 'Success',
          message: `News is now ${isPinned ? 'pinned to' : 'unpinned from'} the top of the channel`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public rejectNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelDetails.rejectNewsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `The News has beed rejected`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public acceptNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelDetails.acceptNewsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Suggestion accepted. News published to your channel`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public duplicateNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelDetails.duplicateNewsSuccess, fromNewsTable.duplicateNewsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News duplicated and saved as draft in My News view`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public removeNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelDetails.removeAllNewsSuccess, fromChannelDetails.removeArchivedNewsSuccess),
      map(({count}) =>
        fromActions.showToast({
          title: 'Success',
          message: `The news was removed from channel`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelForm.createChannelSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Channel created`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelForm.updateChannelSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Changes saved`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public newsAssignedAndSuggested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => hasAssignChanged && hasSuggestChanged),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News assignment and suggestion updated`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public newsAssigned$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => hasAssignChanged && !hasSuggestChanged),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Assignment saved to channel`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public newsSuggested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.updateNewsAssignmentsSuccess),
      filter(({hasAssignChanged, hasSuggestChanged}) => !hasAssignChanged && hasSuggestChanged),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News suggestion updated`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9314-89706&mode=design&t=4A8rCLFiMvCLFCY1-4
  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9139-54788&mode=design&t=4A8rCLFiMvCLFCY1-4
  // MISSING https://www.figma.com/file/7GPVGe7UBKJBm4NuhEw2B0/NewsBoard-2.0?type=design&node-id=9314-89682&mode=design&t=4A8rCLFiMvCLFCY1-4

  public removeSuggestionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.removeSuggestionSuccess),
      map(({channelName}) =>
        fromActions.showToast({
          title: 'Success',
          message: `Suggestion removed from Channel ${channelName}`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromNewsTable.deleteMyNewsSuccess,
        fromNewsTable.deleteAllNewsSuccess,
        fromNewsTable.deleteMyArchivedNewsSuccess
      ),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News were deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public archiveNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.archiveMyNewsSuccess, fromNewsTable.archiveAllNewsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News were archived`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.deleteTemplateSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplateForm.saveTemplateSuccess, fromTemplateForm.updateTemplateSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template saved`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public sendTemplateCopySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.sendTemplateCopySuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `A copy of the template has been sent.`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public templateShared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.updateTemplateSuccess),
      filter(({updateContext}) => updateContext === 'shared'),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template shared`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public templatePermissionsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.updateTemplateSuccess),
      filter(({updateContext}) => updateContext === '' || updateContext === 'permissionsUpdated'),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `User permissions has been updated`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public templateSharedAndPermissionsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.updateTemplateSuccess),
      filter(({updateContext}) => updateContext === 'sharedAndPermissionsUpdated'),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template has been shared and User permissions has been updated`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public templateRenamed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplates.updateTemplateSuccess),
      filter(({updateContext}) => updateContext === 'renamed'),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template renamed`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public unpublishNewsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.unpublishNewsSuccess, fromNewsForm.unpublishSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News unpublished. It's now in draft status.`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveAsDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsDraftSuccess, fromNewsForm.updateAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News saved as draft`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveAsPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsPublishSuccess, fromNewsForm.updateAsPublishSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News published`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveAsTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsForm.saveAsTemplateSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Template saved`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveNewsAsTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsTable.saveNewsAsTemplateSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News saved as a template`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // TICKER
  public createTickerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTickerForm.createTickerSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'News was successfully published in the RocheHome Ticker.',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateTickerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTickerForm.updateTickerSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'News was successfully updated in the RocheHome Ticker.',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteTickerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTickerTable.deleteTickerSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Ticker was successfully deleted.',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  //  GENERIC ACCOUNTS

  public deleteGenericAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGenericAccountsTable.deleteGenericAccountSuccess),
      map(({username}) =>
        fromActions.showToast({
          title: 'Success',
          message: `Account with login "${username}" was successfully deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGenericAccountsForm.createGenericAccountSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `A new account was successfully created`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateGenericAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGenericAccountsForm.updateGenericAccountSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `An account was successfully edited`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public assignUserRoleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoleAssignForm.assignUserRoleSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `User role is now changed`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteUserRoleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoleAssignForm.deleteUserRoleSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `User no longer has role`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // MANAGE SETS
  public updateQuicklinksSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuicklinksSetForm.updateQuicklinksSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Quicklink set is now changed`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createQuicklinksSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuicklinksSetForm.createQuicklinksSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `New set is now in Quicklinks sets list`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteQuicklinksSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromQuicklinksSetForm.deleteQuicklinksSetSuccess, fromQuicklinksSetTable.deleteQuicklinksSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Quicklink set is no longer in your list`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // CHANNELS SETS
  public updateChannelsSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelsSetForm.updateChannelsSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Channel set is now changed`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createChannelsSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelsSetForm.createChannelsSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `New set is now in Channel sets list`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteChannelsSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromChannelsSetForm.deleteChannelsSetSuccess, fromChannelsSetTable.deleteChannelsSetSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Channel set is no longer in your list`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveNewsLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromGlobalSettings.saveNewsLanguageSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `News language are now in the language of your choice`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // PROFILE
  public savePersonalizationPreferencesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSearchingPreferences.savePersonalizationPreferencesSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Your preferences are now changed`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public resetPersonalizationPreferencesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSearchingPreferences.resetPersonalizationPreferencesSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Your preferences are now changed to default state`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveChannelSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationSettings.saveChannelSettingsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Notifications settings were successfully saved`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public resetChannelSettingsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNotificationSettings.resetChannelSettingsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Your notification settings are now changed to default state`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.deleteSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public rejectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.rejectSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog rejected`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public approveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.approveSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog approved`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public prolongSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.prolongSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog prolonged for another 12 months`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public catalogEntrySaveAsDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.saveAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog entry saved as draft`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateAsDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.updateAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog entry draft updated`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public sendForApprovalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.sendForApprovalSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog entry created and send for approval`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateForApprovalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCatalogEntry.updateForApprovalSuccess),
      map(({approvalRequired}) =>
        fromActions.showToast({
          title: 'Success',
          message: `Catalog entry updated and ${approvalRequired ? 'send for approval' : 'published'}`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public commentPosted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsComments.postCommentSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Comment posted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public commentReplied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsComments.sendReplySuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Reply posted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public commentDeleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsComments.deleteCommentSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Comment deleted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteEventSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromManagingEventsTable.deleteMyEventsSuccess, fromManagingEventsTable.deleteMyPastEventsSuccess),
      map(({count}) =>
        fromActions.showToast({
          title: 'Success',
          message: `Event${count > 1 ? 's were ' : ' was '}deleted`,
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public addToCalendarSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(),
      // fromHomeEvents.addToCalendarSuccess,
      // fromHGcalEvents.addEventToGcalSuccess,
      // fromDetailsEvents.addToCalendarSuccess
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Event has been added to calendar',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveDefaultEventFiltersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromFiltersEvents.saveFiltersSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Default filters saved',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public removeBookmarksuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromSubscriptions.removeBookmarkSuccess,
        fromNewsfeedSubscriptions.removeBookmarkSuccess,
        fromContentItems.removeBookmarkByContentIdSuccess,
        fromNews.removeBookmarkSuccess,
        fromNewsRelated.unbookmarkSuccess,
        fromUserNewsArticles.removeBookmarkSuccess
      ),
      debounceTime(100),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Removed news is no longer in My bookmarks',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public copyLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNews.copyLink, fromContentItems.copyLink),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'URL of this page has been copied to your clipboard',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  //EVENTS
  public getEventDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromEventDetails.getEventDetailsFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Event unavailable',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveEventSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromManagingEventsForm.saveAsPublishSuccess, fromManagingEventsForm.updateAsPublishSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Success. Event has been published',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveEventDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromManagingEventsForm.saveAsDraftSuccess, fromManagingEventsForm.updateAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Success. Event has been saved as draft',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public addCatalogToQL$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderCatalogs.addQuickLinkSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'You can see Quicklink in My Quicklinks section',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public addCatalogToQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderCatalogs.addQuickLinkFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Add Quicklink to My Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public removeFromTopQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.removeFromTopSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Selected Quicklink is now in My Quicklink section',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public removeFromTopQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.removeFromTopFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Remove Quicklink from My Top Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public addToTopQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.addToTopSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'You can now see added Quicklink in My Top Quicklinks section',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public addToTopQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.addToTopFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Add Quicklink to My Top Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public renameQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.renameQuickLinkSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Quicklink is now renamed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public renameQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.renameQuickLinkFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Renaming Quicklink failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public resetQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.resetQuickLinksToDefaultSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Your Quicklinks are now set to default',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public resetQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.resetQuickLinksToDefaultFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Reset Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public reorganizeQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.reorganizeQuickLinksSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'My Top Quicklinks are now rearranged',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public reorganizeQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.reorganizeQuickLinksFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Reorganize Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.deleteQuickLinkSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Deleted Quicklink in no longer in My Quicklinks list',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.deleteQuickLinkFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Delete Quicklink failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public deleteMultipleQLSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.deleteQuickLinksSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Deleted Quicklinks in no longer in My Quicklinks list',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteMultipleQLFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromHeaderQuickLinks.deleteQuickLinksFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Delete Quicklinks failed',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public putBookmarksuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromSubscriptions.addBookmarkSuccess,
        fromNewsfeedSubscriptions.addBookmarkSuccess,
        fromContentItems.putBookmarkByContentIdSuccess,
        fromNews.addBookmarkSuccess,
        fromNewsRelated.bookmarkSuccess,
        fromUserNewsArticles.addBookmarkSuccess
      ),
      debounceTime(100),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'You can now see added news in My bookmarks',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public newsletterWasSharedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterShare.shareNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'New owners now have access to your Newsletter',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public newsletterSetContributorsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterDashboard.setContributorsSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Newsletter owners changed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public sendTestNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterOther.sendTestNewsletterSuccess, fromNewsletterInline.sendTestSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Check your inbox to see the email',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public publishNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterOther.publishNewsletterSuccess, fromNewsletterInline.sendNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Check your inbox to see the email',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterOther.createDraftSuccess, fromNewsletterInline.saveAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'A draft version of newsletter was created',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public updateDraftSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterOther.updateDraftSuccess, fromNewsletterInline.updateAsDraftSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'A draft version of newsletter was updated',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveAsTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterDashboard.saveAsTemplateSuccess, fromNewsletterSavedTemplates.acceptNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'You can now find the template under the “My saved templates” section',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public cancelNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterDashboard.cancelNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Newsletter will not be sent to the recipients',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterDashboard.deleteNewsletterSuccess, fromNewsletterTable.deleteNewsletter.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Newsletter has been removed from your list',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteNewsletterTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterSavedTemplates.deleteNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Newsletter template has been removed from your list',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public sendTemplateCopyFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterSavedTemplates.sendTemplateCopyFailure),
      map(() =>
        fromActions.showToast({
          title: 'Something went wrong',
          message: 'Newsletter share failure',
          options: {
            type: 'error',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public renameTemplateNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterSavedTemplates.renameNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Template is now renamed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public renameNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterDashboard.renameNewsletterSuccess, fromNewsletterTable.renameNewsletter.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Newsletter is now renamed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public shareNewsletterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterSavedTemplates.shareNewsletterSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'People that you shared the template with will be notified',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public webSocketAccessDenied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewsletterWebSocket.wsNewsletterAccessDenied),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Please check this newsletter status on Shared Newsletters tab',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // NEWSLETTER-NEW

  public sentTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewNewsletterForm.sendTest.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Test newsletter has been sent',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public draft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewNewsletterForm.saveAsDraft.success),
      map(({newsletter}) =>
        fromActions.showToast({
          title: 'Success',
          message: newsletter.id === null ? 'A draft version of newsletter was created' : 'A draft version of newsletter was updated',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public publish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromNewNewsletterForm.publish.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Check your inbox to see the email',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );


  // RECIPIENT

  public deleteListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsTable.deleteList.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list has been removed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public acceptListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsTable.acceptSentToMeList.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list accepted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public rejectListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsTable.rejectSentToMeList.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list rejected',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public createRecipientsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsForm.form.createSuccess),
      map(({form}) =>
        fromActions.showToast({
          title: 'Success',
          message: form.id === null ? 'Recipients list created' : 'Changes saved',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public sendCopyRecipientsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsTable.sendList.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list sent',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public importRecipientsListFromSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsForm.form.addFromSelect),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list imported',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public importRecipientsListFromFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRecipientsForm.form.addFromFile),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Recipients list added',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public createCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignForm.form.createSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Campaign created',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public archivedCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignTable.archiveCampaign.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Campaign archived',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public unArchivedCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignTable.unArchiveCampaign.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Campaign unarchived',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public shareCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignTable.sendCampaign.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Campaign owners modified',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCampaignTable.deleteCampaign.success),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Campaign deleted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  // ALERTS

  public sendAlertSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAlerts.sendAlertSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Your alert was just sent',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public duplicateEntrySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyContentTable.duplicateCatalogSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Now you can edit the copy of this entry',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteCatalogMyTableSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMyContentTable.deleteSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Catalog entry deleted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteCatalogAllTableSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAllContentTable.deleteSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Catalog entry deleted',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public addChannelByIdsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.addSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Channel subscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public addAuthorByIdsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.addSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Author subscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public addTopicByIdsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.addSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Topic subscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );
  public deleteChannelByIdsSuccessS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.deleteSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.CHANNELS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Channel unsubscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteAuthorByIdsSuccessS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.deleteSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.AUTHORS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Author unsubscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public deleteTopicByIdsSuccessS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSubscriptions.deleteSubscriptionByIdsSuccess),
      filter(({subType}) => subType === SubscriptionType.TOPICS),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Topic unsubscribed',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  // SEARCH RESULTS

  public addQuickLinkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRhSearchResults.addQuicklinkSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'You can see the link in your Quicklinks list',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  public saveSearchFiltersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRhSearchResults.saveFiltersSuccess),
      map(() =>
        fromActions.showToast({
          title: 'Success',
          message: 'Default filters saved',
          options: {
            type: 'success',
            position: 'bottom-left',
          },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private toastService: RdsToastService,
    private store$: Store<any>
  ) {
  }
}
