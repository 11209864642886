import { ConfirmDialogData } from './confirm-dialog.component';

export const getDeleteChannelDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  const plural = ids.length > 1 ? 's' : '';
  return {
    ids,
    title: `Delete ${name ? '"'+name+'"' : 'channel'+plural}?`,
    messages: [
      `Are you sure you want to delete ${name ? '"'+name+'"' : ids.length} channel${plural}?`,
      'Please note that this action is irreversible. All user roles as well as all news will be unassigned from this channel.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, delete ${name ? '' : ids.length + ' '}channel${plural}` ,
    confirmButtonType: 'warning'
  }
}

export const getRemoveNewsFromChannelDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ${name ? '"'+name+'"' : 'news'} from the channel?`,
    messages: [
      `Are you sure you want to remove ${name ? '"'+name+'"' : ids.length+' news'} from the channel?`,
      'Please note that this action is irreversible. The article will no longer be published in this channel.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove ${name ? '' : ids.length + ' news'}`,
    confirmButtonType: 'warning'
  }
}

export const getRemoveNewsFromArchivedDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ${name ? '"'+name+'"' : 'news'} from the channel?`,
    messages: [
      `Are you sure you want to remove ${name ? '"'+name+'"' : ids.length+' news'} from the channel?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove ${name ? '' : ids.length + ' news'}`,
    confirmButtonType: 'warning'
  }
}
export const getDeleteNewsDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Delete ${name ? '"'+name+'"' : 'news'}?`,
    messages: [
      `Are you sure you want to delete ${name ? '"'+name+'"' : ids.length+' news'}?`,
      'Please note that this action is irreversible. The article will be deleted and not visible in all channels it has been published in.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, delete ${name ? '' : ids.length + ' '}news` ,
    confirmButtonType: 'warning'
  }
}

export const getArchiveNewsDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Archive ${name ? '"'+name+'"' : 'news'}?`,
    messages: [
      `Are you sure you want to archive ${name ? '"'+name+'"' : ids.length+' news'}?`,
      'Please note that this action is irreversible. The article will be archived and not visible in all channels it has been published in.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, archive ${name ? '' : ids.length + ' '}news` ,
    confirmButtonType: 'warning'
  }
}



export const getRejectNewsDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Archive ${name ? '"'+name+'"' : 'news'}?`,
    messages: [
      `Are you sure you want to archive ${name ? '"'+name+'"' : ids.length+' news'}?`,
      'Please note that this action is irreversible. The article will be archived and not visible in all channels it has been published in.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, archive ${name ? '' : ids.length + ' '}news` ,
    confirmButtonType: 'warning'
  }
}

export const getUnpublishNewsDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Unpublish ${name ? '"'+name+'"' : 'news'}?`,
    messages: [
      `Are you sure you want to unpublish ${name ? '"'+name+'"' : 'this news'}?`,
      'The article will not be visible in all channels it has been published in.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, unpublish` ,
    confirmButtonType: 'primary'
  }
}

export const getPublishNewsDialog = (ids: Array<number>): ConfirmDialogData => {
  return {
    ids,
    title: `Publish changes?`,
    messages: [
      `Are you sure you want to publish changes to this news?`,
      'Changes will be published <strong>immediately</strong>.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, publish` ,
    confirmButtonType: 'primary'
  }
}

// CATALOG 
export const getRemoveCatalogEntryDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ${name ? '"'+name+'"' : 'news'} catalog entry?`,
    messages: [
      `Are you sure you want to remove ${name ? '"'+name+'"' : ids.length+' news'} catalog entry?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove ${name ? '' : ids.length + ' entries'}`,
    confirmButtonType: 'warning'
  }
}

// CATALOG 
export const getProlongCatalogEntryDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Prolong ${name ? '"'+name+'"' : 'news'} catalog entry?`,
    messages: [
      `Are you sure you want to prolong ${name ? '"'+name+'"' : ids.length+' news'} catalog entry?`,
      `Your entry will be prolonged for another 12 months.`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, prolong ${name ? '' : ids.length + ' entries'}`,
    confirmButtonType: 'primary'
  }
}

// QL SET
export const getRemoveQLSetDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ${name ? '"'+name+'"' : 'news'} Quicklinks set?`,
    messages: [
      `Are you sure you want to remove ${name ? '"'+name+'"' : ids.length+' news'} Quicklinks set?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove ${name ? '' : ids.length + ' sets'}`,
    confirmButtonType: 'warning'
  }
}

// CHANNELS SET
export const getRemoveChannelsSetDialog = (ids: Array<number>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ${name ? '"'+name+'"' : 'news'} Channels set?`,
    messages: [
      `Are you sure you want to remove ${name ? '"'+name+'"' : ids.length+' news'} Channels set?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove ${name ? '' : ids.length + ' sets'}`,
    confirmButtonType: 'warning'
  }
}

//ASSIGN ROLE
export const getRemoveUserRoleDialog = (ids: Array<string>, name?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove all roles for ${name}?`,
    messages: [
      `Are you sure you want to remove assigned roles for ${name}?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove`,
    confirmButtonType: 'warning'
  }
}

//ASSIGN ROLE
export const getRemoveTickerDialog = (ids: Array<number>, message?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove ticker news?`,
    messages: [
      `Are you sure you want to remove ticker news with message "${message}"?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove`,
    confirmButtonType: 'warning'
  }
}

// GENERIC ACCOUNT 
export const getRemoveGenericAccountDialog = (ids: Array<string>, username?: string): ConfirmDialogData => {
  return {
    ids,
    title: `Remove generic account?`,
    messages: [
      `Are you sure you want to remove generic account with username "${username}"?`,
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, remove`,
    confirmButtonType: 'warning'
  }
}

// EVENTS
export const getPublishEventDialog = (ids: Array<number>): ConfirmDialogData => {
  return {
    ids,
    title: `Publish changes?`,
    messages: [
      `Are you sure you want to publish changes to this event?`,
      'Changes will be published <strong>immediately</strong>.'
    ],
    cancelButtonLabel: 'Cancel',
    confirmButtonLabel: `Yes, publish` ,
    confirmButtonType: 'primary'
  }
}
