<ng-container [formGroup]="form">
	<div class="row">
		<div class="col mt-5">
			<h4 class="ui-heading-4">Schedule date</h4>
		</div>
	</div>
	<div class="row">
		<div class="col-6 mt-3">
			<rds-form-field>
				<rds-control-label>Select Time Zone</rds-control-label>
				<rds-select
					#selectTimeZone
					formControlName="timeZone"
					(valueChange)="selectTimeZone.resetFilterValue()"
					[filterBy]="filterBy"
				>
					<rds-select-search-input #searchInput></rds-select-search-input>
					<rds-select-option-separator></rds-select-option-separator>
					<rds-select-option
						*ngFor="let timezone of timezoneDict"
						[value]="timezone.id"
						[id]="timezone.name"
						[innerHTML]="timezone.name | highlight: searchInput.control.value"
					></rds-select-option>
				</rds-select>
			</rds-form-field>
		</div>
	</div>
	<div class="row">
		<div class="col-6 mt-5">
			<rds-form-field>
				<rds-control-label>Select schedule date</rds-control-label>
				<input
					#publishPickerInput
					rdsInput
					formControlName="scheduleDate"
					(focus)="publishPicker.open()"
					[rdsDatepicker]="publishPicker"
				/>
				<rds-datepicker-toggle rds-control-suffix [forDatepicker]="publishPicker"></rds-datepicker-toggle>
				<rds-datepicker #publishPicker (closedStream)="publishPickerInput.blur()"></rds-datepicker>
				<rds-control-error *ngIf="form.get('scheduleDate').errors?.dateFromPast">
					Schedule date can't be in the past
				</rds-control-error>
				<rds-control-error *ngIf="form.get('scheduleDate').errors?.required">
					This field is required<br />
				</rds-control-error>
			</rds-form-field>
		</div>
		<div class="col-6 mt-5">
			<rds-form-field>
				<rds-control-label>Set schedule time</rds-control-label>
				<input #scheduleTime rdsInput formControlName="scheduleTime" type="time" step="900" />
				<button rds-icon-button rds-control-suffix (click)="scheduleTime.showPicker()" size="m">
					<rds-icon namespace="outlined" icon="clock"></rds-icon>
				</button>
				<rds-control-error *ngIf="form.get('scheduleTime').errors?.required">
					This field is required<br />
				</rds-control-error>
			</rds-form-field>
		</div>
	</div>
</ng-container>
