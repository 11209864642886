import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface CampaignTableFilters {
	subscription?: SelectFilterDefinition;
	unsubscription?: SelectFilterDefinition;
	status?: SelectFilterDefinition;
	search?: FilterDefinition;
}

export const CAMPAIGN_SUBSCRIPTION = [
	{ label: 'All', value: null },
	{ label: 'Enabled', value: true },
	{ label: 'Disabled', value: false },
];

export const CAMPAIGN_UNSUBSCRIPTION = [
	{ label: 'All', value: null },
	{ label: 'Enabled', value: true },
	{ label: 'Disabled', value: false },
];

export const CAMPAIGN_STATUS = [
	{ label: 'All', value: null },
	{ label: 'Active', value: 0 },
	{ label: 'Archived', value: 1 },
];

export const INITIAL_CAMPAIGN_FILTERS: CampaignTableFilters = {
	subscription: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: null,
		label: 'Subscription',
		multiple: false,
		options: CAMPAIGN_SUBSCRIPTION,
	},
	unsubscription: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: null,
		label: 'Unsubscription',
		multiple: false,
		options: CAMPAIGN_UNSUBSCRIPTION,
	},
	status: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: null,
		label: 'Status',
		multiple: false,
		options: CAMPAIGN_STATUS,
	},
	...SEARCH_FILTER,
};
