<h2 rds-dialog-header>
    <span style="display: inline-flex; gap: 12px;">
        Rada group information <a href="https://cidm.roche.com" rds-link standalone style="font-size: 12px;"
                                  target="_blank">
        <rds-icon namespace="outlined" icon="link"></rds-icon>
        see more in CIDM </a>
    </span>
</h2>

<div rds-dialog-icon-placeholder>
  <button rds-icon-button size="m" [rds-dialog-close]="null">
    <rds-icon icon="close" namespace="outlined"></rds-icon>
  </button>
</div>
<div rds-dialog-content>
  <div class="row">
    <div class="mt-6 d-inline-flex">
      <div class="col">
        <p class="ui-component-text-s text-low-contrast">Group Name</p>
        <p class="ui-label-l">{{rada.name}}</p>
      </div>
      <div class="col">
        <div align="end">
          <rds-badge *ngIf="rada.numberOfMembers" type="minimal" color="blue"
                     label=" {{rada.numberOfMembers}} direct members" [size]="'m'"></rds-badge>&nbsp;
          <rds-badge *ngIf="rada.numberOfSubgroups" type="minimal" color="blue"
                     label=" {{rada.numberOfSubgroups}} subgroups" [size]="'m'"></rds-badge>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="mt-6">
      <p class="ui-component-text-s text-low-contrast">Email</p>
      <p class="ui-label-l">{{rada.email}}</p>
    </div>
  </div>
  <div class="row">
    <div class="mt-6">
      <p class="ui-component-text-s text-low-contrast">Description</p>
      <p class="ui-label-l">{{rada.description}}</p>
    </div>
  </div>

</div>
<div rds-dialog-actions-container align="end">
  <button rds-secondary-button rnbBackButtonSet [rds-dialog-close]="true" size="m">
    Cancel
  </button>
</div>
