import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter} from '@ngrx/entity';
import * as fromActions from './campaign-newsletters-table.actions';
import {flattenFilters, TableState} from '@app/shared/filters/filters-model';
import {Pagination} from '@app/core/models';
import {NewsletterTableModel} from '@app/core/models/newsletter.model';
import {NewsletterTableFilters} from '@app/shared/filters/models/newsletter-table';
import {
    CampaignNewsletterTableFilters,
    INITIAL_CAMPAIGN_NEWSLETTER_FILTERS
} from '@shared/filters/models/campaign-newsletter-table';

export interface Sugg {
    name: string;
    tickersMessage: string[];
}

export const adapter: EntityAdapter<NewsletterTableModel> = createEntityAdapter<NewsletterTableModel>({
    selectId: (e) => e.id,
});

export interface CampaignId {
    campaignId: number;
}

export interface State {
    table: TableState<NewsletterTableModel, CampaignNewsletterTableFilters>;
}

export const initialState: State = {
    table: {
        data: adapter.getInitialState(),
        pagination: {
            ...Pagination,
            pageIndex: 0,
            pageSize: 10,
        },
        sort: {
            active: 'publishingDate',
            direction: 'desc',
        },
        defaultSort: {
            active: 'publishingDate',
            direction: 'desc',
        },
        filters: INITIAL_CAMPAIGN_NEWSLETTER_FILTERS,
        defaultFilters: INITIAL_CAMPAIGN_NEWSLETTER_FILTERS,
        isLoading: false,
        resetIndex: false,
    },
};

const tablePageReducer = createReducer(
    initialState,
    on(fromActions.table.request, (state) => ({
        ...state,
        table: {
            ...state.table,
            isLoading: true,
        },
    })),
    on(fromActions.table.success, fromActions.table.failure, (state) => ({
        ...state,
        table: {
            ...state.table,
            isLoading: false,
        },
    })),
    on(fromActions.table.success, (state, {data, pagination}) => ({
        ...state,
        table: {
            ...state.table,
            data: adapter.setAll(data.map(item => {
                return {...item, statusId: item.status}
            }), state.table.data),
            pagination,
            resetIndex: false,
        },
    })),
    on(fromActions.table.filter, (state, {filters}) => ({
        ...state,
        table: {
            ...state.table,
            filters,
            resetIndex: true,
        },
    })),
    on(fromActions.table.sort, (state, {active, direction}) => ({
        ...state,
        table: {
            ...state.table,
            sort: {
                active,
                direction,
            },
        },
    })),
    on(fromActions.table.paginate, (state, {pageIndex, pageSize}) => ({
        ...state,
        table: {
            ...state.table,
            pagination: {
                ...state.table.pagination,
                pageIndex,
                pageSize,
            },
        },
    })),
    on(fromActions.renameNewsletter.success, (state, {newsletter}) => ({
        ...state,
        table: {
            ...state.table,
            data: adapter.updateOne({id: newsletter.newsletterId, changes: newsletter}, state.table.data),
        },
    })),
    on(fromActions.deleteNewsletter.success, (state) => ({
        ...state,
        table: {
            ...state.table,
            resetIndex: true,
        },
    })),
    on(fromActions.table.clear, (state) => ({
        ...initialState,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return tablePageReducer(state, action);
}

// NEW WAY TABLE
export const getTableState = (state: State) => state.table;

export const getPagination = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.pagination;
export const getSort = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.sort;
export const getDefaultSort = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.defaultSort;
export const getFilters = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.filters;
export const getDefaultFilters = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
    table.defaultFilters;
export const getIsLoading = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) => table.isLoading;

export const getRequestData = ({
                                   pagination,
                                   sort,
                                   filters,
                                   resetIndex,
                               }: TableState<NewsletterTableModel, NewsletterTableFilters>) => ({
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sort,
    filters: flattenFilters(filters),
    resetIndex,
});

export const getTableData = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
    adapter.getSelectors().selectAll(table.data);

export const getTableDataContributedIds = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
    getTableData(table)
        .filter((n) => n.newsletterContributors.length > 0)
        .map((n) => n.id);

export const getTableDataOnlyDrafts = (table: TableState<NewsletterTableModel, NewsletterTableFilters>) =>
    getTableData(table).every((n) => n.status === 0) && getTableData(table).length > 0;

// Pagination fields
export const getPageSize = (pagination: Pagination) => pagination.pageSize;
export const getPageIndex = (pagination: Pagination) => pagination.pageIndex;
export const getTotalCount = (pagination: Pagination) => pagination.totalCount;

// Filters fields
export const getSearch = (filters: NewsletterTableFilters) => filters.search.value;

export const getEntitiesByIds = (entities: Dictionary<NewsletterTableModel>, ids: string[]): NewsletterTableModel[] =>
    ids.map((id) => entities[id]);
