import { Component, Inject, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromSuggestions from '@app/root-store/suggestions';
import { RDS_DIALOG_DATA, RdsSingleSelectFilterByFunc, RdsSingleSelectOptionComponent } from '@rds/angular-components';
import { debounceTime } from 'rxjs/operators';
import { RhFeedbackSettings } from '@app/newsletter-new/models/newsletter';
import { SubSink } from 'subsink';

export type SelectType = 'select' | 'autocomplete';

export interface ManageDialogData {
	title: string;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
	feedbackSettings: RhFeedbackSettings;
}

export enum ANSWERTYPE {
	PREDEFINED = 0,
	THUMBS = 1,
	STARS = 2,
	CUSTOM = 3,
}

export type validatorsObj = {
	[key: string]: ValidatorFn | ValidatorFn[];
};

@Component({
	selector: 'rh-manage-feedback-section-dialog',
	templateUrl: './manage feedback-section-dialog.component.html',
	styleUrls: ['./manage feedback-section-dialog.component.scss'],
})
export class ManageFeedbackSectionDialogComponent implements OnDestroy {
	private subs: SubSink = new SubSink();
	answerType = ANSWERTYPE;
	feedbackSettings: FormGroup = new FormGroup({
		feedbackType: new FormControl(null, [Validators.required]),
		contact: new FormControl(null, []),
		title: new FormControl(null, []),
		question: new FormControl(null, []),
		answers: new FormArray([this.createAnswerFormGroup()], []),
	});
	previewRadio = new FormControl();
	options: any[] = [
		{
			value: 0,
			name: 'Predefined',
		},
		{
			value: 1,
			name: 'Thumbs up/down',
		},
		{
			value: 2,
			name: 'Five star rating',
		},
		{
			value: 3,
			name: 'Define your answer',
		},
	];

	private createAnswerFormGroup(): FormControl {
		return new FormControl('', [Validators.required]);
	}

	filterBy: RdsSingleSelectFilterByFunc<string> = (
		text: string | null,
		item: RdsSingleSelectOptionComponent<string>
	) => {
		if (text === null || text === '') {
			return true;
		} else {
			return (
				item.value.toLowerCase().includes(text.toLowerCase()) || item.id.toLowerCase().includes(text.toLowerCase())
			);
		}
	};
	private lastType = null;

	constructor(
		@Inject(RDS_DIALOG_DATA) public data: ManageDialogData,
		private store$: Store<fromSuggestions.State>
	) {
		if (data.feedbackSettings && data.feedbackSettings?.answers?.length > 1) {
			for (let i = 0; i < data.feedbackSettings.answers.length - 1; i++) {
				this.addNewQuestion();
			}
		}
		this.feedbackSettings.patchValue({ ...data.feedbackSettings }, { emitEvent: false });
		if (data.feedbackSettings.feedbackType === ANSWERTYPE.PREDEFINED) {
			this.feedbackSettings.get('title').disable();
			this.feedbackSettings.get('question').disable();
			this.addValidators({ contact: [Validators.required] });
			this.feedbackSettings.updateValueAndValidity();
		}
		this.lastType = data.feedbackSettings.feedbackType;
		this.subs.sink = this.feedbackSettings.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
			this.feedbackSettings.enable();

			if (this.lastType !== value.feedbackType) {
				if (this.lastType === ANSWERTYPE.CUSTOM) {
					this.feedbackSettings.patchValue(
						{
							answers: [],
						},
						{ emitEvent: false }
					);
				}
				if (this.lastType === ANSWERTYPE.PREDEFINED) {
					this.feedbackSettings.patchValue(
						{
							title: null,
							question: null,
							contact: null,
						},
						{ emitEvent: false }
					);
					this.feedbackSettings.get('contact').clearValidators();
				}
				this.feedbackSettings.controls['answers'] = new FormArray([this.createAnswerFormGroup()], {});
			}
			this.lastType = value.feedbackType;

			switch (value.feedbackType) {
				case ANSWERTYPE.PREDEFINED: {
					value = {
						...value,
						title: 'Send us your feedback',
						question:
							'What would you like to read about? Do you have any ideas? We would love to hear from you. Reach us at:',
					};
					this.feedbackSettings.patchValue(value, { emitEvent: false });
					this.feedbackSettings.get('title').disable();
					this.feedbackSettings.get('question').disable();
					this.addValidators({ contact: [Validators.required, Validators.email] });
					break;
				}
				case ANSWERTYPE.STARS:
				case ANSWERTYPE.THUMBS:
				case ANSWERTYPE.CUSTOM: {
					this.addValidators({ question: [Validators.required] });
					break;
				}
			}
			this.feedbackSettings.updateValueAndValidity();
			this.data.feedbackSettings = value;
		});
	}

	private removeValidators(keys?) {
		const arrKeys = keys || this.feedbackSettings.controls;
		for (const key in arrKeys) {
			this.feedbackSettings.get(key).clearValidators();
			this.feedbackSettings.get(key).updateValueAndValidity();
		}
	}

	private addValidators(keys: validatorsObj) {
		for (const key of Object.keys(keys)) {
			this.feedbackSettings.get(key).setValidators(keys[key]);
			this.feedbackSettings.get(key).updateValueAndValidity();
		}
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	getAutocompletes(suggestionType, prop, event) {
		this.store$.dispatch(fromSuggestions.loadSuggestion({ suggestionType, prop, phrase: event }));
	}

	addNewQuestion() {
		const answers = this.feedbackSettings.get('answers') as FormArray;
		answers.push(this.createAnswerFormGroup());
	}

	removeQuestion(index) {
		const answers = this.feedbackSettings.get('answers') as FormArray;
		answers.removeAt(index);
	}

	onSave() {
		this.feedbackSettings.enable();
		return this.feedbackSettings.value;
	}
}
