import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { RecipientListsTableModel } from '@app/core/models/newsletter.model';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { RdsDialogService } from '@rds/angular-components';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from './recipients-table.actions';
import * as fromReducer from './recipients-table.reducer';
import { ListType } from './recipients-table.reducer';
import * as fromSelectors from './recipients-table.selectors';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {
	SendCopyDialogComponent,
	SendCopyDialogData,
} from '@app/shared/dialogs/send-copy-dialog/send-copy-dialog.component';
import { TYPE_RECIPIENT } from '@app/newsletter-new/models/recipients-list';

@Injectable()
export class RecipientsTableEffects {
	public initTable$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.init),
				switchMap(() =>
					this.store$.pipe(
						select(fromSelectors.selectTableRequestData),
						debounceTime(300),
						distinctUntilChanged((prev, next) => {
							if (prev.resetIndex && !next.resetIndex) {
								delete prev.resetIndex;
								delete next.resetIndex;
							}
							return JSON.stringify(prev) === JSON.stringify(next);
						}),
						map(({ filters, pageIndex, pageSize, sort, resetIndex }) => ({
							filters,
							sort,
							pageIndex: resetIndex ? 0 : pageIndex,
							pageSize,
						}))
					)
				),
				map((requestData) => fromActions.table.request(requestData))
			),
		{ dispatch: true }
	);
	public getSelectionRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.selectionList.request),
				switchMap(() =>
					this.newsletterService.getAllRecipientsLists().pipe(
						map((data) => ({
							data: data as Array<{ name: string; id: number }>,
						}))
					)
				),
				map(({ data }) => {
					return fromActions.selectionList.success({ lists: data });
				}),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);

	public getTableRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.request),
				switchMap((requestData) =>
					this.newsletterService.getRecipientLists(requestData).pipe(
						map((res) => ({
							data: res.data as Array<RecipientListsTableModel>,
							pagination: res.pagination,
						}))
					)
				),
				mergeMap(({ data, pagination }) => {
					data = data.map((i) => {
						return {
							...i,
							recipientsCount: i.recipients.filter((recipient) => recipient.type === TYPE_RECIPIENT.SINGLE).length,
							radaCount: i.recipients.filter((recipient) => recipient.type === TYPE_RECIPIENT.GROUP).length,
						};
					});
					let sentToMe = data.filter((list) => list.status === ListType.WAITING);
					let my = data.filter((list) => list.status !== ListType.WAITING);

					return [
						fromActions.table.success({
							data: my,
							pagination,
						}),
						fromActions.getSentToMeList.success({ lists: sentToMe }),
					];
				}),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);

	public openDeleteNewsletterDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteList.dialog),
				map(({ lists }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							ids: lists.map((list) => list.id),
							messages: [
								'Please be aware that this action is irreversible.',
								'The recipient list will not be available to use while sending Newsletters.',
							],
							title: lists.length > 1 ? `Delete Recipients list?` : `Delete Recipient list?`,
							confirmButtonLabel: lists.length > 1 ? `Yes, delete recipients list` : `Yes, delete recipient list`,
							confirmButtonType: 'warning',
						},
					});
					return { ids: lists.map((list) => list.id), dialog };
				}),
				switchMap(({ ids, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.deleteList.request({ ids }))
					)
				)
			),
		{ dispatch: true }
	);

	public deleteList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteList.request),
				switchMap(({ ids }) =>
					this.newsletterService.deleteList(ids).pipe(
						map((newsletter) => fromActions.deleteList.success()),
						catchError(({ message }) => of(fromActions.deleteList.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openSendListCopyDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.sendList.dialog),
				map(({ list }) => {
					const data: SendCopyDialogData = {
						title: 'Send a copy of Recipient List',
						message: 'Sent list will appear in receiver My recipient list.',
						confirmButtonLabel: 'Send recipient list',
					};
					const dialog = this.dialogService.open(SendCopyDialogComponent, {
						size: 'l',
						data,
					});
					return { list, dialog };
				}),
				switchMap(({ list, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((users) => {
							return { id: list.id, users };
						})
					)
				),
				map(({ id, users }) => fromActions.sendList.request({ id, users }))
			),
		{ dispatch: true }
	);

	public sendTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.sendList.request),
			switchMap(({ id, users }) =>
				this.newsletterService
					.sendList(
						id,
						users.map((item) => item.identifier)
					)
					.pipe(
						map(() => fromActions.sendList.success()),
						catchError((error) => of(fromActions.sendList.failure({ error })))
					)
			)
		)
	);

	public getSentToMeRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.getSentToMeList.request),
			switchMap(() =>
				this.newsletterService.getSentToMeList().pipe(
					map((lists) => fromActions.getSentToMeList.success({ lists })),
					catchError((error) => of(fromActions.getSentToMeList.failure({ error })))
				)
			)
		)
	);

	public acceptSentToMeRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.acceptSentToMeList.request),
			switchMap(({ id }) =>
				this.newsletterService.acceptSentToMeList(id).pipe(
					map(() => fromActions.acceptSentToMeList.success({ id })),
					catchError((error) => of(fromActions.acceptSentToMeList.failure({ error })))
				)
			)
		)
	);

	public rejectSentToMeRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.rejectSentToMeList.request),
			switchMap(({ id }) =>
				this.newsletterService.rejectSentToMeList(id).pipe(
					map(() => fromActions.rejectSentToMeList.success({ id })),
					catchError((error) => of(fromActions.rejectSentToMeList.failure({ error })))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
