import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { CampaignTableModel } from '@app/core/models/newsletter.model';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { RdsDialogService, SortDirection } from '@rds/angular-components';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './campaign-table.actions';
import * as fromReducer from './campaign-table.reducer';
import * as fromSelectors from './campaign-table.selectors';
import { ConfirmDialogComponent } from '@shared/dialogs/confirm-dialog/confirm-dialog.component';
import * as fromUser from '@core/user/store';
import {
	ManageRoleDialogComponent,
	ManageRoleDialogData,
} from '@shared/dialogs/manage-role-dialog/manage-role-dialog.component';
import { RhCampaignForm } from '@app/newsletter-new/models/campaign';
import { USER_ROLES_CAMPAIGN } from '@app/newsletter-new/models/user-roles.enum';

@Injectable()
export class CampaignTableEffects {
	public initTable$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.init),
				switchMap(() =>
					this.store$.pipe(
						select(fromSelectors.selectTableRequestData),
						debounceTime(300),
						distinctUntilChanged((prev, next) => {
							if (prev.resetIndex && !next.resetIndex) {
								delete prev.resetIndex;
								delete next.resetIndex;
							}
							return JSON.stringify(prev) === JSON.stringify(next);
						}),
						map(({ filters, pageIndex, pageSize, sort, resetIndex }) => ({
							filters,
							sort,
							pageIndex: resetIndex ? 0 : pageIndex,
							pageSize,
						}))
					)
				),
				map((requestData) => fromActions.table.request(requestData))
			),
		{ dispatch: true }
	);

	public getAllRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.getAllCampaign.request),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectSelectionListPagination))),
				switchMap(([requestData, pagination]) =>
					this.newsletterService
						.getCampaigns({
							pageIndex: pagination.pageIndex,
							pageSize: pagination.pageSize,
							filters: null,
							sort: null,
						})
						.pipe()
				),
				switchMap(({ data, pagination }) => {
					if (!pagination.isLast) {
						return [fromActions.getAllCampaign.success({ data, pagination }), fromActions.getAllCampaign.request()];
					}
					return [fromActions.getAllCampaign.success({ data, pagination })];
				}),
				catchError(({ message }) => of(fromActions.getAllCampaign.failure({ error: message })))
			),
		{ dispatch: true }
	);

	public getTableRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.request),
				switchMap((requestData) =>
					this.newsletterService.getCampaigns(requestData).pipe(
						map((res) => ({
							data: res.data as Array<CampaignTableModel>,
							pagination: res.pagination,
						}))
					)
				),
				map(({ data, pagination }) => fromActions.table.success({ data, pagination })),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);
	public refreshTableRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.refresh),
				withLatestFrom(this.store$.pipe(select(fromSelectors.selectTableRequestData))),
				switchMap((requestData) =>
					this.newsletterService
						.getCampaigns(
							requestData as unknown as {
								pageSize: number;
								pageIndex: number;
								sort: {
									active: string;
									direction: SortDirection;
								};
								filters: {
									[key: string]: any;
								};
								resetIndex: false;
							}
						)
						.pipe(
							map((res) => ({
								data: res.data as Array<CampaignTableModel>,
								pagination: res.pagination,
							}))
						)
				),
				map(({ data, pagination }) => fromActions.table.success({ data, pagination })),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);

	public openDeleteNewsletterDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteCampaign.dialog),
				map(({ id }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							id,
							title: `Are you sure you want to delete this campaign?`,
							confirmButtonLabel: `Yes, delete`,
							confirmButtonType: 'warning',
						},
					});
					return { id, dialog };
				}),
				switchMap(({ id, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.deleteCampaign.request({ id }))
					)
				)
			),
		{ dispatch: true }
	);

	public deleteList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteCampaign.request),
				switchMap(({ id }) =>
					this.newsletterService.deleteCampaign(id).pipe(
						switchMap((campaign) => [fromActions.deleteCampaign.success(), fromActions.table.init()]),
						catchError(({ message }) => of(fromActions.deleteCampaign.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openArchiveDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.archiveCampaign.dialog),
				map(({ id }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							id,
							title: `Are you sure you want to archive this campaign?`,
							confirmButtonLabel: `Yes, archive`,
							confirmButtonType: 'warning',
							messages: [
								'You will not be able to assign Newsletters to this campaign any more.',
								'Statistics will be available ',
							],
						},
					});
					return { id, dialog };
				}),
				switchMap(({ id, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.archiveCampaign.request({ id }))
					)
				)
			),
		{ dispatch: true }
	);

	public archiveList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.archiveCampaign.request),
				switchMap(({ id }) =>
					this.newsletterService.archiveCampaign(id).pipe(
						map((campaign) => fromActions.archiveCampaign.success({ id })),
						catchError(({ message }) => of(fromActions.archiveCampaign.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openUnArchiveDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.unArchiveCampaign.dialog),
				map(({ id }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							id,
							title: `Are you sure you want to unarchive this campaign?`,
							confirmButtonLabel: `Yes, unarchive`,
							confirmButtonType: 'warning',
						},
					});
					return { id, dialog };
				}),
				switchMap(({ id, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.unArchiveCampaign.request({ id }))
					)
				)
			),
		{ dispatch: true }
	);

	public unarchiveList$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.unArchiveCampaign.request),
				switchMap(({ id }) =>
					this.newsletterService.unArchiveCampaign(id).pipe(
						map((campaign) => fromActions.unArchiveCampaign.success({ id })),
						catchError(({ message }) => of(fromActions.unArchiveCampaign.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openSendListCopyDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.sendCampaign.dialog),
				withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
				map(([{ campaign }, userProfile]) => {
					const data: ManageRoleDialogData = {
						title: 'Manage Campaign owners',
						confirmButtonLabel: 'Change owners',
						item: {
							permissions: {
								owners: [
									...campaign.campaignUserRoles
										.filter((i) => i.role === USER_ROLES_CAMPAIGN.OWNER)
										.map((i) => {
											return { ...i, identifier: i.userLogin, role: 'owners' };
										}),
								],
								editors: [
									...campaign.campaignUserRoles
										.filter((i) => i.role === USER_ROLES_CAMPAIGN.EDITOR)
										.map((i) => {
											return { ...i, identifier: i.userLogin, role: 'editors' };
										}),
								],
								viewers: [],
							},
						},
					};

					const dialog = this.dialogService.open(ManageRoleDialogComponent, {
						size: 'l',
						data,
					});
					return { campaign, dialog };
				}),
				switchMap(({ campaign, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => {
							return { campaign, permissions: data.value };
						})
					)
				),
				map(({ campaign, permissions }) => fromActions.sendCampaign.request({ campaign, permissions }))
			),
		{ dispatch: true }
	);

	// public openSendListCopyDialog$ = createEffect(
	//   this.actions$.pipe(
	//     ofType(fromActions.openChangeOwnerDialog),
	//     withLatestFrom(this.store$.pipe(select(fromUser.selectUserProfile))),
	//     map(([{card}, userProfile]) => {
	//       const data: SendCopyDialogData = {
	//         title: 'Manage Newsletter owners',
	//         confirmButtonLabel: 'Change owners',
	//         initialUsers: card.newsletterContributors.map((u) => ({
	//           identifier: u.login,
	//           name: `${u.firstName} ${u.lastName}`,
	//           avatar: u.photoUrl,
	//           email: u.email,
	//         }))
	//       }
	//       const dialog = this.dialogService.open(SendCopyDialogComponent, {
	//         size: 'l',
	//         data
	//       });
	//       return ({card, dialog})
	//     }),
	//     switchMap(({card, dialog}) => dialog.afterClosed().pipe(
	//       filter(data => !!data),
	//       map((users: Array<SimpleUser>) => {
	//         return ({newsletterId: card.id, newsletterContributors: users.map((u): UserSharedProfile => ({
	//             login: u.identifier,
	//             firstName: u.name.split(' ')[0],
	//             lastName: u.name.split(' ')[1],
	//             email: u.email,
	//             newsletterId: card.id,
	//             photoUrl: u.avatar
	//           }))})
	//       })
	//     )),
	//     map(({newsletterId, newsletterContributors}) => fromActions.changeOwnerRequest({newsletterId, newsletterContributors}))
	//   ), { dispatch: true}
	// );

	public sendTemplateCopyRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromActions.sendCampaign.request),
			switchMap(({ campaign, permissions }) => {
				const data = { ...campaign, permissions } as RhCampaignForm;
				return this.newsletterService.shareCampaign(data.id, permissions).pipe(
					switchMap(() => [fromActions.sendCampaign.success({ campaign, permissions }), fromActions.table.refresh()]),
					catchError((error) => of(fromActions.sendCampaign.failure({ error })))
				);
			})
		)
	);

	// public openNewsPreview$ = createEffect(() =>
	// this.actions$.pipe(
	//   ofType(fromActions.openNewsPreview),
	//   switchMap(({id, backButton}) => this.newsletterService.getById(id).pipe(
	//     map((ticker) => ({ticker, backButton}))
	//   )),
	//   tap(({ticker, backButton}) => {
	//     const data: NewsletterPreviewDialogData = {
	//       ticker: {
	//         id: ticker.id,
	//         link: ticker.link,
	//         message: ticker.message,
	//         type: ticker.type
	//       },
	//       backButton,
	//       buttons: {
	//         editNewsletter: {
	//           visible: true,
	//           disabled: false
	//         }
	//       }
	//     }

	//     this.dialogService.open(NewsletterPreviewDialogComponent, {
	//       size: 'xl',
	//       data
	//     }
	//     )
	//   } )
	// ), { dispatch: false}
	// );

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
