<h2 rds-dialog-header>{{data.title}}</h2>
<div rds-dialog-content>
    <p *ngFor="let message of data.messages" class="mb-5" [innerHTML]="message">
    </p>
    <ng-container *ngIf="!!data.confirmationReason" [formGroup]="confirmation">
        <rds-form-field>
            <rds-control-label>{{data.confirmationReason.label}}</rds-control-label>
            <textarea
                rdsInput
                formControlName="reason"
            ></textarea>
        </rds-form-field>
    </ng-container>
    <ng-container *ngIf="!!data.confirmationOptions" [formGroup]="confirmation">
        <div class="my-5">
            <rds-radio-group formControlName="option">
                <rds-radio-button *ngFor="let option of data.confirmationOptions.options" [value]="option.value" [disabled]="option.disabled" [rdsTooltip]="option.reason" [rdsTooltipDisabled]="!option.disabled">{{option.label}}</rds-radio-button>
            </rds-radio-group>
        </div>
    </ng-container>
</div>
<div rds-dialog-actions-container align="end">
    <button
        *ngIf="!data.hideCancelButton"
        rds-secondary-button
        [rds-dialog-close]="null"
        size="m"
    >{{data.cancelButtonLabel || 'Cancel'}}</button>
    <button
        *ngIf="data.confirmButtonType === 'primary'"
        rds-primary-button
        [rds-dialog-close]="data"
        size="m"
        [disabled]="!confirmation.valid"
    >{{data.confirmButtonLabel || 'Confirm'}}</button>
    <button
        *ngIf="data.confirmButtonType === 'warning'"
        rds-warning-button
        [rds-dialog-close]="data"
        size="m"
        [disabled]="!confirmation.valid"
    >{{data.confirmButtonLabel || 'Confirm'}}</button>
</div>