import { createAction, props } from '@ngrx/store';
import { GenericAccount } from '@app/core/models';

export const setGenericAccountDetails = createAction(
  '[GENERIC ACCOUNT - FORM] set generic account details',
  props<{ details: GenericAccount }>()
);

export const initAddForm = createAction(
  '[GENERIC ACCOUNT - FORM] Init form',
  props<{genericAccount?: Partial<GenericAccount>}>()
);

export const getGenericAccountDetailsRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Get generic account details request',
  props<{username: string}>()
);

export const getGenericAccountDetailsSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Get generic account details success',
  props<{genericAccount: Partial<GenericAccount>}>()
);

export const getGenericAccountDetailsFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Get generic account details failure',
  props<{error: string}>()
);

export const setFormValue = createAction(
  '[GENERIC ACCOUNT - FORM] Set form value',
  props<{form: Partial<GenericAccount>}>()
);
export const setFormTouched = createAction(
  '[GENERIC ACCOUNT - FORM] Set form touched',
  props<{touched: boolean}>()
);

export const createGenericAccountRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Save generic account request',
);

export const createGenericAccountSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Save generic account success',
);

export const createGenericAccountFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Save generic account failure',
  props<{error: string}>()
);

export const updateGenericAccountRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Update generic account request',
  props<{username: string}>()
);

export const updateGenericAccountSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Update generic account success',
);

export const updateGenericAccountFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Update generic account failure',
  props<{error: string}>()
);

export const clearAll = createAction(
  '[GENERIC ACCOUNT - FORM] Clear form'
);

export const deleteGenericAccountRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Delete generic account Request',
  props<{usernames: Array<string>}>()
);

export const deleteGenericAccountSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Delete generic account Success',
  props<{count: number}>()
);

export const deleteGenericAccountFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Delete generic account Failure',
  props<{ error: any }>()
);

export const openPreviewDialog = createAction(
  '[GENERIC ACCOUNT - FORM] Open preview dialog',
  props<{canSave: boolean}>()
);

export const isUsernameUniqueRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Is username unique request',
  props<{username: string}>()
);

export const isUsernameUniqueSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Is username unique success',
  props<{isUnique: boolean}>()
);

export const isUsernameUniqueFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Is username unique failure',
  props<{error: string}>()
);

export const isEmailUniqueRequest = createAction(
  '[GENERIC ACCOUNT - FORM] Is email unique request',
  props<{email: string}>()
);

export const isEmailUniqueSuccess = createAction(
  '[GENERIC ACCOUNT - FORM] Is email unique success',
  props<{isUnique: boolean}>()
);

export const isEmailUniqueFailure = createAction(
  '[GENERIC ACCOUNT - FORM] Is email unique failure',
  props<{error: string}>()
);