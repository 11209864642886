import {Injectable} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators, validateIsFuture, validateTime} from '@shared/form-controls/validators/validator.function';
import cloneDeep from '@lodash-es/cloneDeep';

@Injectable()
export class NewsletterFormService {
  createHeaderFooterForm(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean }) {
    return new FormGroup({
      // general
      id: new FormControl(null),
      // header
      title: new FormControl(null, [Validators.required, Validators.maxLength(80)]),
      image: new FormControl([]),
      banner: new FormControl([]),
      hasImage: new FormControl(false),
      hasLogo: new FormControl(false),
      hasBanner: new FormControl(false),
      // footer
      hasFeedback: new FormControl(null),
      hasSenderDisclaimer: new FormControl(null),
      disclaimer: new FormControl(null, [Validators.required, Validators.maxLength(500)]),
    });
  }

  public headerFooterForm: FormGroup = this.createHeaderFooterForm();

  createFeedbackSettingsForm(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean }) {
    return new FormGroup({
      // general


      feedbackType: new FormControl(null, []),
      contact: new FormControl(null, []),
      title: new FormControl(null, []),
      question: new FormControl(null, []),
      answers: new FormArray([], []),
    });
  }

  public feedbackSettingsForm: FormGroup = this.createFeedbackSettingsForm();

  updateValidationHeaderFooterForm(options?: { value: any }) {
    if (options?.value?.hasImage) {
      this.headerFooterForm.controls.image.setValidators([Validators.required]);
    } else {
      this.headerFooterForm.controls.image.clearValidators();
    }

    if (options?.value?.hasBanner) {
      this.headerFooterForm.controls.banner.setValidators([Validators.required]);
    } else {
      this.headerFooterForm.controls.banner.clearValidators();
    }
    this.headerFooterForm.updateValueAndValidity();
  }

  getNewForm(form) {
    return cloneDeep(form);
  }

  validateHeaderFooter(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean; form: FormGroup }) {
    const newForm: FormGroup = this.getNewForm(this.headerFooterForm);
    newForm.enable();
    if (newForm) {
      if (options?.patchValue) {
        newForm.patchValue(options.value, {emitEvent: false});
      }
      if (options?.markAllAsTouched) {
        newForm.markAllAsTouched();
      }
      options.form.updateValueAndValidity();
    }
    return newForm;
  }

  createRecipientsListForm(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean }) {
    return new FormGroup(
      {
        recipientRada: new FormControl([], []),
        recipientUsers: new FormControl([], []),
      },
      [CustomValidators.anyControlNotEmpty]
    );
  }

  public recipientsListForm: FormGroup = this.createRecipientsListForm();

  updateValidationRecipientsListForm(options?: { value: any }) {
  }

  validateRecipientsList(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean; form: FormGroup }) {
    const newForm = this.getNewForm(this.recipientsListForm);
    newForm.enable();
    if (options?.patchValue) {
      newForm.patchValue(options.value, {emitEvent: false});
    }
    if (options?.markAllAsTouched) {
      newForm.markAllAsTouched();
    }
    options.form.updateValueAndValidity();
    return newForm;
  }

  createRecipientsListBlockedForm(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean }) {
    return new FormGroup({
      blockRada: new FormControl([], []),
      blockUsers: new FormControl([], []),
    });
  }

  public recipientsListBlockedForm: FormGroup = this.createRecipientsListBlockedForm();

  updateValidationRecipientsListBlockedForm(options?: { value: any }) {
  }

  validateRecipientsListBlocked(options?: {
    value: any;
    markAllAsTouched: boolean;
    patchValue: boolean;
    form: FormGroup;
  }) {
    const newForm = this.getNewForm(this.recipientsListBlockedForm);
    newForm.enable();
    if (options?.patchValue) {
      newForm.patchValue(options.value, {emitEvent: false});
    }
    if (options?.markAllAsTouched) {
      newForm.markAllAsTouched();
    }
    return newForm;
  }

  createPublishInfoForm() {
    return new FormGroup(
      {
        subject: new FormControl(null, [Validators.required]),
        previewText: new FormControl(null, [Validators.required]),
        fromEmail: new FormControl(null, [Validators.required, Validators.email]),
        fromName: new FormControl(null, [Validators.required]),
        replyToName: new FormControl(null, [Validators.required]),
        replyToEmail: new FormControl(null, [Validators.required, Validators.email]),
        whenToSend: new FormControl(null),
        scheduleDate: new FormControl(null),
        scheduleTime: new FormControl(null),
        scheduleSentDate: new FormControl(null),
        timeZone: new FormControl([Intl.DateTimeFormat().resolvedOptions().timeZone]),
      },
      {validators: []}
    );
  }

  public publishInfoForm: FormGroup = this.createPublishInfoForm();

  updateValidationPublishInfoForm(options?: { value: any }) {
    if (options?.value?.whenToSend === 1) {
      this.publishInfoForm.get('scheduleDate').setValidators(Validators.required);
      this.publishInfoForm.get('scheduleTime').setValidators(validateTime);
      this.publishInfoForm.get('scheduleSentDate').setValidators([validateIsFuture, Validators.required]);
      this.publishInfoForm.get('timeZone').setValidators(Validators.required);
    }
    if (options?.value?.whenToSend === 0) {
      this.publishInfoForm.get('scheduleDate').clearValidators();
      this.publishInfoForm.get('scheduleTime').clearValidators();
      this.publishInfoForm.get('scheduleSentDate').clearValidators();
      this.publishInfoForm.get('timeZone').clearValidators();
    }

    this.publishInfoForm.get('scheduleDate').updateValueAndValidity();
    this.publishInfoForm.get('scheduleTime').updateValueAndValidity();
    this.publishInfoForm.get('scheduleSentDate').updateValueAndValidity();
    this.publishInfoForm.get('timeZone').updateValueAndValidity();
    this.publishInfoForm.updateValueAndValidity();
  }

  validatePublishInfo(options?: { value: any; markAllAsTouched: boolean; patchValue: boolean; form: FormGroup }) {
    const newForm = this.getNewForm(this.publishInfoForm);
    newForm.enable();
    if (options?.patchValue) {
      newForm.patchValue(options.value, {emitEvent: false});
    }
    if (options?.markAllAsTouched) {
      newForm.markAllAsTouched();
    }
    options.form.updateValueAndValidity();
    return newForm;
  }

  resetForm() {
    this.headerFooterForm = this.createHeaderFooterForm();
    this.recipientsListForm = this.createRecipientsListForm();
    this.recipientsListBlockedForm = this.createRecipientsListBlockedForm();
    this.publishInfoForm = this.createPublishInfoForm();
  }
}
