import {
	FeedbackStatisticsEnum,
	PeriodStatisticsModel,
	DeliveryStatisticsModel,
} from '@app/core/models/newsletter.model';
import { PeriodEnum } from '@app/shared/filters/models/newsletter-link-statistics';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const statistics = createActionGroup({
	source: 'STATISTICS ALL CAMPAIGN',
	events: {
		init: props<{ id: number }>(),
		clear: emptyProps(),
		requestOverall: props<{
			id: number;
		}>(),
		saveCampaignInfo: props<{ title: string; createdAt: Date; newsletters: Array<any> }>(),
		successOverall: props<{ statistics: DeliveryStatisticsModel }>(),
		//
		initPeriod: props<{ 
			id: number;
			period: PeriodEnum;
			date: Date;
			// temporary
			newsletters: Array<any>;
		}>(),
		updatePeriod: props<{ period: PeriodEnum; date: Date }>(),
		requestPeriod: props<{ 
			id: number;
			period: PeriodEnum;
			date: Date;
			// temporary
			newsletters: Array<any>;
		 }>(),
		successPeriod: props<{ statistics: Array<PeriodStatisticsModel> }>(),
		// temporary
		updateNewsletter: props<{chartType, selectedNewsletters}>(),
		successNewsletter: props<{ newsletterDeliveryStatistics: any, availableNewsletters: Array<any> }>(),
		failure: props<{ error: any }>(),
		randomizeFeedback: emptyProps(),
		changeFeedbackType: props<{ feedbackType: FeedbackStatisticsEnum }>(),
	},
});
