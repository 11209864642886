<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content>
	<div class="row" [formGroup]="form">
		<div class="row">
			<p *ngIf="data.message" class="ui-label-l">{{ data.message }}</p>
		</div>
		<div class="row">
			<div class="col mt-5">
				<rh-people-picker
					(search)="getAutocompletes('send-copy', $event)"
					[multi]="true"
					[restrictedUsers]="data.preventAddYourself ? [currentUser] : []"
					[notRemovable]="notRemovable"
					formControlName="users"
					[autocomplete]="autocomplete$ | async"
				></rh-people-picker>
			</div>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button
		rds-primary-button
		[disabled]="form.get('users').valid === false"
		[rds-dialog-close]="form.get('users').value"
		size="m"
	>
		{{ data.confirmButtonLabel || 'Send template' }}
	</button>
</div>
