import {RoleNames} from '@app/core/enums/roles.enum';
import {RdsIconName} from '@rds/angular-components';

export interface MenuItem {
  icon: RdsIconName;
  title: string;
  path: string;
  roles?: Array<RoleNames>;
}

export const MENU_ITEMS: Array<MenuItem> = [
  {
    title: 'Catalog entry (linked pages)',
    icon: 'edit',
    path: '/catalog/entry/create',
    roles: []
  }, {
    title: 'News',
    icon: 'document',
    path: '/news/create',
    roles: []
  }, {
    title: 'Channel',
    icon: 'wifi_on',
    path: '/channels/create',
    roles: []
  },
  {
    title: 'Newsletter',
    icon: 'email',
    path: '/newsletter',
    roles: []
  }, {
    title: 'Event',
    icon: 'calendar',
    path: '/managing-events/create',
    roles: []
  },
  {
    title: 'Ticker',
    icon: 'high_priority',
    path: '/ticker/create',
    roles: [RoleNames.ADMINISTRATOR]
  },
  {
    title: 'Alert',
    icon: 'warning_hex',
    path: '/alerts',
    roles: [RoleNames.ADMINISTRATOR]
  }];
