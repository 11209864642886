import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
	RhRecipientsListForm,
	RhRecipientsListRequestBody,
	TYPE_RECIPIENT,
} from '@app/newsletter-new/models/recipients-list';
import { select, Store } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';
import { delay, first, map } from 'rxjs/operators';
import {
	CampaignTableModel,
	FeedbackOptionModel,
	FeedbackStatisticsEnum,
	FeedbackStatisticsModel,
	LinkStatisticsTableModel,
	RecipientListsTableModel,
	SentRecipientsListModel,
} from '../models/newsletter.model';

import * as fromMockActions from '@app/newsletter-new/store/mock/mock.actions';
import * as fromMockSelectors from '@app/newsletter-new/store/mock/mock.selectors';

export const NewsletterIdLiteral = '{newsletterId}';

export function FEEDBACK_RANDOMIZE_ANSWERS(
	options: Array<FeedbackOptionModel>,
	uniqueOpensCount: number
): Array<FeedbackOptionModel> {
	const random = () => Math.random();
	return options.reduce((acc, curr) => {
		return [
			...acc,
			{
				name: curr.name,
				icon: curr.icon,
				count: Math.floor(Math.min(random(), 0.8) * (uniqueOpensCount - acc.reduce((a, c) => (a += c.count), 0))),
			},
		];
	}, []);
}

export function FEEDBACK_MOCK_FACTORY(feedbackType, uniqueOpensCount): FeedbackStatisticsModel {
	if (feedbackType === null) {
		return null;
	}
	let options: Array<FeedbackOptionModel> = [];
	let question: string;
	switch (feedbackType) {
		case FeedbackStatisticsEnum.THUMBS:
			question = 'How do you like this Newsletter?';
			options = [
				{ name: 'Thumb Up', icon: 'like', count: 0 },
				{ name: 'Thumb Down', icon: 'dislike', count: 0 },
			];
			break;
		case FeedbackStatisticsEnum.STARS:
			question = 'How do you like this Newsletter?';
			options = [
				{ name: '5 Stars', icon: 'star', count: 0 },
				{ name: '4 Stars', icon: 'star', count: 0 },
				{ name: '3 Stars', icon: 'star', count: 0 },
				{ name: '2 Stars', icon: 'star', count: 0 },
				{ name: '1 Star', icon: 'star', count: 0 },
			];
			break;
		case FeedbackStatisticsEnum.OPTIONS:
			question = 'Tell me, where did you hide the money?';
			const optionsCount = [...Array(Math.floor(Math.random() * (9 - 2 + 1) + 2)).keys()];
			const optionsToSelect = [
				`I don't know anything about your money, homie!`,
				`That was him!`,
				'Money?',
				`Kill me, but i will never tell you!`,
				`It's there`,
				`Check your mom's basement`,
				'You are asking too late... I spent everything',
				'You mean ABBA song?',
				'I took it to Mordor',
			];
			for (let option in optionsCount) {
				options.push({ name: optionsToSelect[+option], icon: null, count: 0 });
			}
			break;
	}
	return {
		feedbackType,
		question,
		options: FEEDBACK_RANDOMIZE_ANSWERS(options, uniqueOpensCount),
	};
}

export const LINK_STATISTICS_LIST_MOCK: Array<LinkStatisticsTableModel> = [
	{ id: 0, name: 'Crova launch', type: 'button', url: '', clicksCount: 6, clickThroughtRate: 0 },
	{ id: 1, name: 'Crova Start', type: 'button', url: '', clicksCount: 6, clickThroughtRate: 0 },
	{ id: 2, name: 'Crova Start 2', type: 'link', url: '', clicksCount: 6, clickThroughtRate: 0 },
	{ id: 3, name: 'This is a link', type: 'button', url: '', clicksCount: 12, clickThroughtRate: 0 },
	{ id: 4, name: 'This is a link 2', type: 'link', url: '', clicksCount: 4, clickThroughtRate: 0 },
];

export const RECIPIENT_LIST_MOCK: Array<RecipientListsTableModel> = [
	{
		id: 0,
		name: 'xx',
		edited: new Date('2023-06-12'),
		recipients: [],
		blockedRecipients: [
			{
				email: 'glofct_ix-fd-ep-arad-bdtools-smartsheet@msxdl.roche.com',
				name: 'glofct_ix-fd-ep-arad-BDTools-Smartsheet',
				surname: null,
				included: false,
				type: 1,
			},
			{
				email: 'tessie.delos_santos@roche.com',
				name: 'Tessie',
				surname: 'Delos Santos',
				included: false,
				type: 0,
			},
		],
		sharedBy: null,
	},
	{
		id: 1,
		name: 'Weekly jokes list IT',
		edited: new Date('2022-11-03'),
		recipients: [],
		blockedRecipients: [],
		sharedBy: null,
	},
	{
		id: 2,
		name: 'List 2',
		edited: new Date('2023-02-10'),
		recipients: [],
		blockedRecipients: [],
		sharedBy: null,
	},
	{
		id: 3,
		name: 'Test',
		edited: new Date('2023-06-12'),
		recipients: [],
		blockedRecipients: [],
		sharedBy: null,
	},
	{
		id: 5,
		name: 'Test',
		edited: new Date('2021-06-20'),
		recipients: [
			{
				email: 'glofct_ix-fd-ep-arad-bdtools-smartsheet@msxdl.roche.com',
				name: 'glofct_ix-fd-ep-arad-BDTools-Smartsheet',
				surname: null,
				included: false,
				type: 1,
			},
			{
				email: 'tessie.delos_santos@roche.com',
				name: 'Tessie',
				surname: 'Delos Santos',
				included: false,
				type: 0,
			},
		],
		blockedRecipients: [],
		sharedBy: null,
	},
];

export const RECIPIENTS_LIST_SENT_MOCK: Array<SentRecipientsListModel> = [
	// {id: 16, name: 'Check this out!', sentBy: 'John Cena', personalization: true, edited: new Date('2021-03-07'), recipients: [], blockedRecipients: []},
	// {
	//     id: 17,
	//     name: `Don't use this list`,
	//     sentBy: 'Barbara Rabarbar',
	//     edited: new Date('2023-02-04'), recipients: [], blockedRecipients: []
	// },
	// {id: 18, name: 'Instant reject this', sentBy: 'Aloizy Kleks', edited: new Date('2022-08-20'), recipients: [], blockedRecipients: []},
];

export function stringGen(len) {
	let text = '';
	let charset = ' abcdefghijklmnopqrstuvwxyz0123456789';

	for (var i = 0; i < len; i++) {
		text += charset.charAt(Math.floor(Math.random() * charset.length));
	}

	return text;
}

export function getRandomRecipient(num: number, model: string) {
	let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	let users = [];
	for (let i = 0; i < num; i++) {
		let number = Math.floor(Math.random() * 10);
		let string = stringGen(number);
		let email = string + '@gmail.com';
		let type: number = Math.floor(Math.random() * 2);
		users.push({
			email: email,
			name: string,
			surname: type > TYPE_RECIPIENT.SINGLE ? null : string,
			[model === 'recipients' ? 'included' : 'blocked']:
				model === 'recipients' ? !!email.match(emailRegex) : !email.match(emailRegex),
			type: type,
		});
	}
	return users;
}

export const RECIPIENTS_LIST_DETAILS_MOCK: Array<Partial<RhRecipientsListRequestBody>> = [
	{
		id: 0,
		name: 'Weekly jokes list',
		description: stringGen(Math.floor(Math.random() * 255)),
		recipients: [
			{
				id: createGuid(),
				email: 'janusz.kowalski@polska.pl',
				name: 'Janusz',
				surname: 'Kowalski',
				included: true,
				type: 0,
			},
			{ id: createGuid(), email: 'jan.nowak@polska.pl', name: 'Jan', surname: 'Nowak', included: true, type: 0 },
			{ id: createGuid(), email: 'jan.nowak@polska.pl', name: 'Jan', surname: null, included: false, type: 0 },
			{ id: createGuid(), email: 'jan.nowak@polska.pl', name: null, surname: 'Nowak', included: false, type: 0 },
			{ id: createGuid(), email: 'ewa.zdrzewa@polska.pl', name: 'Ewa', surname: 'Zdrzewa', included: true, type: 0 },
			{ id: createGuid(), email: 'antoni.dobroni', name: 'Antoni', surname: 'Dobroni', included: false, type: 0 },
			{ id: createGuid(), email: 'antoni.dobroni', name: 'Antoni', surname: null, included: false, type: 0 },
			{
				id: createGuid(),
				email: 'mariusz.szuiram@polska.pl',
				name: 'Mariusz',
				surname: 'Szuiram',
				included: true,
				type: 0,
			},
			{
				id: createGuid(),
				email: 'rafal.dluzewski@contractors.roche.com',
				name: null,
				surname: 'Duda',
				included: true,
				type: 0,
			},
			{ id: createGuid(), email: null, name: 'Stefan', surname: 'Fortepian', included: true, type: 0 },
		],
		blockedRecipients: getRandomRecipient(Math.floor(Math.random() * 25), 'blockedRecipients'),
	},
	{
		id: 1,
		name: 'Weekly jokes list IT',
		description: stringGen(Math.floor(Math.random() * 255)),
		recipients: getRandomRecipient(Math.floor(Math.random() * 25), 'recipients'),
		blockedRecipients: getRandomRecipient(Math.floor(Math.random() * 25), 'blockedRecipients'),
	},
	{
		id: 2,
		name: 'GPS',
		description: stringGen(Math.floor(Math.random() * 255)),
		recipients: getRandomRecipient(Math.floor(Math.random() * 25), 'recipients'),
		blockedRecipients: getRandomRecipient(Math.floor(Math.random() * 25), 'blockedRecipients'),
	},
	{
		id: 3,
		name: 'Basel IT',
		description: stringGen(Math.floor(Math.random() * 255)),
		recipients: getRandomRecipient(Math.floor(Math.random() * 50), 'recipients'),
		blockedRecipients: getRandomRecipient(Math.floor(Math.random() * 50), 'blockedRecipients'),
	},
	{
		id: 4,
		name: 'Pharma',
		description: stringGen(Math.floor(Math.random() * 255)),
		recipients: getRandomRecipient(Math.floor(Math.random() * 25), 'recipients'),
		blockedRecipients: getRandomRecipient(Math.floor(Math.random() * 25), 'blockedRecipients'),
	},
];

export function createGuid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

@Injectable()
export class NewsletterMockService {
	constructor(private store$: Store<any>) {}

	public getCampaigns({
		pageIndex,
		pageSize,
		filters,
		sort,
	}: {
		pageSize: number;
		pageIndex: number;
		sort: {
			active: string;
			direction: SortDirection;
		};
		filters: {
			[key: string]: any;
		};
	}) {
		const subscriptionFilter = (f, v: CampaignTableModel) => {
			if (f.subscription === true || f.subscription === false) {
				return v.subscription === f.subscription;
			} else {
				return true;
			}
		};
		const unsubscriptionFilter = (f, v: CampaignTableModel) => {
			if (f.unsubscription === true || f.unsubscription === false) {
				return v.unsubscription === f.unsubscription;
			} else {
				return true;
			}
		};
		const searchFilter = (f, v: CampaignTableModel) => {
			if (!!f.search) {
				return v.name.toLowerCase().includes(f.search.toLowerCase());
			} else {
				return true;
			}
		};

		return this.store$.pipe(
			select(fromMockSelectors.selectCampaignData),
			// filter campaigns
			first((data) => !!data),
			map((data) => ({
				initial: data,
				filtered: data
					.filter(
						(rl) => subscriptionFilter(filters, rl) && unsubscriptionFilter(filters, rl) && searchFilter(filters, rl)
					)
					.sort((a, b) => {
						switch (sort.active) {
							case 'title':
								return (sort.direction === 'asc' ? 1 : -1) * a.name.localeCompare(b.name);
							case 'selfSubscribedCount':
								return (sort.direction === 'asc' ? 1 : -1) * a.selfSubscribedCount - b.selfSubscribedCount;
							case 'unsubscribedCount':
								return (sort.direction === 'asc' ? 1 : -1) * a.unsubscribedCount - b.unsubscribedCount;
						}
					}),
			})),
			// paginate campaigns
			map(({ initial, filtered }) => ({
				initial,
				filtered,
				paginated: filtered.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize),
			})),
			// data + pagination
			delay(Math.random() * 300),
			map(({ initial, filtered, paginated }) => ({
				data: paginated,
				pagination: {
					isFirst: pageIndex === 0,
					isLast: pageIndex === Math.ceil(filtered.length / pageSize),
					pageCount: Math.ceil(filtered.length / pageSize),
					pageIndex,
					pageSize,
					totalCount: filtered.length,
				},
			}))
		);
	}

	public getSentToMeList() {
		return of(RECIPIENTS_LIST_SENT_MOCK).pipe(delay(Math.random() * 300));
	}

	public getLinkStatistics(
		newsletterId,
		{
			pageIndex,
			pageSize,
			filters,
			sort,
		}: {
			pageSize: number;
			pageIndex: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: {
				[key: string]: any;
			};
		}
	) {
		const typeFilter = (f, v: LinkStatisticsTableModel) => {
			return f.type.includes(v.type) || f.type.length === 0 || !f.lanugage;
		};

		const searchFilter = (f, v: LinkStatisticsTableModel) => {
			if (!!f.search) {
				return v.name.toLowerCase().includes(f.search.toLowerCase());
			} else {
				return true;
			}
		};

		let filteredList = LINK_STATISTICS_LIST_MOCK.filter(
			(rl) => typeFilter(filters, rl) && searchFilter(filters, rl)
		).sort((a, b) => {
			switch (sort.active) {
				case 'name':
					return (sort.direction === 'asc' ? 1 : -1) * a.name.localeCompare(b.name);
			}
		});

		let paginatedList = filteredList.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);

		return of(paginatedList).pipe(
			first((data) => !!data),
			delay(Math.random() * 300),
			map((res) => ({
				data: res,
				pagination: {
					isFirst: pageIndex === 0,
					isLast: pageIndex === Math.ceil(filteredList.length / pageSize),
					pageCount: Math.ceil(filteredList.length / pageSize),
					pageIndex,
					pageSize,
					totalCount: filteredList.length,
				},
			}))
		);
	}

	public getRecipientsList(id: number): Observable<Partial<RhRecipientsListForm>> {
		// const url = this.endpoints.ENDPOINT.RECIPIENTS.GET.replace('{id}', '' + email);
		return this.store$.pipe(
			select(fromMockSelectors.selectRecipientListById(id)),
			first((data) => !!data),
			delay(Math.random() * 300)
		);
	}

	public createRecipientsList(form: Partial<RhRecipientsListForm>): Observable<Partial<RhRecipientsListForm>> {
		// const url = this.endpoints.ENDPOINT.RECIPIENTS.GET.replace('{id}', '' + email);
		// return this.http
		//     .post(url, form);
		this.store$.dispatch(fromMockActions.recipientList.create({ data: form }));

		return this.store$.pipe(
			select(fromMockSelectors.selectRecentlyAddedRecipientList),
			first((data) => !!data),
			delay(Math.random() * 300)
		);
	}

	public editRecipientsList(form: Partial<RhRecipientsListForm>): Observable<Partial<RhRecipientsListForm>> {
		// const url = this.endpoints.ENDPOINT.RECIPIENTS.GET.replace('{id}', '' + email);
		// return this.http
		//     .post(url, form);
		this.store$.dispatch(fromMockActions.recipientList.edit({ id: form.id, data: form }));

		return this.store$.pipe(select(fromMockSelectors.selectRecipientListById(form.id)), delay(Math.random() * 300));
	}

	public getRada(email: string): Observable<any> {
		// const url = this.endpoints.ENDPOINT.RADA.GET.replace('{id}', '' + email);

		return of({
			groupName: stringGen(Math.floor(Math.random() * 10)),
			email: stringGen(Math.floor(Math.random() * 10)) + '@gmail.com',
			type: stringGen(Math.floor(Math.random() * 10)) + '@gmail.com',
			applicationName: stringGen(Math.floor(Math.random() * 20)),
			owner: stringGen(Math.floor(Math.random() * 20)),
			description: stringGen(Math.floor(Math.random() * 200)),
			members: Math.floor(Math.random() * 500),
			subGroupMembers: Math.floor(Math.random() * 1000),
		}).pipe(delay(Math.floor(Math.random() * 500)));

		// return this.http
		//     .post(url);
	}
}
