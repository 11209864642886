import { Pipe, PipeTransform } from '@angular/core';
import * as fromSuggestions from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Pipe({
  name: 'autocompletes'
})
export class AutocompletesPipe implements PipeTransform {
  transform(prop): Observable<fromSuggestions.Autocomplete> {
    return this.store$.pipe(
      select(fromSuggestions.selectAutocomplete(prop)),
      distinctUntilChanged()
      );
  }

  constructor(
    private store$: Store<any>) {
  }
}
