import { CampaignTableModel } from '@app/core/models/newsletter.model';
import { CampaignTableFilters } from '@app/shared/filters/models/campaign-table';
import { Pagination } from '@core/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SortDirection } from '@rds/angular-components';

export const table = createActionGroup({
	source: 'CAMPAIGN TABLE',
	events: {
		init: emptyProps(),
		clear: emptyProps(),
		refresh: emptyProps(),
		request: props<{
			pageIndex: number;
			pageSize: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: { [key: string]: any };
		}>(),
		success: props<{ data: Array<CampaignTableModel>; pagination: Pagination }>(),
		failure: props<{ error: any }>(),
		filter: props<{ filters: CampaignTableFilters }>(),
		sort: props<{ active: string; direction: SortDirection }>(),
		paginate: props<{ pageIndex: number; pageSize: number }>(),
	},
});
export const getAllCampaign = createActionGroup({
	source: 'CAMPAIGNS GET ALL',
	events: {
		request: emptyProps(),
		success: props<{ data: Array<CampaignTableModel>; pagination: Pagination }>(),
		failure: props<{ error: string }>(),
	},
});
export const sendCampaign = createActionGroup({
	source: 'SHARE CAMPAIGN',
	events: {
		dialog: props<{ campaign: any }>(),
		request: props<{
			campaign: any;
			permissions: {
				owners: any[];
				editors: any[];
			};
		}>(),
		success: props<{
			campaign: any;
			permissions: {
				owners: any[];
				editors: any[];
			};
		}>(),
		failure: props<{ error: string }>(),
	},
});

export const archiveCampaign = createActionGroup({
	source: 'ARCHIVE CAMPAIGN',
	events: {
		dialog: props<{ id: number }>(),
		request: props<{ id: number }>(),
		success: props<{ id: number }>(),
		failure: props<{ error: string }>(),
	},
});
export const unArchiveCampaign = createActionGroup({
	source: 'UNARCHIVE CAMPAIGN',
	events: {
		dialog: props<{ id: number }>(),
		request: props<{ id: number }>(),
		success: props<{ id: number }>(),
		failure: props<{ error: string }>(),
	},
});
export const deleteCampaign = createActionGroup({
	source: 'DELETE CAMPAIGN',
	events: {
		dialog: props<{ id: number }>(),
		request: props<{ id: number }>(),
		success: emptyProps(),
		failure: props<{ error: string }>(),
	},
});
