<h2 rds-dialog-header>{{ data.title }}</h2>
<div rds-dialog-content style="overflow-x: hidden" [formGroup]="form">
	<div class="row">
		<div class="col">
			<div class="mt-5">
				<rh-people-picker
					(search)="getAutocompletes('user', 'user', $event)"
					[multi]="false"
					formControlName="user"
					[autocomplete]="userAutocomplete$ | async"
				>
					<rds-control-error *ngIf="form.controls.user.errors?.userNotExists"
						>Selected Content User is not valid
					</rds-control-error>
					<rds-control-error *ngIf="form.get('user').hasError('required') && form.get('user').touched">
						This field is required
					</rds-control-error>
				</rh-people-picker>
			</div>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button rds-primary-button [disabled]="form.invalid" size="m" [rds-dialog-close]="form.get('user').value">
		{{ data.confirmButtonLabel }}
	</button>
</div>
