<button rds-text-button (click)="openAdvancedFilters()">Advanced
    filters{{activeFiltersCount > 0 ? ' (' + activeFiltersCount + ')' : ''}}</button>
<rds-drawer *ngIf="!!filters" position="end" class="rds-shadow-2"
            (rhClickOutside)="drawer.expanded ? closeAdvancedFilters() : {}" (closeDrawer)="closeAdvancedFilters()">
    <rds-drawer-header>{{title}}</rds-drawer-header>
    <ng-container [formGroup]="filters">
        <ng-container
                *ngFor="let filter of filters.controls | keyvalue: showFiltersInOrder; let first = first; trackBy: trackByFn">
            <ng-container *ngIf="filter.value.controls.changable.value === true" [formGroup]="filters.get(filter.key)">
                <div class="row">
                    <div class="col" [class.my-5]="!first" [class.mb-5]="first">
                        <div *ngIf="!!filter.value.controls.label.value" class="row">
                            <div class="col">
                                <p class="ui-label-m-bold">{{filter.value.controls.label.value}}</p>
                            </div>
                            <div class="col-auto">
                                <rds-icon *ngIf="isApplied(filter.key)" class="c-success" namespace="filled"
                                          icon="checkmark_circle"></rds-icon>
                            </div>
                        </div>
                        <div class="row">
                            <div [ngSwitch]="filter.value.controls.type.value" class="col mt-3">
                                <rds-switch
                                        *ngSwitchCase="FilterTypeEnum.PREDEFINED_TOGGLE"
                                        [checked]="filter.value.controls.value.value"
                                        formControlName="value"
                                >
                                    {{filter.value.controls.toggleLabel.value}}
                                </rds-switch>

                                <rds-form-field *ngSwitchCase="FilterTypeEnum.SELECT"
                                                class="table-filters__control--select">
                                    <rds-control-label>
                                        {{filter.value.controls.label.value}}
                                        <span *ngIf="filter.value.controls.multiple.value && filter.value.controls.value.value?.length as count"
                                              class="c-600-roche-blue">
                          ({{count > 99 ? '99+' : count}})
                        </span>
                                    </rds-control-label>
                                    <ng-container *ngIf="filter.value.controls.multiple.value">
                                        <rds-multi-select formControlName="value"
                                                          [hideValue]="filter.value.controls.multiple.value">
                                            <rds-multi-select-all-option
                                                    *ngIf="filter.value.controls.options.value.length > 5"></rds-multi-select-all-option>
                                            <rds-multi-select-option
                                                    *ngFor="let option of filter.value.controls.options.value"
                                                    [value]="option.value">{{option.label}}</rds-multi-select-option>
                                        </rds-multi-select>
                                    </ng-container>
                                    <ng-container *ngIf="!filter.value.controls.multiple.value">
                                        <rds-select formControlName="value">
                                            <rds-select-all-option
                                                    *ngIf="filter.value.controls.options.value.length > 5"></rds-select-all-option>
                                            <rds-select-option
                                                    *ngFor="let option of filter.value.controls.options.value"
                                                    [value]="option.value">{{option.label}}</rds-select-option>
                                        </rds-select>
                                    </ng-container>
                                </rds-form-field>

                                <ng-container *ngSwitchCase="FilterTypeEnum.SUGGESTION">
                                    <ng-container [ngSwitch]="filter.value.controls.suggestion.value">
                                        <rh-people-picker
                                                *ngSwitchCase="SuggestionTypeEnum.USER"
                                                (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
                                                [multi]="filter.value.controls.multiple.value"
                                                formControlName="value"
                                                [autocomplete]="suggestions[filter.key] | async">
                                        </rh-people-picker>

                                        <rh-entity-picker
                                                *ngSwitchCase="SuggestionTypeEnum.CHANNEL"
                                                (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
                                                [multi]="filter.value.controls.multiple.value"
                                                label="Search for channel"
                                                chipPlaceholder="Selected channels appear here"
                                                formControlName="value"
                                                [autocomplete]="suggestions[filter.key] | async">
                                        </rh-entity-picker>

                                        <rh-entity-picker
                                                *ngSwitchCase="SuggestionTypeEnum.CATALOG"
                                                (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
                                                [multi]="filter.value.controls.multiple.value"
                                                label="Search for catalog"
                                                chipPlaceholder="Selected catalogs appear here"
                                                formControlName="value"
                                                [autocomplete]="suggestions[filter.key] | async">
                                        </rh-entity-picker>

                                        <rh-topic-picker
                                                *ngSwitchCase="SuggestionTypeEnum.TOPIC"
                                                (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
                                                [multi]="filter.value.controls.multiple.value"
                                                formControlName="value"
                                                [autocomplete]="suggestions[filter.key] | async">
                                        </rh-topic-picker>

                                    </ng-container>
                                </ng-container>

                                <ng-container *ngSwitchCase="FilterTypeEnum.DATE">
                                    <rds-form-field *ngIf="filter.value.controls.range.value">
                                        <rds-control-label>Choose a date range</rds-control-label>
                                        <rds-date-range-input [rangePicker]="modifiedPicker"
                                                              [formGroup]="filter.value.controls.value">
                                            <input #modifiedStartInput rdsStartDate placeholder="Start date"
                                                   (focus)="modifiedPicker.open()" formControlName="start"/>
                                            <input #modifiedEndInput rdsEndDate placeholder="End date"
                                                   (focus)="modifiedPicker.open()" formControlName="end"/>
                                        </rds-date-range-input>
                                        <rds-datepicker-toggle
                                                rds-control-suffix
                                                [forDatepicker]="modifiedPicker"
                                        ></rds-datepicker-toggle>
                                        <rds-date-range-picker #modifiedPicker
                                                               (closedStream)="modifiedStartInput.blur();modifiedEndInput.blur();ensureRangeValues(filter.value.controls.value)">
                                            <rds-datepicker-actions>
                                                <button
                                                        rds-text-button
                                                        rdsDatepickerCancel
                                                >Cancel
                                                </button>
                                                <button
                                                        rds-secondary-button
                                                        (click)="clearRange(filter.value.controls.value, modifiedPicker)"
                                                >Clear
                                                </button>
                                                <button
                                                        rds-primary-button
                                                        rdsDatepickerApply
                                                >Apply
                                                </button>
                                            </rds-datepicker-actions>
                                        </rds-date-range-picker>
                                    </rds-form-field>

                                    <rds-form-field *ngIf="!filter.value.controls.range.value">
                                        <rds-control-label>Choose a date</rds-control-label>
                                        <input
                                            #singlePickerInput
                                            rdsInput
                                            [min]="now"
                                            formControlName="value"
                                            (focus)="singlePicker.open()"
                                            [rdsDatepicker]="singlePicker"
                                        />
                                        <rds-datepicker-toggle
                                                rds-control-suffix
                                                [forDatepicker]="singlePicker"
                                        ></rds-datepicker-toggle>
                                        <rds-datepicker
                                            #singlePicker
                                            (closedStream)="singlePickerInput.blur()"
                                            [touchUi]="false"
                                        ></rds-datepicker>
                                    </rds-form-field>
                                </ng-container>

                                <rh-dropdown
                                        *ngSwitchCase="FilterTypeEnum.DICTIONARY"
                                        formControlName="value"
                                        size="small"
                                        [closeOnClickOutside]="true"
                                        [floatingOptions]="false"
                                        [showChips]="false"
                                        [isMultiple]="true"
                                        [optionsDictionary]="dictionaries[filter.value.controls.dictionary.value].dict | async"
                                        [optionsModel]="dictionaries[filter.value.controls.dictionary.value].model"
                                >
                      <span label>
                        {{filter.value.controls.label.value}}
                          <span *ngIf="filter.value.controls.value.value?.length as count" class="c-600-roche-blue">
                          ({{count > 99 ? '99+' : count}})
                        </span>
                      </span>
                                </rh-dropdown>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>
        </ng-container>
    </ng-container>
    <rds-drawer-actions align="start">
        <div class="row row-centered">
            <div class="col-auto">
                <button rds-text-button (click)="clearAll()">Clear advanced filters</button>
            </div>
            <div class="col-auto offset-auto">
                <button rds-primary-button (click)="closeAdvancedFilters()">Close</button>
            </div>
        </div>
    </rds-drawer-actions>
</rds-drawer>
