import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { SubSink } from 'subsink';
import * as fromSuggestions from '@app/root-store/suggestions';
import { Autocomplete } from '@app/root-store/suggestions';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { UserProfile } from '@core/models';

export interface SendTestDialogData {
	title: string;
	cancelButtonLabel?: string;
	confirmButtonLabel?: string;
	defaultUser: UserProfile;
}

@Component({
	selector: 'rh-send-test-dialog',
	templateUrl: './send-test-dialog.component.html',
	styleUrls: ['./send-test-dialog.component.scss'],
})
export class SendTestDialogComponent implements OnInit, OnDestroy {
	private subs = new SubSink();

	userAutocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('user')));

	form: FormGroup = new FormGroup({
		user: new FormControl([], [Validators.required]),
	});

	getAutocompletes(type, prop, event) {
		this.store$.dispatch(fromSuggestions.loadSuggestion({ suggestionType: type, prop, phrase: event }));
	}

	constructor(
		private dialogRef: RdsDialogRef<SendTestDialogComponent>,
		@Inject(RDS_DIALOG_DATA) public data: SendTestDialogData,
		private cdr: ChangeDetectorRef,
		private store$: Store<fromSuggestions.State>
	) {
		if (this.data) {
			this.form.patchValue({ user: [{ ...this.data.defaultUser }] });
		}
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.store$.dispatch(fromSuggestions.initSuggestion({ suggestionType: 'user', prop: 'user' }));
	}
}
