<rh-card *ngIf="event" [disableAnimation]="disableAnimation" [forceHoverState]="forceHoverState"
         (mouseenter)="changeHover(true)" (mouseleave)="changeHover(false)"
         (click)="openEvent(event.id, $event)">

  <img body-header class="grid-image cursor-pointer" [src]="event.imageUrl || '/assets/images/event-placeholder.png'">
  <ng-container body-title>
    <rh-event-header [date]="eventTime" [title]="event.title"
                     [hovered]="(hovered && !disableAnimation) || forceHoverState"></rh-event-header>
  </ng-container>
  <ng-container body-description>
    <div class="event-content">
      <p class="event-content__description ui-body-s text-low-contrast" [innerHTML]="event.description">
      <p class="event-content__location ui-component-text-s text-low-contrast text-truncate">
        <ng-container *ngIf="event.location">
          LOCATION:<br/>
          {{event.location}}
        </ng-container>
      </p>
    </div>
  </ng-container>
  <ng-container footer>
    <p class="ui-component-text-m text-low-contrast text-truncate">
      {{event.allDay ? 'ALL DAY' : eventTime | date: 'h:mm a'}}
      <ng-container [ngSwitch]="event.type || event.eventType">
        <span *ngSwitchCase="RhEventType.ON_SITE" class="ui-component-text-s"> | ON-SITE</span>
        <span *ngSwitchCase="RhEventType.VIRTUAL" class="ui-component-text-s"> | VIRTUAL</span>
        <span *ngSwitchCase="RhEventType.HYBRID" class="ui-component-text-s"> | HYBRID</span>
        <span *ngSwitchCase="RhEventType.OFF_SITE" class="ui-component-text-s"> | OFF-SITE</span>
        <span *ngSwitchDefault class="ui-component-text-s"> | TYPE</span>
      </ng-container>
    </p>

    <ng-container *ngIf="event.registrationUrl">
      <div class="register" (click)="$event.stopPropagation()">
        <a href="{{event.registrationUrl}}" class="ui-component-text-s" target="_blank"> REGISTER
          <rds-icon class="icon-button cursor-pointer" icon="launch" namespace="outlined"></rds-icon>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!event.registrationUrl">
      <rds-icon class="result__button" icon="add_circle" rdsTooltip="Add to calendar" namespace="outlined"
                (click)="addEventToGcal($event)"></rds-icon>
    </ng-container>

  </ng-container>
</rh-card>
