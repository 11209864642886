import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as fromActions from './newsletter-table.actions';
import * as fromReducer from './newsletter-table.reducer';
import * as fromSelectors from './newsletter-table.selectors';
import * as fromNewsletterForm from '../newsletter-form';
import * as fromNewsletterBlocks from '../newsletter-blocks';
import * as fromNewsletterWebSocket from '../newsletter-websocket';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { RdsDialogService } from '@rds/angular-components';
import { NewsletterTableModel } from '@app/newsletter/models';
import { NewsletterService } from '@app/core/services/newsletter.service';
import { RenameDialogComponent, RenameDialogData } from '@app/shared/dialogs/rename-dialog/rename-dialog.component';
import { ConfirmDialogComponent } from '@app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import {
	ManageRoleDialogComponent,
	ManageRoleDialogData,
} from '@shared/dialogs/manage-role-dialog/manage-role-dialog.component';
import { USER_ROLES_NEWSLETTER } from '@app/newsletter-new/models/user-roles.enum';

@Injectable()
export class NewsletterTableEffects {
	public initTable$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.init),
				switchMap(() =>
					this.store$.pipe(
						select(fromSelectors.selectTableRequestData),
						debounceTime(300),
						distinctUntilChanged((prev, next) => {
							if (prev.resetIndex && !next.resetIndex) {
								delete prev.resetIndex;
								delete next.resetIndex;
							}
							return JSON.stringify(prev) === JSON.stringify(next);
						}),
						map(({ filters, pageIndex, pageSize, sort, resetIndex }) => ({
							filters,
							sort,
							pageIndex: resetIndex ? 0 : pageIndex,
							pageSize,
						}))
					)
				),
				map((requestData) => fromActions.table.request(requestData))
			),
		{ dispatch: true }
	);

	public getTableRequest$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.request),
				switchMap((requestData) =>
					this.newsletterService.getAll(requestData).pipe(
						map((res) => ({
							data: res.data as Array<NewsletterTableModel>,
							pagination: res.pagination,
						}))
					)
				),
				map(({ data, pagination }) => fromActions.table.success({ data, pagination })),
				catchError(({ message }) => of(fromActions.table.failure({ error: message })))
			),
		{ dispatch: true }
	);

	public connectWebSocketOnInitTable$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.init),
				withLatestFrom(this.store$.pipe(select(fromNewsletterWebSocket.selectIsWebSocketConnected))),
				filter(([action, isConnected]) => !isConnected),
				map(() => fromNewsletterWebSocket.wsConnect())
			),
		{ dispatch: true }
	);

	public updateWebSocketOnTableUpdate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.table.success),
				withLatestFrom(
					this.store$.pipe(select(fromNewsletterWebSocket.selectIsWebSocketConnected)),
					this.store$.pipe(select(fromSelectors.selectTableDataContributedIds))
				),
				filter(([action, isConnected, newsletterIds]) => isConnected && newsletterIds.length > 0),
				map(() => fromNewsletterWebSocket.wsUpdateNewsletters())
			),
		{ dispatch: true }
	);

	// public openNewsPreview$ = createEffect(() =>
	// this.actions$.pipe(
	//   ofType(fromActions.openNewsPreview),
	//   switchMap(({id, backButton}) => this.newsletterService.getById(id).pipe(
	//     map((ticker) => ({ticker, backButton}))
	//   )),
	//   tap(({ticker, backButton}) => {
	//     const data: NewsletterPreviewDialogData = {
	//       ticker: {
	//         id: ticker.id,
	//         link: ticker.link,
	//         message: ticker.message,
	//         type: ticker.type
	//       },
	//       backButton,
	//       buttons: {
	//         editNewsletter: {
	//           visible: true,
	//           disabled: false
	//         }
	//       }
	//     }

	//     this.dialogService.open(NewsletterPreviewDialogComponent, {
	//       size: 'xl',
	//       data
	//     }
	//     )
	//   } )
	// ), { dispatch: false}
	// );

	public openDeleteNewsletterDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteNewsletter.dialog),
				map(({ newsletter }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							ids: [newsletter.id],
							title: `Are you sure you want to delete "${newsletter.displayName || newsletter.title}" newsletter draft?`,
							confirmButtonLabel: `Yes, delete`,
							confirmButtonType: 'warning',
						},
					});
					return { id: newsletter.id, dialog };
				}),
				switchMap(({ id, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.deleteNewsletter.request({ id }))
					)
				)
			),
		{ dispatch: true }
	);

	public deleteNewsletter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.deleteNewsletter.request),
				switchMap(({ id }) =>
					this.newsletterService.deleteNewsletter(id).pipe(
						map((newsletter) => fromActions.deleteNewsletter.success()),
						catchError(({ message }) => of(fromActions.deleteNewsletter.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);
	public openSendListCopyDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.shareNewsletter.dialog),

				map(({ newsletter }) => {
					const data: ManageRoleDialogData = {
						title: 'Manage newsletter editors',
						confirmButtonLabel: 'Change editors',
						item: {
							permissions: {
								owners: [],
								editors: [
									...newsletter.newsletterContributors.map((item) => {
										return { ...item, description: '', role: USER_ROLES_NEWSLETTER.EDITOR };
									}),
								],
								viewers: [],
							},
						},
					};

					const dialog = this.dialogService.open(ManageRoleDialogComponent, {
						size: 'l',
						data,
					});
					return { newsletter, dialog };
				}),
				switchMap(({ newsletter, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((data) => {
							return { newsletter, permissions: data.value };
						})
					)
				),
				map(({ newsletter, permissions }) => fromActions.shareNewsletter.request({ newsletter, permissions }))
			),
		{ dispatch: true }
	);

	public shareNewsletter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.shareNewsletter.request),
				switchMap(({ newsletter, permissions }) =>
					this.newsletterService
						.shareNewsletter(
							newsletter.id,
							permissions.owners.map((i) => i.identifier)
						)
						.pipe(
							map((newsletter) => fromActions.shareNewsletter.success({ newsletter })),
							catchError(({ message }) => of(fromActions.renameNewsletter.failure({ error: message })))
						)
				)
			),
		{ dispatch: true }
	);

	public openRenameNewsletterDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.renameNewsletter.dialog),
				map(({ newsletter }) => {
					const data: RenameDialogData = {
						title: 'Rename Newsletter',
						oldName: newsletter.displayName || newsletter.title,
						controlLabel: 'Name of the Newsletter',
						confirmButtonLabel: 'Rename Newsletter',
						required: true,
						maxLength: 512,
					};
					const dialog = this.dialogService.open(RenameDialogComponent, {
						size: 'l',
						data,
					});
					return { id: newsletter.id, name: newsletter.title, dialog };
				}),
				switchMap(({ id, name, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						map((name) => fromActions.renameNewsletter.request({ id, title: name }))
					)
				)
			),
		{ dispatch: true }
	);

	public renameNewsletter$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.renameNewsletter.request),
				switchMap(({ id, title }) =>
					this.newsletterService.rename(id, title).pipe(
						map((newsletter) =>
							fromActions.renameNewsletter.success({
								newsletter: {
									description: null,
									displayName: title,
									imagePreviewUrl: null,
									newsletterId: id,
									templateId: null,
								},
							})
						),
						catchError(({ message }) => of(fromActions.renameNewsletter.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	public openMoveToDraftDialog$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.moveToDraft.dialog),
				map(({ newsletter }) => {
					const dialog = this.dialogService.open(ConfirmDialogComponent, {
						data: {
							ids: [newsletter.id],
							title: `Are you sure you want to move to draft "${newsletter.displayName || newsletter.title}"?`,
							confirmButtonLabel: `Yes, move`,
							confirmButtonType: 'warning',
						},
					});
					return { newsletter, dialog };
				}),
				switchMap(({ newsletter, dialog }) =>
					dialog.afterClosed().pipe(
						filter((data) => !!data),
						switchMap((name) => [
							fromActions.moveToDraft.request({ newsletter }),
							fromNewsletterForm.getNewsletterById.request({ id: newsletter.id }),
						])
					)
				)
			),
		{ dispatch: true }
	);

	public moveToDraft$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromActions.moveToDraft.request),
				withLatestFrom(
					this.store$.pipe(select(fromNewsletterForm.selectFormForRequest)),
					this.store$.pipe(select(fromNewsletterBlocks.selectSections))
				),
				switchMap(([{ newsletter }, form, sections]) =>
					this.newsletterService.createDraftInlineNew({ ...form, scheduleSentDate: null }, sections).pipe(
						map((newsletter) => fromActions.deleteNewsletter.success()),
						catchError(({ message }) => of(fromActions.deleteNewsletter.failure({ error: message })))
					)
				)
			),
		{ dispatch: true }
	);

	constructor(
		private actions$: Actions,
		private store$: Store<fromReducer.State>,
		private newsletterService: NewsletterService,
		private dialogService: RdsDialogService
	) {}
}
