import {Action, createReducer, on} from '@ngrx/store';
import * as fromActions from './newsletter-form.actions';
import {FormStepStatus} from '@shared/form-status-badge/form-status-badge.component';
import {FormStatus} from '@app/newsletter-new/store/recipients-form';
import {
  DEFAULT_DISCLAIMER,
  EMPTY_NEWSLETTER_FORM,
  NewsletterStepsToStatus,
  RhNewsletterForm,
} from '@app/newsletter-new/models/newsletter';
import {createGuid} from '@shared/utils/guid';
import {RECIPIENTS_LIST_TYPE} from '@app/newsletter-new/models/recipients-list';

type RemappingStatus = 'not_started' | 'in_progress' | 'finished';

export interface StepModel {
  step: FormStepStatus,
  form: FormStatus
}

export interface State {
  agreementsAccepted: boolean;
  remappingStatus: RemappingStatus | any;
  newsletter: RhNewsletterForm;
  initialNewsletter: RhNewsletterForm;
  formChecked: boolean;
  currentStep: number;
  headerFooterStatus: StepModel;
  bodyStatus: StepModel;
  recipientsStatus: StepModel;
  blockStatus: StepModel;
  publishingStatus: StepModel;
}

export const initialState: State = {
  agreementsAccepted: false,
  remappingStatus: 'not_started',
  newsletter: EMPTY_NEWSLETTER_FORM,
  initialNewsletter: EMPTY_NEWSLETTER_FORM,
  formChecked: false,
  currentStep: 0,
  headerFooterStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID',
  },
  bodyStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID',
  },
  recipientsStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID',
  },
  blockStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID',
  },
  publishingStatus: {
    step: FormStepStatus.NOT_DEFINED,
    form: 'INVALID',
  },
};

export const newsletterFormReducer = createReducer(
  initialState,
  on(fromActions.userAgreement.confirmed, (state) => ({
    ...state,
    agreementsAccepted: true,
  })),
  on(fromActions.remapping.start, (state) => ({
    ...state,
    remappingStatus: 'in_progress',
  })),
  on(fromActions.remapping.finish, (state) => ({
    ...state,
    remappingStatus: 'finished',
  })),
  on(fromActions.getNewsletterById.success, (state, {newsletter}) => ({
    ...state,
    newsletter,
  })),
  on(fromActions.form.setStepStatus, (state, {status, step}) => ({
    ...state,
    [NewsletterStepsToStatus[step]]: {
      ...state[NewsletterStepsToStatus[step]],
      step: status,
    },
  })),
  on(fromActions.form.setStepIsValid, (state, {status, step}) => ({
    ...state,
    [NewsletterStepsToStatus[step]]: {
      ...state[NewsletterStepsToStatus[step]],
      form: status,
    },
  })),
  on(fromActions.form.setChecked, (state) => ({
    ...state,
    formChecked: true,
  })),
  on(fromActions.form.setValue, (state, {form}) => ({
    ...state,
    newsletter: {
      ...state.newsletter,
      ...form,
    },
  })),
  on(fromActions.form.setCurrentStep, (state, {currentStep}) => ({
    ...state,
    currentStep,
  })),
  on(fromActions.form.resetCustomDisclaimer, (state) => ({
    ...state,
    newsletter: {
      ...state.newsletter,
      disclaimer: DEFAULT_DISCLAIMER,
    },
  })),
  on(fromActions.form.setInitialValue, (state, {form}) => ({
    ...state,
    initialNewsletter: {
      ...state.initialNewsletter,
      ...form,
    },
  })),
  on(fromActions.form.clearForm, (state) => ({
    ...initialState
  })),
  on(fromActions.form.addFromFile, (state, {destination, results}) => {
    let users = {};
    users[destination] = [...state.newsletter[destination], ...results].map((item) => {
      return {
        ...item,
        id: createGuid(),
      };
    });
    return {
      ...state,
      newsletter: {
        ...state.newsletter,
        ...users,
      },
    };
  }),
  on(fromActions.getCampaignById.success, (state, {campaign}) => {
    // let key = destination === RECIPIENTS_LIST_TYPE.RECIPIENT ? 'recipients' : 'blockedRecipients';
    const mergedRecipients = campaign.recipientLists.reduce((result, item) => [...result, ...item['recipients']], []);
    const mergedBlockedRecipients = campaign.recipientLists.reduce(
      (result, item) => [...result, ...item['blockedRecipients']],
      []
    );

    let singleRecipients = mergedRecipients.filter((u) => u.type === 0).map((u) => ({...u, id: createGuid()}));
    let groupsRecipients = mergedRecipients.filter((u) => u.type === 1);

    let singleBlockedRecipients = mergedBlockedRecipients
      .filter((u) => u.type === 0)
      .map((u) => ({
        ...u,
        id: createGuid(),
      }));
    let groupsBlockedRecipients = mergedBlockedRecipients.filter((u) => u.type === 1);
    // let singleUsers = mergedUsers.filter((u) => u.type === 0).map((u) => ({...u, id: createGuid()}));
    // let groups = mergedUsers.filter((u) => u.type === 1);
    //
    // let users = {};
    return {
      ...state,
      newsletter: {
        ...state.newsletter,
        campaignId: campaign.id,
        campaignName: campaign.name,
        recipientUsers: singleRecipients,
        recipientRada: groupsRecipients,
        groupsBlockedRecipients: singleBlockedRecipients,
        blockRada: groupsBlockedRecipients,
      },
    };
  }),
  on(fromActions.form.addFromSelect, (state, {destination, results}) => {
    let key = destination === RECIPIENTS_LIST_TYPE.RECIPIENT ? 'recipients' : 'blockedRecipients';
    const mergedUsers = results?.reduce((result, item) => [...result, ...item[key]], []);

    let singleUsers = mergedUsers.filter((u) => u.type === 0).map((u) => ({...u, id: createGuid()}));
    let groups = mergedUsers.filter((u) => u.type === 1);

    let users = {};
    if (destination === RECIPIENTS_LIST_TYPE.RECIPIENT) {
      users = {
        recipientRada: [...state.newsletter['recipientRada'], ...groups].reduce((result, item) => {
          if (result.findIndex((r) => r.name === item.name) === -1) {
            result.push(item);
          }
          return result;
        }, []),
        recipientUsers: [...state.newsletter[destination], ...singleUsers].reduce((result, item) => {
          if (result.findIndex((r) => r.email === item.email) === -1) {
            result.push(item);
          }
          return result;
        }, []),
      };
    } else {
      users = {
        blockRada: [...state.newsletter['blockRada'], ...groups].reduce((result, item) => {
          if (result.findIndex((r) => r.name === item.name) === -1) {
            result.push(item);
          }
          return result;
        }, []),
        blockUsers: [...state.newsletter[destination], ...singleUsers].reduce((result, item) => {
          if (result.findIndex((r) => r.email === item.email) === -1) {
            result.push(item);
          }
          return result;
        }, []),
      };
    }
    return {
      ...state,
      newsletter: {
        ...state.newsletter,
        ...users,
      },
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterFormReducer(state, action);
}

export const getAgreementsAccepted = (state: State) => state.agreementsAccepted;
export const getRemappingStatus = (state: State) => state.remappingStatus;
export const getNewsletter = (state: State) => state.newsletter;
export const getInitialNewsletter = (state: State) => state.initialNewsletter;
export const getNewsletterMetadata = (newsletter: RhNewsletterForm) => ({
  newsletterId: newsletter?.newsletterId,
  templateId: newsletter?.templateId,
  isContributed: newsletter?.isContributed,
});

export const getFormChecked = (state: State) => state.formChecked;
export const getCurrentStep = (state: State) => state.currentStep;

export const getHeaderFooterStepStatus = (state: State) => state.headerFooterStatus.step;
export const getHeaderFooterFormStatus = (state: State) => state.headerFooterStatus.form;

export const getBodyStepStatus = (state: State) => state.bodyStatus.step;
export const getBodyFormStatus = (state: State) => state.bodyStatus.form;
export const getRecipientsStepStatus = (state: State) => state.recipientsStatus.step;
export const getRecipientsFormStatus = (state: State) => state.recipientsStatus.form;
export const getBlockStepStatus = (state: State) => state.blockStatus.step;
export const getBlockFormStatus = (state: State) => state.blockStatus.form;

export const getPublishingStepStatus = (state: State) => state.publishingStatus.step;
export const getPublishingFormStatus = (state: State) => state.publishingStatus.form;
export const getCampaignName = (state: State) => state.newsletter.campaignName;
