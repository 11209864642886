<div #formField>
    <rds-form-field [formGroup]="form" *ngIf="!disabled">
        <rds-control-label [requireAsterisk]="required">
            Enter user name or email
        </rds-control-label>
        <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
        <input
                rdsInput
                (focus)="focus()"
                (blur)="blur()"
                formControlName="search"
        />
    </rds-form-field>
    <div rdsDropdown>
        <button rds-dropdown-toggle-button size="l" class="user__value-container--autocomplete-trigger"
                [rdsMenuTriggerFor]="menu"
                #trigger="rdsMenuTrigger"
                [isOpen]="trigger.menuOpen"
        >Toggle
        </button>
        <rds-menu #menu="rdsMenu" class="user__value-container--autocompletes">
            <ng-container *ngIf="autocomplete.loading">
                <div [ngStyle]="{'width.px': formField.offsetWidth}"
                     class="user__value-container--autocompletes__row loading">
                    <rds-progress-bar></rds-progress-bar>
                </div>
            </ng-container>
            <ng-container *ngIf="!autocomplete.loading">
                <div *ngIf="autocomplete.suggestions.length === 0" [ngStyle]="{'width.px': formField.offsetWidth}"
                     class="user__value-container--autocompletes__row not-found">
          <span>
            User not found
          </span>
                </div>
                <div *ngFor="let option of autocomplete.suggestions"
                     (click)="selectedIncludesUser(option.identifier || option.login) && canRemove(option.identifier || option.login)? remove(option) : select(option)"
                     [ngStyle]="{'width.px': formField.offsetWidth}" class="user__value-container--autocompletes__row"
                     [class.is-selected]="selectedIncludesUser(option.identifier)"
                     [class.disabled]="!canRemove(option.identifier)">
                    <rh-user-single [user]="option"></rh-user-single>
                    <span [innerHTML]="(option.name || option.firstName + ' ' + option.lastName) + ' (' + option.email + ')' | highlight:form.controls.search.value"></span>
                    <rds-icon *ngIf="!selectedIncludesUser(option.identifier)" namespace="filled"
                              icon="plus"></rds-icon>
                    <rds-icon *ngIf="selectedIncludesUser(option.identifier)" namespace="filled"
                              icon="checkmark"></rds-icon>
                </div>
            </ng-container>
        </rds-menu>
    </div>
    <rh-subscript-wrapper *ngIf="!!control && !disabled" [hasError]="!control.valid && control.touched"
                          [disabled]="disabled">
        <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
        <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
    </rh-subscript-wrapper>
    <div *ngIf="selectedUsers?.length > 0" class="user__value-container" [class.readonly]="disabled">
        <div *ngIf="selectedUsers.length > 0" class="user__value-container--selected">
            <div *ngFor="let selected of selectedUsers" class="row row-centered">
                <div class="col-auto">
                    <rh-user-single [user]="selected"></rh-user-single>
                </div>
                <div class="col">
                    <p class="ui-label-m-bold">
                        {{selected.name || (selected.firstName + ' ' + selected.lastName)}} <span
                            *ngIf="!!selected.email" class="ui-label-s">({{selected.email}})</span>
                    </p>
                    <p *ngIf="!!labelFn" class="ui-label-s-bold">
                        {{labelFn(selected) }}
                    </p>
                </div>
                <div *ngIf="!disabled" class="col-auto">
                    <button rds-icon-button size="m" (click)="remove(selected)"
                            [disabled]="!canRemove(selected.identifier || selected.login) || disabled">
                        <rds-icon icon="delete" namespace="outlined"></rds-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
