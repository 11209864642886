import {Component, EventEmitter, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomValidators} from '@app/shared/form-controls/validators/validator.function';
import {RdsMenuTriggerDirective} from '@rds/angular-components';
import {debounceTime, distinctUntilChanged} from 'rxjs';

@Component({
  selector: 'rh-topic-picker',
  templateUrl: './topic-picker.component.html',
  styleUrls: ['./topic-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TopicPickerComponent
    }
  ]
})
export class TopicPickerComponent implements OnInit {
  @ViewChild("trigger", {static: false}) trigger: RdsMenuTriggerDirective;

  selectedTopics: Array<string> = [];

  onChange = (topics) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(suggestions: Array<string>) {
    this.selectedTopics = suggestions;

  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.form.disable() : this.form.enable();
    this.disabled = disabled;
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  _autocomplete: { suggestions: Array<string>, loading: boolean };
  get autocomplete(): { suggestions: Array<string>, loading: boolean } {
    return this._autocomplete;
  }

  @Input() set autocomplete(value: { suggestions: Array<string>, loading: boolean }) {
    this._autocomplete = {
      suggestions: this.form.controls.search.value.length > 0 ? value.suggestions : [],
      loading: value.loading
    };
    if (!!this.trigger) {
      this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading ? this.trigger.openMenu() : this.trigger.closeMenu();
    }
  }

  @Input() formControl!: FormControl;
  @Input() formControlName!: string;
  @Input() required = false;
  @Input() label: string = 'Enter topics or select from suggestion';
  @Input() chipPlaceholder: string = 'topic';

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }


  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  @Input() loggedTopicIdentifier: string;
  @Input() labelFn: (args: any) => string;
  @Input() allowRemoveSelf: boolean = false;
  @Input() multi: boolean = false;

  selectedIncludesTopic(topic: string) {
    return this.selectedTopics.findIndex(a => a.toLowerCase() === topic.toLowerCase()) > -1;
  }

  focus() {
    if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
      this.trigger.openMenu()
    }
  }

  blur() {
    this.markAsTouched();
  }

  select(topic: string) {
    if (!this.selectedTopics.map(t => t.toLowerCase()).includes(topic.toLowerCase().trim())) {
      this.multi ? this.selectedTopics = [...this.selectedTopics, topic.trim()] : this.selectedTopics = [topic.trim()];

      this.form.controls.search.setValue('');
      this.selected.emit();
      this.onChange(this.selectedTopics.slice())
    }
  }

  remove(index: number) {
    this.selectedTopics = this.selectedTopics.filter((t, i) => i !== index);

    this.form.controls.search.setValue('');
    this.removed.emit();
    this.onChange(this.selectedTopics)
  }

  ngOnInit(): void {
    this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.search.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function () {
      originalMarkAsTouched.apply(this, arguments);
      that.form.controls.search.markAsTouched();
      that.form.controls.search.updateValueAndValidity();
    }

    this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.form.controls.search.updateValueAndValidity();
    });

    this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe(s => this.search.emit(s));
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {
  }

}
