<h2 rds-dialog-header>{{ data.title || 'Edit users and their roles' }}</h2>
<div rds-dialog-content>
	<div class="row" [formGroup]="form">
		<div class="col mt-9">
			<div class="row">
				<div class="col mt-5">
					<rh-people-role-picker
						(search)="getAutocompletes('permissions', $event)"
						(selected)="getAutocompletes('permissions', '')"
						(removed)="getAutocompletes('permissions', '')"
						formControlName="permissions"
						[roles]="TEMPLATE_ROLES"
						defaultRole="owners"
						[autocomplete]="permissionsAutocomplete$ | async"
					>
						<rds-control-error *ngIf="form.controls.permissions.errors?.atLeastOneOwnerRequired"
							>At least one Owner is required
						</rds-control-error>
					</rh-people-role-picker>
				</div>
			</div>
		</div>
	</div>
</div>
<div rds-dialog-actions-container align="end">
	<button rds-secondary-button [rds-dialog-close]="null" size="m">Cancel</button>
	<button
		rds-primary-button
		[disabled]="form.get('permissions').valid === false || (initialRolesNotChanged && initialUsersNotChanged)"
		[rds-dialog-close]="{
			value: form.get('permissions').value,
			initialUsersNotChanged,
			initialRolesNotChanged,
			isMyTemplate
		}"
		size="m"
	>
		Update user permissions
	</button>
</div>
