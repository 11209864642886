import {FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition} from '../filters-model';

export interface CampaignNewsletterTableFilters {
    statuses?: SelectFilterDefinition;
    search?: FilterDefinition;
}

export const NEWSLETTER_STATUS = [
    {label: 'Draft', value: 0},
    {label: 'Sent', value: 1},
    {label: 'Scheduled', value: 2},
    {label: 'Template', value: 3},
    {label: 'Shared template', value: 4},
];

export const INITIAL_CAMPAIGN_NEWSLETTER_FILTERS: CampaignNewsletterTableFilters = {
    statuses: {
        type: FilterTypeEnum.SELECT,
        changable: true,
        value: [],
        label: 'Status',
        multiple: true,
        options: NEWSLETTER_STATUS,
    },
    ...SEARCH_FILTER,
};
