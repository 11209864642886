import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { Pagination } from '@core/models';
import { SortDirection } from '@rds/angular-components';
import { NewsletterTableFilters } from '@app/shared/filters/models/newsletter-table';
import { BackButton } from '@app/root-store/ui/back-button';
import { NewsletterRenameResponse, NewsletterTableModel } from '@app/core/models/newsletter.model';

export const table = createActionGroup({
	source: 'NEWSLETTER TABLE',
	events: {
		init: emptyProps(),
		clear: emptyProps(),
		request: props<{
			pageIndex: number;
			pageSize: number;
			sort: {
				active: string;
				direction: SortDirection;
			};
			filters: { [key: string]: any };
		}>(),
		success: props<{ data: Array<NewsletterTableModel>; pagination: Pagination }>(),
		failure: props<{ error: any }>(),
		filter: props<{ filters: NewsletterTableFilters }>(),
		sort: props<{ active: string; direction: SortDirection }>(),
		paginate: props<{ pageIndex: number; pageSize: number }>(),
	},
});

export const openNewsPreview = createAction(
	'[NEWSLETTER TABLE] Open news preview',
	props<{ id: number; backButton: BackButton }>()
);

export const deleteNewsletter = createActionGroup({
	source: 'DELETE NEWSLETTER',
	events: {
		dialog: props<{ newsletter: NewsletterTableModel }>(),
		request: props<{ id: number }>(),
		success: emptyProps(),
		failure: props<{ error: string }>(),
	},
});

export const renameNewsletter = createActionGroup({
	source: 'RENAME NEWSLETTER',
	events: {
		dialog: props<{ newsletter: NewsletterTableModel }>(),
		request: props<{ id: number; title: string }>(),
		success: props<{ newsletter: NewsletterRenameResponse }>(),
		failure: props<{ error: string }>(),
	},
});
export const shareNewsletter = createActionGroup({
	source: 'SHARE NEWSLETTER',
	events: {
		dialog: props<{ newsletter: NewsletterTableModel }>(),
		request: props<{
			newsletter: NewsletterTableModel;
			permissions: {
				owners: any[];
				editors: any[];
			};
		}>(),
		success: props<{ newsletter: NewsletterRenameResponse }>(),
		failure: props<{ error: string }>(),
	},
});
export const moveToDraft = createActionGroup({
	source: 'MOVE TO DRAFT',
	events: {
		dialog: props<{ newsletter: NewsletterTableModel }>(),
		request: props<{
			newsletter: NewsletterTableModel;
		}>(),
		success: props<{ newsletter: NewsletterRenameResponse }>(),
		failure: props<{ error: string }>(),
	},
});
