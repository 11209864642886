import {createAction, props} from '@ngrx/store';
import {NewsletterValueModel} from '@app/newsletter/models';
import {SearchingPreferencesIds} from '@core/models';
import { NewsletterEditType } from '@app/newsletter/models/newsletter-edit-type.enum';
export const formUpdated = createAction(
  '[NEWSLETTER - FORM] Newsletter updated',
  props<{ form: NewsletterValueModel }>()
);

export const changeTemplate = createAction(
  '[NEWSLETTER - FORM] Change template',
  props<{ templateId: string }>()
);

export const setEditType = createAction(
  '[NEWSLETTER - FORM] Set edit type',
  props<{ editType: NewsletterEditType}>()
);

export const clearEditType = createAction(
  '[NEWSLETTER - FORM] Clear edit type'
);

export const getEmailFromNewsletter = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from'
);

export const getEmailFromNewsletterSuccess = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from success',
  props<{ fromEmail: string }>()
);

export const getEmailFromNewsletterFailure = createAction(
  '[NEWSLETTER - GET EMAIL] Get email from failure'
);
export const refreshNewsletterInit = createAction(
  '[NEWSLETTER -REFRESH NEWS] init',
  props<{ channelsId: string[], language: string }>()
);
export const refreshNewsletterSuccess = createAction(
  '[NEWSLETTER -REFRESH NEWS] success'
);

export const putSearchingPreferencesCountRequest = createAction(
  '[NEWSLETTER - NEWSLETTER FORM] Put Searching Preferences Count',
  props<{ preferencesIds: SearchingPreferencesIds }>()
);

export const putSearchingPreferencesCountSuccess = createAction(
  '[NEWSLETTER - NEWSLETTER FORM] Put Searching Preferences Count Success,',
  props<{ usersCount: number }>()
);

export const putSearchingPreferencesCountFailure = createAction(
  '[NEWSLETTER - NEWSLETTER FORM] Put Searching Preferences Count Failed',
  props<{ error: any }>()
);

export const getUserHasAnyRoleInNewsletterSuccess = createAction(
  '[NEWSLETTER - GET USER ANY ROLE SUCCESS] GET User Has Any Role Success',
  props<{ hasAnyRole: boolean }>()
);

export const reorderNews = createAction(
  '[NEWSLETTER FORM] Reorder news',
  props<{
    sectionIndex: string,
    oldNewsIndex: number,
    newNewsIndex: number}>()
);

export const currentNewsMoved = createAction(
  '[NEWSLETTER - NEWS MOVED] '
);
export const clearMoved = createAction(
  '[NEWSLETTER - NEWS MOVED] Clear'
);
export const loadDynamicLinks = createAction(
  '[NEWSLETTER - DYNAMIC LINKS] Load',
);

export const getNewsletterById = createAction(
  '[NEWSLETTER - GET] Get newsletter by id',
  props<{ id: number }>());

export const getNewsletterByIdWithChannels = createAction(
  '[NEWSLETTER - GET] Get newsletter by id with channels',
  props<{ id: number }>()
);

export const getNewsletterByIdWithChannelsFailure = createAction(
  '[NEWSLETTER - GET] Get newsletter by id with channels failure'
);

export const getAllNeededNews = createAction('[NEWSLETTER - GET NEEDED NEWS] Get news',
  props<{ loadedForm: NewsletterValueModel }>());


export const getNewsletterByIdSuccess = createAction(
  '[NEWSLETTER - FORM LOADED] success',
  props<{ loadedForm: NewsletterValueModel }>());

export const getNewsletterByIdFailure = createAction(
  '[NEWSLETTER - FORM LOADED] failed',
  props<{ message: string }>()
);

export const clearLoadedForm = createAction(
  '[NEWSLETTER - FORM LOADED] cleared'
);
export const clearForm = createAction(
  '[NEWSLETTER - FORM] cleared'
);
