import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';
import * as fromDashboard from './newsletter-dashboard/newsletter-dashboard.reducer';
import * as fromOther from './newsletter-other/newsletter.reducer';
import * as fromNewsletterForm from './newsletter-form/newsletter-form.reducer';
import * as fromNewsletterInline from './newsletter-inline/newsletter-inline.reducer';
import * as fromNewsletterBlocks from './newsletter-blocks/newsletter-blocks.reducer';
import * as fromNewsletterSavedTemplates from './newsletter-saved-templates/newsletter-saved-templates.reducer';
import * as fromNewsletterWebSocket from './newsletter-websocket/newsletter-websocket.reducer';
import * as fromNewsletterStatistics from './newsletter-statistics/newsletter-statistics.reducer';
import * as fromNewsletterShare from './newsletter-share/newsletter-share.reducer';
export const feature = 'newsletter';

export interface State {
  newsletterDashboard: fromDashboard.State;
  newsletterOther: fromOther.State;
  newsletterForm: fromNewsletterForm.State;
  newsletterInline: fromNewsletterInline.State;
  newsletterBlocks: fromNewsletterBlocks.State;
  newsletterStatistics: fromNewsletterStatistics.State;
  newsletterSavedTemplates: fromNewsletterSavedTemplates.State;
  newsletterWebSocket: fromNewsletterWebSocket.State;
  newsletterShare: fromNewsletterShare.State;
}

export function reducer(state: State | undefined, action: Action) {
  return combineReducers({
    newsletterDashboard: fromDashboard.reducer,
    newsletterOther: fromOther.reducer,
    newsletterForm: fromNewsletterForm.reducer,
    newsletterInline: fromNewsletterInline.reducer,
    newsletterBlocks: fromNewsletterBlocks.reducer,
    newsletterStatistics: fromNewsletterStatistics.reducer,
    newsletterSavedTemplates: fromNewsletterSavedTemplates.reducer,
    newsletterWebSocket: fromNewsletterWebSocket.reducer,
    newsletterShare: fromNewsletterShare.reducer
  })(state, action);
}

export const selectState = createFeatureSelector<State>(feature);
export const getNewsletterDashboard = (state: State) => state.newsletterDashboard;
export const getNewsletterOther = (state: State) => state.newsletterOther;
export const getNewsletterForm = (state: State) => state.newsletterForm;
export const getNewsletterInline = (state: State) => state.newsletterInline;
export const getNewsletterBlocks = (state: State) => state.newsletterBlocks;
export const getNewsletterStatistics = (state: State) => state.newsletterStatistics;
export const getNewsletterSavedTemplates = (state: State) => state.newsletterSavedTemplates;
export const getNewsletterWebSocket = (state: State) => state.newsletterWebSocket;
export const getNewsletterShare = (state: State) => state.newsletterShare;
