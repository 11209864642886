import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'min'
})
export class MinPipe implements PipeTransform {

  transform(args: Array<number>, value: any): any {
    return Math.min(...args);
  }

}
