<div #formField>
    <rds-form-field [formGroup]="form" *ngIf="!disabled">
        <rds-control-label [requireAsterisk]="required">
            Enter event name
        </rds-control-label>
        <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
        <input
                rdsInput
               (focus)="focus()"
                (blur)="blur()"
                formControlName="search"
        />
    </rds-form-field>
    <div rdsDropdown>
        <button rds-dropdown-toggle-button size="l" class="event__value-container--autocomplete-trigger"
                [rdsMenuTriggerFor]="menu"
                #trigger="rdsMenuTrigger"
                [isOpen]="trigger.menuOpen"
        >Toggle
        </button>
        <rds-menu #menu="rdsMenu" class="event__value-container--autocompletes">
            <ng-container *ngIf="autocomplete.loading">
                <div [ngStyle]="{'width.px': formField.offsetWidth}"
                     class="event__value-container--autocompletes__row loading">
                    <rds-progress-bar></rds-progress-bar>
                </div>
            </ng-container>
            <ng-container *ngIf="!autocomplete.loading">
                <div *ngIf="autocomplete.suggestions.length === 0" [ngStyle]="{'width.px': formField.offsetWidth}"
                     class="event__value-container--autocompletes__row not-found">
          <span>
            Event not found
          </span>
                </div>
                <div *ngFor="let option of autocomplete.suggestions"
                        rds-list-item
                     (click)="selectedIncludesEvent(option.id) && canRemove(option.id)? remove(option) : select(option)"
                     [ngStyle]="{'width.px': formField.offsetWidth}" class="event__value-container--autocompletes__row"
                     [class.is-selected]="selectedIncludesEvent(option.id)"
                     [class.disabled]="!canRemove(option.id)">
                     <div style="display: flex;gap: 24px; align-items: center;">
                        <img style="height: 36px; aspect-ratio: 16/9" [src]="option.imageUrl || '/assets/images/news-placeholder.png'" alt="">
                        <div style="display: flex; flex-direction: column; min-width: 0;">
                          <div style="display: flex; min-width: 0; gap: 8px;">
                            <rds-badge size="s" [label]="option.date | date:'dd LLL yyyy' | uppercase" type="minimal" color="purple"></rds-badge>
                            <p class="ui-label-m-bold text-ellipsis">
                              {{option.title}}
                            </p>
                          </div>
                          <p class="ui-label-s text-ellipsis">
                            {{option.description}}
                          </p>
                        </div>
                      </div>
                </div>
            </ng-container>
        </rds-menu>
    </div>
    <rh-subscript-wrapper *ngIf="!!control && !disabled" [hasError]="!control.valid && control.touched"
                          [disabled]="disabled">
        <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
        <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
    </rh-subscript-wrapper>
    <div *ngIf="selectedEvents?.length > 0" class="event__value-container" [class.readonly]="disabled">
        <div *ngIf="selectedEvents.length > 0" class="event__value-container--selected">
            <div *ngFor="let selected of selectedEvents" class="row row-centered">
                <div class="col-auto" style="display: flex;gap: 24px; align-items: center;">
                    <img style="height: 36px; aspect-ratio: 16/9" [src]="selected.imageUrl || '/assets/images/news-placeholder.png'" alt="">
                    <div style="display: flex; flex-direction: column; min-width: 0;">
                      <div style="display: flex; min-width: 0; gap: 8px;">
                        <rds-badge size="s" [label]="selected.date | date:'dd LLL yyyy' | uppercase" type="minimal" color="purple"></rds-badge>
                        <p class="ui-label-m-bold text-ellipsis">
                          {{selected.title}}
                        </p>
                      </div>
                      <p class="ui-label-s text-ellipsis">
                        {{selected.description}}
                      </p>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</div>
