import {createAction, props} from '@ngrx/store';
import { SharedNewsletter } from '.';

export const wsConnect = createAction(
  '[NEWSLETTER2 - WS] Connect'
);

export const wsConnectAndCheckNewsletter = createAction(
  '[NEWSLETTER2 - WS] Connect and check newsletter',
  props<{newsletterId: number}>()
);

export const wsConnected = createAction(
  '[NEWSLETTER2 - WS] Connected'
);

export const wsSetIsConnected = createAction(
  '[NEWSLETTER2 - WS] Set is connected'
);

export const wsDisconnect = createAction(
  '[NEWSLETTER2 - WS] Disconnect'
);

export const wsDisconnectIfConnected = createAction(
  '[NEWSLETTER2 - WS] Disconnect if connected'
);

export const wsDisconnected = createAction(
  '[NEWSLETTER2 - WS] Disconnected'
);

export const wsStartKeepAlive = createAction(
  '[NEWSLETTER2 - WS] Start keep alive!'
);

export const wsStopKeepAlive = createAction(
  '[NEWSLETTER2 - WS] Stop keep alive!'
);

// FROM CLIENT

export const wsUpdateNewsletters = createAction(
  '[NEWSLETTER2 - WS] Init User'
);

export const wsUpdateNewslettersWithNewsletterId = createAction(
  '[NEWSLETTER2 - WS] Init User with Newsletter Id',
  props<{newsletterId: number}>()
);

export const wsStartWork = createAction(
  '[NEWSLETTER2 - WS] Start work',
  props<{newsletterId: number}>()
);

export const wsStopWork = createAction(
  '[NEWSLETTER2 - WS] Stop work',
  props<{newsletterId: number}>()
);

export const wsCheckNewsletter = createAction(
  '[NEWSLETTER2 - WS] Check newsletter',
  props<{newsletterId: number}>()
);

export const wsNewsletterDeleted = createAction(
  '[NEWSLETTER2 - WS] Newsletter deleted',
  props<{newsletterId: number}>()
);

export const wsMovedToDraft = createAction(
  '[NEWSLETTER2 - WS] Newsletter moved to drafts',
  props<{newsletterId: number}>()
);

export const wsUnshare = createAction(
  '[NEWSLETTER2 - WS] Newsletter unshare',
  props<{newsletterId: number}>()
);

// TO CLIENT
export const wsMessageReceived = createAction(
  '[NEWSLETTER2 - WS] Message received',
  props<{messageType: string, data: any, singleNewsletter: boolean}>()
);

export const wsNewsletterInfo = createAction(
  '[NEWSLETTER2 - WS] Newsletter info message',
  props<({ newsletters: Array<SharedNewsletter>, singleNewsletter: boolean})>()
);

export const wsNewsletterTaken = createAction(
  '[NEWSLETTER2 - WS] Newsletter taken message',
  props<({ newsletter: SharedNewsletter, singleNewsletter: boolean})>()
);

export const wsNewsletterReleased = createAction(
  '[NEWSLETTER2 - WS] Newsletter released message',
  props<({ newsletter: SharedNewsletter, singleNewsletter: boolean})>()
);

export const wsNewsletterAccessDenied = createAction(
  '[NEWSLETTER2 - WS] Newsletter access denied message',
  props<({ newsletterId: number, singleNewsletter: boolean})>()
);

export const wsNewsletterAccessGranted = createAction(
  '[NEWSLETTER2 - WS] Newsletter access granted message',
  props<({ newsletterId: number, singleNewsletter: boolean})>()
);

export const wsUpdateRequired = createAction(
  '[NEWSLETTER2 - WS] Newsletter update required'
);

export const wsUnknownMessage = createAction(
  '[NEWSLETTER2 - WS] Newsletter unknown message',
  props<({messageType: string, data: any, singleNewsletter: boolean})>()
);
