import { Injectable } from '@angular/core';
import { ApiService, HttpError } from './api/api.service';
import { EndpointsService } from './endpoints/endpoints.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ManagingChannelsTableForm,
  ManagingChannel,
  ManagingChannelFilterRequest,
  ManagingChannelFilterResponse } from '../models/managing-channels.model';
import { SortEnum } from '../enums/sort.enum';
import { UserChannel } from '../models';
@Injectable()
export class ManagingChannelsService {

  public putAllChannelsFilter(query: ManagingChannelsTableForm): Observable<{allChannels: Array<ManagingChannel>, totalCount: number}> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL.FILTER.PUT;
    const queryParamsUrl = this.endpoints.addParams(url, {pageIndex: query.pageIndex, pageSize: query.pageSize});
    const body: ManagingChannelFilterRequest = {
      order: this.prepareSortParam(query.sort),
      name: query.filters.name.trim(),
      description: query.filters.description.trim(),
      departments: query.filters.departments,
      locations: query.filters.locations,
      functions: query.filters.functions,
    };
    return this.http
      .put(queryParamsUrl, body)
      .pipe(
        map((response: ManagingChannelFilterResponse) => ({allChannels: response.data, totalCount: response.totalCount})),
        catchError((error: HttpError) => throwError('Cannot get All channels'))
      );
  }

  public putAllChannelsSuggestions(
    searchProperty: string,
    searchPhrase: string
  ): Observable<{suggestions: Array<{id: number, name: string}> }> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL.SEARCH.PUT;
    const queryParamsUrl = this.endpoints.addParams(url, {searchProperty, searchPhrase});

    const body = {
      name: null,
      description: null,
      departments: [],
      locations: [],
      functions: [],
    };

    return this.http
      .put(queryParamsUrl, body)
      .pipe(
        map((response) => ({
          suggestions: response.map((suggestion, index) => ({
            id: index,
            name: suggestion
          }))
        })),
        catchError((error: HttpError) => throwError('Cannot get All channels suggestions'))
      );
  }

  public putMyFavoriteChannelsFilter(query: ManagingChannelsTableForm): Observable<{myFavoriteChannels: Array<ManagingChannel>, totalCount: number}> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.FAVOURITES.FILTER.PUT;
    const queryParamsUrl = this.endpoints.addParams(url, {pageIndex: query.pageIndex, pageSize: query.pageSize});
    const body: ManagingChannelFilterRequest = {
      order: this.prepareSortParam(query.sort),
      name: query.filters.name.trim(),
      description: query.filters.description.trim(),
      departments: query.filters.departments,
      locations: query.filters.locations,
      functions: query.filters.functions,
    };

    return this.http
      .put(queryParamsUrl, body)
      .pipe(
        map((response: ManagingChannelFilterResponse) => ({myFavoriteChannels: response.data, totalCount: response.totalCount})),
        catchError((error: HttpError) => throwError('Cannot get User channels'))
      );
  }

  public putMyFavoriteChannelsSuggestions(
    searchProperty: string,
    searchPhrase: string
  ): Observable<{ suggestions: Array<{id: number, name: string}> }> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.FAVOURITES.SEARCH.PUT;
    const queryParamsUrl = this.endpoints.addParams(url, {searchProperty, searchPhrase});

    const body = {
      name: null,
      description: null,
      departments: [],
      locations: [],
      functions: [],
    };

    return this.http
      .put(queryParamsUrl, body)
      .pipe(
        map((response) => ({
          suggestions: response.map((suggestion, index) => ({
            id: index,
            name: suggestion
          }))
        })),
        catchError((error: HttpError) => throwError('Cannot get My favorite channels suggestions'))
      );
  }

  public deleteUserChannelsByIds(ids: Array<string>): Observable<{updatedChannels: Array<UserChannel>}> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.DELETE;

    return this.http
      .deleteWithBody(url, ids)
      .pipe(
        map((response: Array<UserChannel>) => ({updatedChannels: response})),
        catchError((error: HttpError) => throwError('Cannot delete User channels'))
      );
  }

  public postUserChannelsByIds(ids: Array<string>): Observable<{updatedChannels: Array<UserChannel>}> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.DELETE;

    return this.http
      .post(url, ids)
      .pipe(
        map((response: Array<UserChannel>) => ({updatedChannels: response})),
        catchError((error: HttpError) => throwError('Cannot add User channels'))
      );
  }

  public postSharedChannel(id: string): Observable<{updatedChannels: Array<UserChannel>; sharedChannelName: string; }> {
    const url = this.endpoints.ENDPOINT.CONTENT.USER_CHANNEL.DELETE;

    return this.http
      .post(url, [id])
      .pipe(
        map((response: Array<UserChannel>) => ({
          updatedChannels: response,
          sharedChannelName: response.find(channel => channel.id === id).name
        })),
        catchError((error: HttpError) => throwError('Cannot add User channels'))
      );
  }

  public getChannelById(id: string): Observable<{channel: ManagingChannel}> {
    const url = this.endpoints.replaceUrlTokens(
      this.endpoints.ENDPOINT.CONTENT.CHANNEL.DETAILS.GET, { id }
    );

    return this.http
      .get(url)
      .pipe(
        map((response: ManagingChannel) => ({channel: response})),
        catchError((error: HttpError) => throwError('Cannot get User channel'))
      );
  }

  public putGetChannelsByIds(ids: string[]): Observable<{channels: ManagingChannel[]}> {
    const url = this.endpoints.ENDPOINT.CONTENT.CHANNEL.GET_BY_IDS.PUT;

    return this.http
      .put(url, ids)
      .pipe(
        map((response: ManagingChannel[]) => ({channels: response})),
        catchError((error: HttpError) => throwError('Cannot get User channel'))
      );
  }

  prepareSortParam(sort: { [key: string]: SortEnum }) {
    // tslint:disable-next-line
    for ( let key of Object.keys(sort)) {
      if (sort[key] !== SortEnum.NONE) {
        return `${key} ${sort[key]}`.toLowerCase();
      }
    }
    return 'none';

  }

  constructor(private http: ApiService, private endpoints: EndpointsService) { }
}
