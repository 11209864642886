import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import {
	DictionaryTypeEnum,
	FilterDefinition,
	FilterTypeEnum,
	SelectFilterDefinition,
	SuggestionTypeEnum,
} from '../filters-model';

import * as fromSuggestions from '@app/root-store/suggestions';

import { FiltersUtilsService } from '../filters.utils';
import { AdvancedFiltersComponent } from '../advanced-filters/advanced-filters.component';

@Component({
	selector: 'rh-table-filters',
	templateUrl: './table-filters.component.html',
	styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit, OnDestroy {
	@ViewChild(AdvancedFiltersComponent, { static: false }) advancedFilters: AdvancedFiltersComponent;

	subs: SubSink = new SubSink();
	FilterTypeEnum = FilterTypeEnum;
	DictionaryTypeEnum = DictionaryTypeEnum;
	SuggestionTypeEnum = SuggestionTypeEnum;

	dictionaries: { [key: string]: { dict: Observable<any>; model: any } } = {};
	suggestions: { [key: string]: Observable<any> } = {};

	@Input() defaultFilters: { [prop: string]: FilterDefinition | SelectFilterDefinition };
	@Input() advancedFiltersTitle = 'Advanced filters';

	filters: FormGroup = new FormGroup({});
	// advancedFilters: {[key: string]: any};

	_tableFilters: { [prop: string]: FilterDefinition | SelectFilterDefinition };

	@Input() set tableFilters(filters: { [key: string]: any }) {
		this._tableFilters = filters;
		this.dictionaries = this.filtersUtils.initializeDictionaries(filters);
		this.suggestions = this.filtersUtils.initializeSuggestions(filters);
		this.filtersUtils.formifyFilters(this.filters, filters);
	}

	get tableFilters() {
		return this._tableFilters;
	}

	get disableClear() {
		const valTF = Object.keys(this.tableFilters)
			.filter((k) => k !== 'advancedFilters')
			.map((key) => ({ [key]: this.tableFilters[key].value }));
		const valDF = Object.keys(this.defaultFilters)
			.filter((k) => k !== 'advancedFilters')
			.map((key) => ({ [key]: this.defaultFilters[key].value }));
		return (
			JSON.stringify(valTF) === JSON.stringify(valDF) &&
			(this.advancedFilters?.activeFiltersKeys.length === 0 || !this.advancedFilters)
		);
	}

	get showLabel() {
		return Object.keys(this.tableFilters).some((key) => key !== 'search' && this.tableFilters[key].changable);
	}

	@Output() filtersChanged: EventEmitter<{ [prop: string]: FilterDefinition }> = new EventEmitter();

	constructor(
		private store$: Store<any>,
		private filtersUtils: FiltersUtilsService
	) {}

	getAutocompletes(type, prop, event) {
		this.store$.dispatch(fromSuggestions.loadSuggestion({ suggestionType: type, prop, phrase: event }));
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit(): void {
		this.subs.sink = this.filters.valueChanges
			.pipe(
				debounceTime(300),
				distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next))
			)
			.subscribe((filters) => {
				this.filtersChanged.emit({
					...filters,
				});
			});
	}

	advancedFiltersChanged(filters: { [prop: string]: FilterDefinition }) {
		this.filters.patchValue({
			...this.filters.value,
			advancedFilters: {
				filters: {
					...filters,
				},
			},
		});
	}

	clearSearch() {
		(this.filters.get('search') as FormGroup).controls.value.setValue('');
	}

	clearAll() {
		const filters = {
			...this.defaultFilters,
		};
		delete filters.advancedFitlers;
		this.filters.patchValue(filters);
		this.advancedFilters.clearAll();
	}

	showFiltersInOrder() {
		return 0;
	}

	clearRange(formGroup, picker) {
		formGroup.setValue({ start: null, end: null });
		picker.close();
	}

	trackByFn(index, item): void {
		return item.key;
	}
}
