import {Component, EventEmitter, Host, Input, OnInit, Optional, Output, SkipSelf, ViewChild} from '@angular/core';
import {ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomValidators} from '@app/shared/form-controls/validators/validator.function';
import {RdsMenuTriggerDirective} from '@rds/angular-components';
import {debounceTime, distinctUntilChanged} from 'rxjs';

@Component({
    selector: 'rh-rada-picker',
    templateUrl: './rada-picker.component.html',
    styleUrls: ['./rada-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RadaPickerComponent
        }
    ]
})
export class RadaPickerComponent implements OnInit {
    @ViewChild("trigger", {static: false}) trigger: RdsMenuTriggerDirective;

    selectedRadaGroups: Array<any> = [];

    onChange = (rada) => {
    };

    onTouched = () => {
    };

    touched = false;

    disabled = false;

    hostWidth: number;

    writeValue(suggestions: Array<string>) {
        this.selectedRadaGroups = suggestions;

    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        disabled ? this.form.disable() : this.form.enable();
        this.disabled = disabled;
    }

    @Output() search: EventEmitter<string> = new EventEmitter<string>();
    @Output() selected: EventEmitter<string> = new EventEmitter<string>();
    @Output() removed: EventEmitter<string> = new EventEmitter<string>();
    @Output() details: EventEmitter<any> = new EventEmitter<any>();
    _autocomplete: {
        suggestions: Array<string>,
        loading: boolean
    };
    get autocomplete(): {
        suggestions: Array<string>,
        loading: boolean
    } {
        return this._autocomplete;
    }

    @Input() set autocomplete(value: {
        suggestions: Array<string>,
        loading: boolean
    }) {
        this._autocomplete = {
            suggestions: this.form.controls.search.value.length > 0 ? value.suggestions : [],
            loading: value.loading
        };
        if (!!this.trigger) {
            this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading ? this.trigger.openMenu() : this.trigger.closeMenu();
        }
    }

    @Input() formControl!: FormControl;
    @Input() formControlName!: string;
    @Input() required = false;
    @Input() label: string = 'Select RADA group';
    @Input() chipPlaceholder: string = 'RADA group';

    get control() {
        return this.formControl || this.controlContainer.control?.get(this.formControlName);
    }


    form: FormGroup = new FormGroup({
        search: new FormControl('')
    });

    @Input() labelFn: (args: any) => string;
    @Input() allowRemoveSelf: boolean = false;
    @Input() multi: boolean = false;

    selectedIncludesRada(rada: {
        email: string,
        name: string,
        description: string
    }) {
        return this.selectedRadaGroups.findIndex(a => a.email === rada.email) > -1;
    }

    focus() {
        if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
            this.trigger.openMenu()
        }
    }

    blur() {
        this.markAsTouched();
    }

    select(rada: {
        email: string,
        name: string,
        description: string
    }) {
        if (!this.selectedIncludesRada(rada)) {
            let tmp = {...rada, type: 1};
            this.multi ? this.selectedRadaGroups = [...this.selectedRadaGroups, tmp] : this.selectedRadaGroups = [tmp];

            this.form.controls.search.setValue('');
            this.selected.emit();
            this.onChange(this.selectedRadaGroups.slice())
        }
    }

    remove(index: number) {
        this.selectedRadaGroups = this.selectedRadaGroups.filter((g, i) => i !== index);

        this.form.controls.search.setValue('');
        this.removed.emit();
        this.onChange(this.selectedRadaGroups)
    }

    ngOnInit(): void {
        this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
        this.form.controls.search.updateValueAndValidity();

        const originalMarkAsTouched = this.control.markAsTouched;
        const that = this;
        this.control.markAsTouched = function () {
            originalMarkAsTouched.apply(this, arguments);
            that.form.controls.search.markAsTouched();
            that.form.controls.search.updateValueAndValidity();
        }

        this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
            this.form.controls.search.updateValueAndValidity();
        });

        this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe(s => this.search.emit(s));
    }

    constructor(
        @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
    ) {
    }

}
