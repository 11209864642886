import { AfterViewInit, Component, Inject, OnDestroy } from '@angular/core';
import { NewsletterService } from '@app/core/services/newsletter.service';

import { NewsletterValueModel } from '@app/newsletter/models';
import { RDS_DIALOG_DATA, RdsDialogRef } from '@rds/angular-components';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Section } from '@app/newsletter-new/models/section-type.enum';
import { RhNewsletterForm } from '@app/newsletter-new/models/newsletter';
import { BackButton } from '@app/root-store/ui/back-button';
import { NewsPreviewButtons } from '@shared/dialogs/previews/news-preview-dialog/news-preview-dialog.component';

export interface TemplatePreviewDialogData {
	sections: Observable<Array<Section>>;
	form: Observable<Partial<RhNewsletterForm>>;
	buttons: NewsPreviewButtons;
	backButton?: BackButton;
}

@Component({
	selector: 'rh-template-preview-dialog',
	templateUrl: './template-preview-dialog.component.html',
	styleUrls: ['./template-preview-dialog.component.scss'],
})
export class TemplatePreviewDialogComponent implements AfterViewInit, OnDestroy {
	private subs: SubSink = new SubSink();
	templateId: string;
	newsletterId: number;
	formNewsletter: Observable<NewsletterValueModel>;

	constructor(
		@Inject(RDS_DIALOG_DATA) public data: TemplatePreviewDialogData,
		public rdsDialogRef: RdsDialogRef<TemplatePreviewDialogComponent>,
		private newsletterService: NewsletterService
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngAfterViewInit(): void {}
}
