import { FilterDefinition, FilterTypeEnum, SEARCH_FILTER, SelectFilterDefinition } from '../filters-model';

export interface NewsletterLinkStatisticsTableFilters {
	types?: SelectFilterDefinition;
	search?: FilterDefinition;
}

export enum PeriodEnum {
	YEAR = 0,
	MONTH = 1,
	DAY = 2,
	HOUR = 3,
}

export const NEWSLETTER_LINK_TYPES = [
	{ label: 'Button link', value: 'button' },
	{ label: 'Channel', value: 'channel' },
	{ label: 'Event', value: 'event' },
	{ label: 'Image', value: 'image' },
	{ label: 'Link', value: 'link' },
	{ label: 'News', value: 'news' },
];

export const INITIAL_NEWSLETTER_LINK_STATISTICS_FILTERS = {
	type: {
		type: FilterTypeEnum.SELECT,
		changable: true,
		value: [],
		label: 'Type',
		multiple: true,
		options: NEWSLETTER_LINK_TYPES,
	},
	...SEARCH_FILTER,
};
