import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsDividerModule,
  RdsDropdownModule,
  RdsHeaderModule,
  RdsIconsModule,
  RdsMenuModule,
  RdsProgressBarModule,
  RdsTreeModule,
  RdsTabModule,
  RdsChipsModule,
  RdsLinkModule,
  RdsRadioButtonModule,
  RdsFormFieldModule,
  RdsDialogModule,
  RdsFooterModule,
  RdsBreadcrumbsModule,
  RdsPaginatorModule,
  RdsBadgeModule,
  RdsAvatarModule,
  RdsTableModule,
  RdsEmptyStateModule,
  RdsTooltipModule,
  RdsCheckboxModule,
  RdsSearchModule,
  RdsToggleButtonModule,
  RdsSwitchModule,
  RdsStepperModule,
  RdsAccordionModule,
  RdsUploadModule,
  RdsDatepickerModule,
  RdsNativeDateModule,
  RdsCarouselModule,
  RdsAlertModule,
  RdsProgressSpinnerModule,
  RdsDrawerModule,
  RdsPopoverModule,
  RdsCardModule,
  RdsSliderModule,
  RDS_ICON_LOADER_CONFIG,
  RdsIconLoaderConfig,
} from '@rds/angular-components';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RdsNativeDateModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RdsDialogModule,
    RdsButtonModule,
    RdsDividerModule,
    RdsDropdownModule,
    RdsHeaderModule,
    RdsIconsModule,
    RdsMenuModule,
    RdsProgressBarModule,
    RdsTreeModule,
    RdsTabModule,
    RdsChipsModule,
    RdsLinkModule,
    RdsRadioButtonModule,
    RdsFormFieldModule,
    RdsDialogModule,
    RdsFooterModule,
    RdsBreadcrumbsModule,
    RdsPaginatorModule,
    RdsBadgeModule,
    RdsAvatarModule,
    RdsTableModule,
    RdsEmptyStateModule,
    RdsTooltipModule,
    RdsCheckboxModule,
    RdsSearchModule,
    RdsToggleButtonModule,
    RdsSwitchModule,
    RdsStepperModule,
    RdsAccordionModule,
    RdsUploadModule,
    RdsDatepickerModule,
    RdsCarouselModule,
    RdsAlertModule,
    RdsProgressSpinnerModule,
    RdsDrawerModule,
    RdsPopoverModule,
    RdsCardModule,
    RdsSliderModule,
  ],
  providers: [
    {
      provide: RDS_ICON_LOADER_CONFIG,
      useValue: {
        preloadSelected: {
          filled: [
            'more_vertical',
            'checkmark_circle',
            'warning_circle'
          ],
          outlined: [
            'minus',
            'image',
            'fullscreen',
            'link',
            'note',
            'calendar',
            'wifi_on',
            'plus'
          ],
        },
      } as RdsIconLoaderConfig,
    },
  ]
})
export class RdsModule {}
