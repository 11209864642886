import { PeopleRolePickerModel } from '@app/shared/custom-controls/people-role-picker/people-role-picker.component';
import { NewsContent, NewsDetails } from './news';
import { SimpleUser } from './user';

export type TemplatesListType = 'predefined' | 'mytemplates' | 'shared' | 'pending' | 'readonly';
export type TemplateRoles = 'Owner' | 'Editor' | 'Viewer';

export enum TemplatesRefresh {
	NONE = 0,
	REFRESH = 1,
	REFRESH_PLUS = 2,
	REFRESH_MINUS = 3,
}

export interface SimpleTemplate {
	id?: number;
	title: string;
	language: string;
	imagePreview: string;
	imagePreviewUrl?: string;
	isPredefined: boolean;
	newsletterTemplateUserRole?: any[];
}

export interface TemplatePermissions {
	owners: Array<SimpleUser>;
	editors: Array<SimpleUser>;
	viewers: Array<SimpleUser>;
	isOwner?: boolean;
	isEditor?: boolean;
	isViewer?: boolean;
}

export interface TemplateForm extends SimpleTemplate {
	content: Array<NewsContent>;
	permissions: TemplatePermissions;
}

export interface TemplateUpdate extends SimpleTemplate, TemplatePermissions {
	contentCreate: Array<NewsContent>;
	contentUpdate: Array<NewsContent>;
	contentDelete: Array<string>;
	updateMetaData?: boolean;
}

export interface Template extends SimpleTemplate, TemplatePermissions {
	content: Array<NewsContent>;
	createdDate: Date;
	modifiedDate: Date;
}

export const TEMPLATE_ROLES: PeopleRolePickerModel = {
	owners: {
		label: 'Owner',
		description: 'Can edit, manage permission and delete the template',
		canEdit: false,
	},
	editors: {
		label: 'Editor',
		description: 'Can edit the template',
		canEdit: false,
	},
	viewers: {
		label: 'Viewer',
		description: 'Can use the template',
		canEdit: false,
	},
};

export const EMPTY_TEMPLATE_FORM: TemplateForm = {
	id: null,
	title: '',
	language: 'en',
	content: [
		{
			language: 'en',
			abstract: '',
			title: '',
			html: '',
			image: null,
			link: '',
		},
	],
	permissions: {
		owners: [],
		editors: [],
		viewers: [],
	},
	isPredefined: false,
	imagePreview: null,
};

export function mapTemplateToNews(template: Template): NewsDetails {
	return {
		assignedChannels: [],
		byline: null,
		contact: null,
		content: template.content,
		contentType: 'Embedded',
		editors: template.editors,
		id: template.id,
		isEditor: false,
		isOwner: false,
		language: template.language,
		owners: template.owners,
		relatedNews: [],
		status: 'Published',
		suggestedChannels: [],
		authors: [],
		bylineEmail: null,
		commentsEnabled: true,
		topics: [],
		publishDate: template.createdDate,
	};
}

export function mapNewsToTemplate(news: NewsDetails): Template {
	return {
		content: news.content,
		editors: news.editors,
		viewers: [],
		createdDate: new Date(),
		modifiedDate: new Date(),
		isPredefined: false,
		title: news.content[0].title,
		id: news.id,
		language: news.language,
		owners: news.owners,
		imagePreview: null,
	};
}
