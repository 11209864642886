import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromReducer from '@app/newsletter-new/store/newsletter-form/newsletter-form.reducer';
import { DEFAULT_DISCLAIMER, RhNewsletterRequest } from '@app/newsletter-new/models/newsletter';

export const selectNewsletterForm = createSelector(fromNewsletter.selectState, fromNewsletter.getNewsletterForm);

export const selectAgreementsAccepted = createSelector(selectNewsletterForm, fromReducer.getAgreementsAccepted);

export const selectRemappingStatus = createSelector(selectNewsletterForm, fromReducer.getRemappingStatus);

export const selectNewsletter = createSelector(selectNewsletterForm, fromReducer.getNewsletter);
export const selectInitialNewsletter = createSelector(selectNewsletterForm, fromReducer.getInitialNewsletter);

export const selectNewsletterMetadata = createSelector(selectNewsletter, fromReducer.getNewsletterMetadata);

export const selectFormChecked = createSelector(selectNewsletterForm, fromReducer.getFormChecked);

export const selectHeaderFooterStepStatus = createSelector(selectNewsletterForm, fromReducer.getHeaderFooterStepStatus);

export const selectHeaderFooterFormStatus = createSelector(selectNewsletterForm, fromReducer.getHeaderFooterFormStatus);

export const selectBodyStepStatus = createSelector(selectNewsletterForm, fromReducer.getBodyStepStatus);

export const selectBodyFormStatus = createSelector(selectNewsletterForm, fromReducer.getBodyFormStatus);

export const selectRecipientsStepStatus = createSelector(selectNewsletterForm, fromReducer.getRecipientsStepStatus);

export const selectRecipientsFormStatus = createSelector(selectNewsletterForm, fromReducer.getRecipientsFormStatus);

export const selectBlockStepStatus = createSelector(selectNewsletterForm, fromReducer.getBlockStepStatus);

export const selectBlockFormStatus = createSelector(selectNewsletterForm, fromReducer.getBlockFormStatus);

export const selectPublishingStepStatus = createSelector(selectNewsletterForm, fromReducer.getPublishingStepStatus);

export const selectPublishingFormStatus = createSelector(selectNewsletterForm, fromReducer.getPublishingFormStatus);

export const selectGeneralInformationForm = createSelector(selectNewsletter, (form) =>
	form
		? {
				feedbackSettings: form.feedbackSettings,
				hasFeedback: form.hasFeedback,
				hasSenderDisclaimer: form.hasSenderDisclaimer,
				disclaimer: form.disclaimer,
				customDisclaimerSwitch: form.disclaimer !== DEFAULT_DISCLAIMER,
				title: form.title,
			}
		: null
);
export const selectFeedbackSettings = createSelector(selectGeneralInformationForm, (form) =>
	form
		? {
				...form.feedbackSettings,
			}
		: null
);
export const selectHeaderFooterForm = createSelector(
	selectNewsletter,
	selectFeedbackSettings,
	(form, feedbackSettings) =>
		form
			? {
					hasBanner: form.hasBanner,
					hasImage: form.hasImage,
					image: form.image,
					banner: form.banner,
					campaignId: form.campaignId,
					feedbackSettings,
					hasFeedback: form.hasFeedback,
					hasSenderDisclaimer: form.hasSenderDisclaimer,
					disclaimer: form.disclaimer,
					customDisclaimerSwitch: form.disclaimer !== DEFAULT_DISCLAIMER,
					title: form.title,
					id: form.id,
				}
			: null
);

export const selectBodyForm = createSelector(selectNewsletter, (form) => (form ? {} : null));

export const selectContentForm = createSelector(selectNewsletter, (form) =>
	form
		? {
				hasBanner: form.hasBanner,
				hasImage: form.hasImage,
				image: form.image,
				banner: form.banner,
			}
		: null
);
export const selectRecipientsListForm = createSelector(selectNewsletter, (form) =>
	form
		? {
				recipientRada: form.recipientRada,
				recipientUsers: form.recipientUsers,
			}
		: null
);
export const selectBlockUsersForm = createSelector(selectNewsletter, (form) =>
	form
		? {
				blockRada: form.blockRada,
				blockUsers: form.blockUsers,
			}
		: null
);

export const selectPublishingForm = createSelector(selectNewsletter, (form) =>
	form
		? {
				subject: form.subject,
				previewText: form.previewText,
				whenToSend: form.whenToSend,
				fromEmail: form.fromEmail,
				fromName: form.fromName,
				replyToName: form.replyToName,
				replyToEmail: form.replyToEmail,

				scheduleDate: form.scheduleDate,
				scheduleTime: form.scheduleTime,
				scheduleSentDate: form.scheduleSentDate,
				timeZone: form.timeZone,
			}
		: null
);

export const selectChangesMade = createSelector(
	selectNewsletter,
	selectInitialNewsletter,
	(form, initialForm) => JSON.stringify(form) !== JSON.stringify(initialForm)
);

// export const selectCanSave = createSelector(
// 	selectGeneralInformationFormStatus,
// 	selectContentFormStatus,
// 	selectRecipientsFormStatus,
// 	selectBlockFormStatus,
// 	selectPublishingFormStatus,
// 	(general, content, recipients, blocks, publishing) =>
// 		general === 'VALID' && content === 'VALID' && recipients === 'VALID' && blocks === 'VALID' && publishing === 'VALID'
// );

export const selectCanSave = createSelector(
	selectHeaderFooterFormStatus,
	selectBodyFormStatus,
	selectRecipientsFormStatus,
	selectBlockFormStatus,
	selectPublishingFormStatus,
	(headerFooter, body, recipients, blocks, publishing) =>
		headerFooter === 'VALID' &&
		body === 'VALID' &&
		recipients === 'VALID' &&
		blocks === 'VALID' &&
		publishing === 'VALID'
);
export const selectCanSaveDraft = createSelector(selectNewsletter, ({ title }) => !!title);

export const selectCurrentStep = createSelector(selectNewsletterForm, fromReducer.getCurrentStep);
export const selectCampaignName = createSelector(selectNewsletterForm, fromReducer.getCampaignName);
export const selectIsCampaign = createSelector(selectCampaignName, (name: string) => (name ? true : false));

export const selectFormForRequest = createSelector(
	selectNewsletter,
	selectFeedbackSettings,
	(form, feedbackSettings) => {
		const data: RhNewsletterRequest = !!form
			? {
					newsletterId: form.newsletterId,
					templateId: form.templateId,
					title: form.title,
					disclaimer: form.disclaimer,
					hasSenderDisclaimer: form.hasSenderDisclaimer,
					hasBanner: form.hasBanner,
					hasImage: form.hasImage,
					titleIncluded: true,
					hasFeedback: form.hasFeedback,
					bannerPhotoUrl: form.hasBanner ? form?.banner?.url : form.hasImage ? form?.image?.url : null,
					bannerPhotoName: form.hasBanner ? form?.banner?.name : form.hasImage ? form?.image?.name : null,
					bannerPhotoSize: form.hasBanner ? form?.banner?.size : form.hasImage ? form?.image?.size : null,
					fromEmail: form.fromEmail,
					fromName: form.fromName,
					content: form.content,
					subject: form.subject,
					previewText: form.previewText,
					replyToEmail: form.replyToEmail,
					replyToName: form.replyToName,
					googleAnalyticsUrl: null,
					timeZone: form.whenToSend === 1 ? form.timeZone : null,
					scheduleSentDate: form.whenToSend === 1 ? form.scheduleSentDate : null,
					imagePreviewUrl: null,
					campaignId: form.campaignId,
					receivers: form.campaignId
						? []
						: [
								...[...form.recipientRada, ...form.blockRada].reduce((result, item) => {
									if (result.findIndex((r) => r.email === item.email) === -1) {
										result.push({
											email: item.email,
											name: item.name,
											surname: item.surname,
											type: 1,
											isReciever: form.recipientRada.findIndex((r) => r.email === item.email) > -1,
											isBlocked: form.blockRada.findIndex((r) => r.email === item.email) > -1,
										});
									}
									return result;
								}, []),
								...[...form.recipientUsers, ...form.blockUsers].reduce((result, item) => {
									if (result.findIndex((r) => r.email === item.email) === -1) {
										result.push({
											email: item.email,
											name: item.name,
											surname: item.surname,
											type: 0,
											isReciever: form.recipientUsers.findIndex((r) => r.email === item.email) > -1,
											isBlocked: form.blockUsers.findIndex((r) => r.email === item.email) > -1,
										});
									}
									return result;
								}, []),
							],
					feedback: form.hasFeedback
						? {
								feedbackType: feedbackSettings?.feedbackType,
								contact: feedbackSettings?.feedbackType === 0 ? form?.feedbackSettings?.contact : null,
								title: feedbackSettings?.title,
								question: feedbackSettings?.question,
								answers: feedbackSettings?.answers,
							}
						: null,
				}
			: null;

		return data;
	}
);
