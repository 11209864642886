import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit} from '@angular/core';
import * as fromUserPhoto from '@core/core-store/user-photo';
import {select, Store} from '@ngrx/store';
import {SubSink} from 'subsink';

@Directive({
  selector: '[rhFetchAvatar]',
  exportAs: 'rhFetchAvatar'
})
export class FetchAvatarDirective implements OnInit, OnDestroy {

  @Input('rhFetchAvatar') login: string;
  subs: SubSink = new SubSink();
  src = null;
  notFound = false

  ngOnInit() {
    this.store$.dispatch(fromUserPhoto.getUserPhoto({login: this.login}));
    this.subs.sink = this.store$.pipe(
      select(fromUserPhoto.selectEntityById, {id: this.login}),
    ).subscribe(userPhoto => {
      if (!!userPhoto && userPhoto.url) {
        this.src = userPhoto.url;
        this.cdr.markForCheck()
      }
      this.notFound = userPhoto?.userNotFound;
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  constructor(
    private cdr: ChangeDetectorRef, private store$: Store<fromUserPhoto.State>) {
  }

}
