import { ListType } from '@app/newsletter-new/store/recipients-table';
import { NewsImage } from '@app/core/models/newsboard';

export const DEFAULT_DISCLAIMER =
	'The information transmitted in this message is intended only for the person or entity to which it is addressed and may contain confidential and/or privileged material. Any review, re-transmission dissemination or other use of, or taking of any action in reliance upon, this information by persons or entities other than the intended recipient is prohibited. If you receive this message in error, please contact the sender and delete the material from any computer.';

export interface RhNewsletterRemappingResults {
	image?: {
		imageNotExist: boolean;
		isRatioMatching: boolean;
		correctRatio: number;
		currentRatio: number;
	};
	radaGroups?: {
		missing: string[];
	};
	news?: {
		missing: any[];
	};
}

export interface RhFeedbackSettings {
	feedbackType: number;
	contact: string;
	title: string;
	question: string;
	answers: string[];
}

export interface RhNewsletterGeneral {
	campaignId: number;
	campaignName?: string;
	hasFeedback: boolean;
	feedbackSettings: RhFeedbackSettings;
	hasSenderDisclaimer: boolean;
	disclaimer: string;
	title: string;
	newsletterId?: number;
}

export interface RhNewsletterContent {
	hasImage: boolean;
	hasBanner: boolean;
	image: NewsImage;
	banner: NewsImage;
	sections: any[];
	content: string;
}

export interface RhNewsletterRecipients {
	recipientRada: Array<any>;
	recipientUsers: Array<any>;
}

export interface RhNewsletterBlock {
	blockRada: Array<any>;
	blockUsers: Array<any>;
}

export interface RhNewsletterPublishing {
	subject: string;
	previewText: string;
	whenToSend: number;
	fromEmail: string;
	fromName: string;
	replyToName: string;
	replyToEmail: string;

	scheduleDate: string;
	scheduleTime: string;
	scheduleSentDate: string;
	timeZone: Array<string>;
	newsletterReceivers?: Array<{
		email: string;
		name: string;
		surname: string;
		isBlocked: boolean;
		isReciever: boolean;
		type: number;
	}>;
	// @TODO remove after
	from?: string;
	color?: string;
	colorLight?: string;
	colorDark?: string;
	templateId?: string;
	disclaimerIncluded?: boolean;
	languageId?: number;
	rocheLogoInHeader?: boolean;
	feedbackIncluded?: boolean;
	step?: number;
	to?: string;
	toGroups?: string;
	senderName?: string;
}

export interface RhNewsletterByIdResponse {
	newsletter: RhNewsletterForm;
	newsletterLegacy: RhNewsletterForm;
	templateId: string;
	newsletterId: number;
}

export interface RhNewsletterRequest {
	newsletterId: number;
	templateId: string;
	title: string;
	disclaimer: string;
	hasSenderDisclaimer: boolean;
	hasBanner: boolean;
	hasImage: boolean;
	titleIncluded: boolean;
	hasFeedback: boolean;
	bannerPhotoUrl: string;
	bannerPhotoName: string;
	bannerPhotoSize: number;
	fromEmail: string;
	fromName: string;
	content: string;
	subject: string;
	previewText: string;
	replyToEmail: string;
	replyToName: string;
	googleAnalyticsUrl: string;
	timeZone: string[];
	scheduleSentDate: string;
	imagePreviewUrl: string;
	campaignId: number;
	receivers: any[];
	feedback: {
		feedbackType: number;
		contact: string;
		title: string;
		question: string;
		answers: string[];
	};
}

export interface RhNewsletterForm
	extends RhNewsletterGeneral,
		RhNewsletterContent,
		RhNewsletterRecipients,
		RhNewsletterBlock,
		RhNewsletterPublishing {
	id: number;
	status: ListType;
	newsletterId?: number;
	templateId: string;
	isContributed: boolean;
	// missing fields
	googleAnalitycsUrl?: string;
	imagePreviewUrl?: string;
}

export const EMPTY_NEWSLETTER_FORM: RhNewsletterForm = {
	id: null,
	recipientRada: [],
	recipientUsers: [],
	blockRada: [],
	blockUsers: [],
	status: 0,
	templateId: 'NewsletterTemplateNew',
	isContributed: false,
	newsletterId: null,
	campaignId: null,
	campaignName: null,
	hasFeedback: false,
	feedbackSettings: {
		feedbackType: null,
		contact: null,
		title: null,
		question: null,
		answers: [],
	},
	hasSenderDisclaimer: false,
	disclaimer: DEFAULT_DISCLAIMER,
	subject: null,
	previewText: null,
	whenToSend: 0,
	scheduleDate: null,
	scheduleTime: null,
	scheduleSentDate: null,
	timeZone: [Intl.DateTimeFormat().resolvedOptions().timeZone],
	fromEmail: 'rw2.rhtest1@roche.com',
	fromName: null,
	replyToName: null,
	replyToEmail: null,
	title: null,
	hasImage: true,
	hasBanner: false,
	image: null,
	banner: null,
	sections: [],
	content: null,
};
export const DUMMY_NEWSLETTER_FORM: RhNewsletterForm = {
	id: null,
	recipientRada: [],
	recipientUsers: [],
	blockRada: [],
	blockUsers: [],
	status: 0,
	templateId: 'NewsletterTemplateNew',
	isContributed: false,
	newsletterId: null,
	campaignId: null,
	campaignName: null,
	hasFeedback: false,
	feedbackSettings: {
		feedbackType: null,
		contact: null,
		title: null,
		question: null,
		answers: [],
	},
	hasSenderDisclaimer: true,
	disclaimer: DEFAULT_DISCLAIMER,
	subject: 'Test',
	previewText: 'Test1',
	whenToSend: null,
	fromEmail: 'Test@com.pl',
	fromName: 'Test2',
	replyToName: 'Test4',
	replyToEmail: 'Test@com.pl',
	title: 'Lorem ipsum Lorem ipsum lalalala',
	hasImage: true,
	hasBanner: false,
	image: null,
	banner: null,
	scheduleTime: null,
	scheduleDate: null,
	scheduleSentDate: null,
	timeZone: [Intl.DateTimeFormat().resolvedOptions().timeZone],
	// image: {
	// 	id: 'image',
	// 	url: 'https://dk51z269pgo8d.cloudfront.net/2808aa25-ba5b-4768-9c3a-50004d47e89b.png',
	// 	name: 'test-image.png',
	// 	size: 123456789
	// },
	// banner: {
	// 	id: 'banner',
	// 	url: 'https://dk51z269pgo8d.cloudfront.net/4930712f-d677-42bf-9d5b-b7d84655cab1.png',
	// 	name: 'test-banner.png',
	// 	size: 987654321
	// },
	sections: [],
	content: null,
};
export const DEFAULT_FEEDBACK_SETTINGS = {
	feedbackType: 0,
	contact: null,
	title: 'Send us your feedback',
	question: 'What would you like to read about? Do you have any ideas? We would love to hear from you.',
	answers: [],
};

export enum NewsletterSteps {
	HEADER_FOOTER = 'HEADER_FOOTER',
	BODY = 'BODY',
	RECIPIENTS_LIST = 'RECIPIENTS_LIST',
	BLOCK_USERS = 'BLOCK_USERS',
	PUBLISHING_INFO = 'PUBLISHING_INFO',
}

export enum NewsletterStepsToStatus {
	HEADER_FOOTER = 'headerFooterStatus',
	BODY = 'bodyStatus',
	RECIPIENTS_LIST = 'recipientsStatus',
	BLOCK_USERS = 'blockStatus',
	PUBLISHING_INFO = 'publishingStatus',
}
