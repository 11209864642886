import { DatePipe } from '@angular/common';
import {
	EMPTY_OVERALL_STATS,
	FeedbackStatisticsModel,
	PeriodStatisticsModel,
	DeliveryStatisticsModel,
} from '@app/core/models/newsletter.model';
import { PeriodEnum } from '@app/shared/filters/models/newsletter-link-statistics';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './statistics-all-campaign.actions';
import { periodFromDate, subperiodsFromPeriod } from '@app/newsletter-new/utils/helpers';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export const CHART_COLOR_PALETE_FACTORY = (count: number) => {
	switch (count) {
		case 1:
			return ['#7C0095'];
		case 2:
			return ['#7C0095', '#C684BD'];
		case 3:
			return ['#7C0095', '#AD42C3', '#C684BD'];
		case 4:
			return ['#7C0095', '#AD42C3', '#C684BD', '#DEAABF'];
		case 5:
			return ['#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 6:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF'];
		case 7:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7'];
		case 8:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198'];
		case 9:
			return ['#41004E', '#7C0095', '#AD42C3', '#AD63BF', '#C684BD', '#DEAABF', '#FAD6C7', '#E7B198', '#A26D51'];
		case 10:
			return [
				'#41004E',
				'#7C0095',
				'#AD42C3',
				'#AD63BF',
				'#C684BD',
				'#DEAABF',
				'#FAD6C7',
				'#E7B198',
				'#A26D51',
				'#C28D70',
			];
		default:
			return [];
	}
};

const newslettersAdapter = createEntityAdapter<any>({
	selectId: (n) => n.newsletterId,
});

export interface State {
	title: string;
	createdAt: Date;
	id: number;
	newsletters: EntityState<any>;
	statistics: DeliveryStatisticsModel;
	periodStatistics: Array<PeriodStatisticsModel>;
	feedback: FeedbackStatisticsModel;
	period: {
		period: PeriodEnum;
		date: Date;
	};
	availableNewsletters: Array<any>;
	newsletterDeliveryStatistics: Array<any>;
	newsletterDelivery: {
		chartType: 'normalized' | 'stacked' | string;
		selectedNewsletters: Array<number>;
	}
}

export const initialState: State = {
	title: null,
	createdAt: null,
	id: null,
	newsletters: newslettersAdapter.getInitialState(),
	statistics: EMPTY_OVERALL_STATS,
	periodStatistics: [],
	feedback: null,
	period: null,
	availableNewsletters: [],
	newsletterDeliveryStatistics: [],
	newsletterDelivery: null
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.statistics.init, (state, { id }) => ({
		...state,
		id,
	})),
	on(fromActions.statistics.saveCampaignInfo, (state, { createdAt, newsletters, title }) => ({
		...state,
		createdAt,
		title,
		newsletters: newslettersAdapter.addMany(newsletters, state.newsletters),
	})),
	on(fromActions.statistics.initPeriod, (state, { period, date }) => ({
		...state,
		period: {
			period,
			date,
		},
	})),
	on(fromActions.statistics.updatePeriod, (state, { period, date }) => ({
		...state,
		period: {
			period,
			date,
		},
	})),
	on(fromActions.statistics.successPeriod, (state, { statistics }) => ({
		...state,
		periodStatistics: statistics,
	})),
	on(fromActions.statistics.updateNewsletter, (state, { chartType, selectedNewsletters }) => ({
		...state,
		newsletterDelivery: {
			chartType: chartType ? chartType : state.newsletterDelivery.chartType,
			selectedNewsletters: selectedNewsletters ? selectedNewsletters : state.newsletterDelivery.selectedNewsletters
		}
	})),
	on(fromActions.statistics.successNewsletter, (state, { newsletterDeliveryStatistics, availableNewsletters }) => ({
		...state,
		newsletterDeliveryStatistics,
		newsletterDelivery: {
			chartType: state.newsletterDelivery?.chartType || 'normalized',
			selectedNewsletters: availableNewsletters.map(n => n.newsletterId)
		},
		availableNewsletters,
	})),
	on(fromActions.statistics.successOverall, (state, { statistics }) => ({
		...state,
		statistics,
	})),
	on(fromActions.statistics.clear, (state) => ({
		...initialState,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

export const getStatistics = (state: State) => state.statistics;

export const getRecipientsChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Newsletter recipients',
		value: statistics?.totalReceivers + '',
	},
];

export const getDeliverabilityChartData = (statistics: DeliveryStatisticsModel) => {
	return {
		customColors: [
			{
				name: 'Delivered & opened',
				value: '#00A17C',
			},
			{
				name: 'Delivered not opened',
				value: '#E9B519',
			},
			{
				name: 'Newsletter bounced',
				value: '#C40000',
			},
		],
		chartData: [
			{
				name: 'Delivered & opened',
				value: statistics?.uniqueOpened + '',
			},
			{
				name: 'Delivered not opened',
				value: statistics?.delivered - statistics?.uniqueOpened + '',
			},
			{
				name: 'Newsletter bounced',
				value: statistics?.bounced + '',
			},
		],
	};
};

export const getClicksData = (statistics: DeliveryStatisticsModel) => ({
	unique: statistics?.uniqueClicked,
	total: statistics?.clicked,
});

export const getSuccessfulDeliveryChart = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Successful delivery',
		value: statistics?.delivered + '',
	},
];

export const getUniqueOpensChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Unique newsletter opened',
		value: statistics?.uniqueOpened + '',
	},
];

export const getBouncesChartData = (statistics: DeliveryStatisticsModel) => [
	{
		name: 'Newsletter bounces',
		value: statistics?.bounced + '',
	},
];
export const getCampaignTitle = (state: State) => state.title;
export const getCampaignCreatedAt = (state: State) => state.createdAt;
export const getCampaignId = (state: State) => state.id;
export const getPeriod = (state: State) => state.period;
export const getAvailableNewsletters = (state: State) => state.availableNewsletters;
export const getNewsletterDelivery = (state: State) => state.newsletterDelivery;

export const getPeriodDeliveryChartData = (state: State) => {
  const {periodStatistics, period, newsletters} = state;
  if (!period) return;
  let subperiods = subperiodsFromPeriod(periodFromDate(period.period, period.date));
  const datePipe = new DatePipe('en-US');
  const newsletterDictionary = newslettersAdapter.getSelectors().selectEntities(newsletters);
  const maxBarValue = Math.max(...periodStatistics.map(p => p.newsletters.reduce((acc, n) => acc + n.delivered + n.bounced, 0)),0);
  console.log('maxBarValue: ', maxBarValue)
  return {
    name: periodFromDate(period.period, period.date),
    colorScheme: {
      domain: ['#357AF6', '#C2BAB5']
    },
    chartData: subperiods.map(subperiod => {
      let label = '';
      const data = periodStatistics.find(p => p.period === subperiod);
      console.log(data);
      switch (period.period) {
        case PeriodEnum.YEAR:
          label = datePipe.transform(new Date(subperiod), 'MMM');
          break;
        case PeriodEnum.MONTH:
          label = datePipe.transform(new Date(subperiod), 'dd (E)');
          break;
        case PeriodEnum.DAY:
          label = datePipe.transform(new Date(subperiod), 'HH:00');
          break;
      }
      return {
        name: label,
        series: [
          {
            name: 'Delivered',
            value: data ? data.newsletters.reduce((acc, n) => acc + n.delivered, 0) : 0,
            extra: {
              groupType: period.period,
              period: subperiod,
              newsletters: data ? data.newsletters.filter(n => n.delivered > 0 || n.bounced > 0).map(n => ({
                ...n,
                title: newsletterDictionary[n.newsletterId].title,
                sentDate: newsletterDictionary[n.newsletterId].sentDate,
              })) : []
            }
          },
          {
            name: 'Bounced',
            value: data ? data.newsletters.reduce((acc, n) => acc + n.bounced, 0) : 0,
            extra: {
              groupType: period.period,
              period: subperiod,
              newsletters: data ? data.newsletters.filter(n => n.delivered > 0 || n.bounced > 0).map(n => ({
                ...n,
                title: newsletterDictionary[n.newsletterId].title,
                sentDate: newsletterDictionary[n.newsletterId].sentDate,
              })) : []
            }
          }
        ]
      }
    }),
    yScaleMax: (Math.floor(maxBarValue/10)+1)*10
  }
}


export const getPeriodClicksChartData = (state: State) => {
	const { periodStatistics, period, newsletters } = state;
	if (!period) return;
	let subperiods = subperiodsFromPeriod(periodFromDate(period.period, period.date));
	const datePipe = new DatePipe('en-US');
	const newsletterDictionary = newslettersAdapter.getSelectors().selectEntities(newsletters);
	const maxBarValue = Math.max(
		...periodStatistics.map((p) => p.newsletters.reduce((acc, n) => acc + n.uniqueClicked, 0)),
		0
	);
	return {
		name: periodFromDate(period.period, period.date),
		colorScheme: {
			domain: ['#7D0096'],
		},
		chartData: subperiods.map((subperiod) => {
			let label = '';
			const data = periodStatistics.find((p) => p.period === subperiod);
			console.log(data);
			switch (period.period) {
				case PeriodEnum.YEAR:
					label = datePipe.transform(new Date(subperiod), 'MMM');
					break;
				case PeriodEnum.MONTH:
					label = datePipe.transform(new Date(subperiod), 'dd (E)');
					break;
				case PeriodEnum.DAY:
					label = datePipe.transform(new Date(subperiod), 'HH:00');
					break;
			}
			return {
				name: label,
				series: [
					{
						name: 'Newsletter link clicks',
						value: data ? data.newsletters.reduce((acc, n) => acc + n.uniqueClicked, 0) : 0,
						extra: {
							groupType: period.period,
							period: subperiod,
							newsletters: data
								? data.newsletters
										.filter((n) => n.uniqueClicked > 0)
										.map((n) => ({
											...n,
											title: newsletterDictionary[n.newsletterId].title,
											sentDate: newsletterDictionary[n.newsletterId].sentDate,
										}))
								: [],
						},
					},
				],
			};
		}),
		yScaleMax: (Math.floor(maxBarValue / 10) + 1) * 10,
	};
};

export const getNewsletterDeliveryNormalizedChartData = (state: State) => {
	const { newsletterDeliveryStatistics, newsletterDelivery } = state;
	if (!newsletterDeliveryStatistics) return;
	const filteredNewsletters = newsletterDeliveryStatistics.filter(n => newsletterDelivery.selectedNewsletters.includes(n.newsletterId));
	const maxBarValue = Math.max(
		...filteredNewsletters.map((n) => n.delivered + n.bounced),
		0
	);
console.log('dataByNewsletter: ', filteredNewsletters)
console.log('maxBarValue: ', maxBarValue)
	return{
		name: 'Normalized newsletter delivery data',
		customColors: [
			{
				name: 'Delivered & opened',
				value: '#00A17C',
			},
			{
				name: 'Delivered not opened',
				value: '#E9B519',
			},
			{
				name: 'Newsletter bounced',
				value: '#C40000',
			},
		],
		chartData: filteredNewsletters.map((n) => ({
			name: `${n.newsletterTitle} | ${n.newsletterId}`,
			series: [
				{
					name: 'Delivered & opened',
					value: n?.uniqueOpened,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced
					}
				},
				{
					name: 'Delivered not opened',
					value: n?.delivered - n?.uniqueOpened,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced
					}
				},
				{
					name: 'Newsletter bounced',
					value: n?.bounced,
					extra: {
						newsletterName: n.newsletterTitle,
						newsletterSentDate: n.newsletterSentDate,
						total: n.delivered + n.bounced
					}
				},
			]
		})),
		xScaleMax: (Math.floor(maxBarValue / 10) + 1) * 10,
	}
}