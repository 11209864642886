import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as fromActions from './templates.actions';
import { Template } from '@app/core/models/newsboard/template';
import { Pagination } from '@app/core/models';

export const feature = 'templates';

export const adapter: EntityAdapter<Template> = createEntityAdapter<Template>({
	selectId: (e) => e.id,
});

export interface State {
	mytemplates: {
		data: EntityState<Template>;
		pagination: Pagination;
		isLoading: boolean;
	};
	shared: {
		data: EntityState<Template>;
		pagination: Pagination;
		isLoading: boolean;
	};
	readonly: {
		data: EntityState<Template>;
		pagination: Pagination;
		isLoading: boolean;
	};
	predefined: {
		data: EntityState<Template>;
		pagination: Pagination;
		isLoading: boolean;
	};
	pending: {
		data: EntityState<Template>;
		pagination: Pagination;
		isLoading: boolean;
	};
}

export const initialState: State = {
	mytemplates: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 6,
		},
		isLoading: false,
	},
	shared: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 6,
		},
		isLoading: false,
	},
	readonly: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 6,
		},
		isLoading: false,
	},
	predefined: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 6,
		},
		isLoading: false,
	},
	pending: {
		data: adapter.getInitialState(),
		pagination: {
			...Pagination,
			pageIndex: 0,
			pageSize: 6,
		},
		isLoading: false,
	},
};

const tableReducer = createReducer(
	initialState,
	on(fromActions.lists.success, (state, { listType, data, refresh, pagination }) => ({
		...state,
		[listType]: {
			...state[listType],
			data: refresh ? adapter.setAll(data, state[listType].data) : adapter.upsertMany(data, state[listType].data),
			pagination,
			isLoading: false,
		},
	})),
	on(fromActions.lists.incrementTotalCount, (state, { listType, by }) => ({
		...state,
		[listType]: {
			...state[listType],
			pagination: {
				...state[listType].pagination,
				totalCount: (state[listType].pagination?.totalCount || 0) + by,
			},
		},
	})),
	on(fromActions.deleteTemplate.success, (state, { id, listType }) => ({
		...state,
		[listType]: {
			...state[listType],
			data: adapter.removeOne(id, state[listType].data),
		},
	})),
	on(fromActions.lists.loadMore, (state, { listType }) => ({
		...state,
		[listType]: {
			...state[listType],
			pagination: {
				...state[listType].pagination,
				pageIndex: state[listType].pagination.pageIndex + 1,
			},
		},
	})),
	on(fromActions.clear, (state) => ({
		...initialState,
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return tableReducer(state, action);
}

export const getMyTemplates = (state: State) => adapter.getSelectors().selectAll(state.mytemplates.data);
export const getMyTemplatesEntities = (state: State) => adapter.getSelectors().selectEntities(state.mytemplates.data);
export const getMyTemplatesPagination = (state: State) => state.mytemplates.pagination;

export const getSharedTemplates = (state: State) => adapter.getSelectors().selectAll(state.shared.data);
export const getSharedTemplatesEntities = (state: State) => adapter.getSelectors().selectEntities(state.shared.data);
export const getSharedTemplatesPagination = (state: State) => state.shared.pagination;

export const getPredefinedTemplates = (state: State) => adapter.getSelectors().selectAll(state.predefined.data);
export const getPredefinedTemplatesEntities = (state: State) =>
	adapter.getSelectors().selectEntities(state.predefined.data);
export const getPredefinedPagination = (state: State) => state.predefined.pagination;

export const getSentToMeTemplates = (state: State) => adapter.getSelectors().selectAll(state.pending.data);
export const getSentToMeTemplatesEntities = (state: State) => adapter.getSelectors().selectEntities(state.pending.data);
export const getSentToMeTemplatesPagination = (state: State) => state.pending.pagination;

export const getReadonlyTemplates = (state: State) => adapter.getSelectors().selectAll(state.readonly.data);
export const getReadonlyTemplatesEntities = (state: State) =>
	adapter.getSelectors().selectEntities(state.readonly.data);
export const getReadonlyTemplatesPagination = (state: State) => state.readonly.pagination;
