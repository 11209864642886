import { animate, keyframes, query, state, style, transition, trigger } from '@angular/animations';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { RdsMenuTriggerDirective, RdsSortDirective, Sort, SortDirection } from '@rds/angular-components';
import {
	BehaviorSubject,
	Observable,
	Subject,
	combineLatest,
	debounceTime,
	distinctUntilChanged,
	map,
	of,
	tap,
} from 'rxjs';
import { RecipientSug } from '../custom-controls/recipients-picker-table/recipients-picker-table.component';
import { CustomValidators } from '../form-controls/validators/validator.function';
import { SORTABLE_LOADING } from '../table/loading-sort-columns';
import { createGuid } from '../utils/guid';
import { TableVirtualScrollStrategy } from './virtual-scroll-table-datasource';

export interface ChipsFilter {
	icon: string;
	iconColorClass: string;
	label: {
		singular: string;
		plural: string;
	};
	condition: (args: any) => boolean;
}

@Component({
	selector: 'rh-table-with-edit-performance',
	templateUrl: './table-with-edit-performance.component.html',
	styleUrls: ['./table-with-edit-performance.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: TableVirtualScrollStrategy }],
	animations: [
		// metadata array
		trigger('highLight', [
			// trigger block
			state('true', style({})),
			state('false', style({})),
			transition(
				'* => true',
				query('.rds-cell', [
					animate(
						'5s',
						keyframes([
							style({ offset: 0 }),
							style({ backgroundColor: '#F0F4FD', offset: 0.05 }),
							style({ backgroundColor: '#F0F4FD', offset: 0.95 }),
							style({ offset: 1 }),
						])
					),
				])
			), // animation timing
			transition('true => *', animate('0ms linear')),
		]),
	],
})
export class TableWithEditPerformanceComponent implements AfterViewInit, OnChanges {
	@ViewChild('headerTableRef', { static: true, read: RdsSortDirective }) sortRef: RdsSortDirective | null = null;
	@ViewChild('headerTableRef', { static: true, read: ElementRef }) headerElementRef: ElementRef | null = null;
	@ViewChild('trigger', { static: false }) trigger: RdsMenuTriggerDirective;

	@HostListener('window:resize', ['$event']) updateAutocomplete(event) {
		this.autocompleteWidth = this.headerElementRef.nativeElement.offsetWidth;
	}
	static BUFFER_SIZE = 3;
	rowHeight = 57;
	headerHeight = 46;

	_autocomplete: { suggestions: Array<RecipientSug>; loading: boolean };
	get autocomplete(): { suggestions: Array<RecipientSug>; loading: boolean } {
		return this._autocomplete;
	}

	@Input() set autocomplete(value: { suggestions: Array<RecipientSug>; loading: boolean }) {
		this._autocomplete = {
			suggestions: value.suggestions,
			loading: value.loading,
		};
		if (!!this.trigger) {
			this.autocompleteWidth = this.headerElementRef.nativeElement.offsetWidth;
			this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading
				? this.trigger.openMenu()
				: this.trigger.closeMenu();
		}
	}

	// inputs
	@Output() rowsChange = new EventEmitter<Array<any>>();

	@Input() defaultSortActive: string;
	@Input() defaultSortDirection: SortDirection;
	@Input() columns: string[] = [];
	@Input() editableColumns: { [key: string]: Array<ValidatorFn> } = {};
	@Input() validityColumn: string;
	@Input() autocompleteColumn: string;
	@Input() autocompleteWidth: number = 400;
	@Input() readOnly: boolean = false;
	@Input() groupColumn: string;
	@Input() isLoading: boolean = false;
	@Input() rows: Observable<Array<any>> = of([]);
	@Input() filters: Array<ChipsFilter> = [];
	activeFilter: ChipsFilter = null;

	lastEdited: { id: string; index: number; scrolled: boolean };
	recentlyAdded: { id: string; scrolled: boolean };

	scrollToElement: {
		id?: string;
		index?: number;
		reason: 'added' | 'edited' | 'showExist';
		scrolled: boolean;
	};

	highlighted: string;
	searchForm: FormGroup = new FormGroup({
		search: new FormControl(''),
	});

	addForm: FormGroup;

	filterPhrase = (row, search) => {
		return Object.keys(this.editableColumns).some((c) => row[c]?.toLowerCase().includes(search.toLowerCase()));
	};

	controlDataSnapshot: Array<{ id: string; groupProp: string; form: { valid: boolean } }> = [];
	loadingRows = [];

	allColumns: Array<string> = [];
	lightColumns: Array<string> = [];
	headerColumns: Array<string> = [];
	loadingColumns = [];

	applyFilter(filter: ChipsFilter) {
		this.activeFilter = filter;
		this.filterChange.next(this.activeFilter);
	}

	getFilterCount(filter: ChipsFilter): number {
		const count = this.controlDataSnapshot.filter((v) => filter.condition(v)).length;
		if (count === 0 && this.activeFilter === filter) {
			this.applyFilter(null);
		}
		return count;
	}

	isSortable(columnName) {
		return SORTABLE_LOADING.includes(columnName);
	}

	deleteColumns = ['omit-alternation', 'delete-checkbox', 'omit-alternation', 'delete'];

	selection = new SelectionModel<{ id: string }>(true, [], true, (a, b) => a.id === b.id);
	selectionChange: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
	editSelection = new SelectionModel<{ id: string }>(true, [], true, (a, b) => a.id === b.id);
	editChange: BehaviorSubject<Array<string>> = new BehaviorSubject([]);
	sortChange: BehaviorSubject<Sort> = new BehaviorSubject(null);
	filterChange: BehaviorSubject<ChipsFilter> = new BehaviorSubject(null);
	searchChange: BehaviorSubject<string> = new BehaviorSubject('');
	rowsWithStates: Observable<Array<any>>;
	requestScroll: BehaviorSubject<boolean> = new BehaviorSubject(true);

	@Output() rowsEdited: EventEmitter<any> = new EventEmitter();
	@Output() rowsDeleted: EventEmitter<any> = new EventEmitter();
	@Output() selectionChanged: Subject<SelectionChange<any>> = this.selection.changed;
	@Output() sortChanged: EventEmitter<Sort> = new EventEmitter();
	@Output() updateValidation: EventEmitter<Array<{ id: string; valid: boolean }>> = new EventEmitter();
	@Output() userAdded: EventEmitter<any> = new EventEmitter();
	@Output() search: EventEmitter<string> = new EventEmitter();

	gridHeight = 360;
	placeholderHeight = 0;
	isSticky = false;
	visibleRows: Array<any> = [];
	allRows: Array<any> = [];

	focusAutocomplete(column) {
		if (column === this.autocompleteColumn) {
			if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
				this.trigger.openMenu();
			}
		} else {
			this.trigger.closeMenu();
		}
	}

	@ViewChild(RdsSortDirective, { static: false }) sort: RdsSortDirective;

	get selectedCount() {
		return this.selection.selected.length;
	}

	get disableMasterToggle() {
		return this.allRows.length === 0;
	}

	get isAnyInEditState() {
		return this.controlDataSnapshot.some((i) => this.editSelection.selected.findIndex((s) => s.id === i.id) > -1);
	}

	get isAllInEditState() {
		return this.controlDataSnapshot.every((i) => this.editSelection.selected.findIndex((s) => s.id === i.id) > -1);
	}

	get isAnySelected() {
		return this.controlDataSnapshot.some((i) => this.selection.selected.findIndex((s) => s.id === i.id) > -1);
	}

	groupBy = (items, groupKey) =>
		items.reduce(
			(result, item) => ({
				...result,
				[item[groupKey]]: [...(result[item[groupKey]] || []), item],
			}),
			{}
		);

	get isAllSelected() {
		return this.controlDataSnapshot.every((i) => this.selection.selected.findIndex((s) => s.id === i.id) > -1);
	}

	constructor(
		@Inject(VIRTUAL_SCROLL_STRATEGY) private readonly scrollStrategy: TableVirtualScrollStrategy,
		private cdr: ChangeDetectorRef
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.readOnly || changes.columns) {
			const bulk = !this.readOnly ? ['omit-alternation', 'select'] : [];
			const actions = !this.readOnly ? ['actions'] : [];
			// this.allColumns = ['index', ...this.columns.map(c => `${c}.light`)];
			this.allColumns = [...bulk, ...this.columns.map((c) => (this.readOnly ? `${c}.light` : `${c}`)), ...actions];
			this.headerColumns = [...this.allColumns, 'omit-alternation', 'scroll-fix'];
		}

		if (changes.allColumns) {
			this.loadingColumns = this.allColumns.map((c) => `${c}.loading`);
		}
	}

	createEmptyTableForm(editableColumns: { [key: string]: Array<ValidatorFn> }) {
		const group = new FormGroup({});
		Object.keys(editableColumns).forEach((key) => {
			group.addControl(`${key}`, new FormControl(''));
		});
		return group;
	}

	addFormValidators(group: FormGroup, editableColumns: { [key: string]: Array<ValidatorFn> }) {
		Object.keys(editableColumns).forEach((key) => {
			group.get(key).setValidators([...editableColumns[key], CustomValidators.isDuplicate(this.controlDataSnapshot)]);
			group.get(key).updateValueAndValidity();
		});
		group.markAllAsTouched();
	}

	removeFormValidators(group: FormGroup, editableColumns: { [key: string]: Array<ValidatorFn> }) {
		Object.keys(editableColumns).forEach((key) => {
			group.get(key).setValidators([]);
			group.get(key).updateValueAndValidity();
		});
		group.markAllAsTouched();
	}

	createOrUpdateTableForm(row: any, editableColumns: { [key: string]: Array<ValidatorFn> }) {
		let group: FormGroup;
		if (!row.form) {
			group = new FormGroup({});
			Object.keys(editableColumns).forEach((key) => {
				group.addControl(
					`${key}`,
					new FormControl(
						row[key],
						key === this.groupColumn
							? [...editableColumns[key], CustomValidators.isDuplicate(this.controlDataSnapshot, row.id)]
							: editableColumns[key]
					)
				);
			});
		} else {
			group = row.form;
			Object.keys(editableColumns).forEach((key) => {
				group
					.get(key)
					.setValidators(
						key === this.groupColumn
							? [...editableColumns[key], CustomValidators.isDuplicate(this.controlDataSnapshot)]
							: editableColumns[key]
					);
				group.get(key).updateValueAndValidity();
			});
		}
		group.markAllAsTouched();

		return group;
	}

	ngAfterViewInit() {
		this.addForm = this.createEmptyTableForm(this.editableColumns);
		this.onSort({ active: this.defaultSortActive, direction: this.defaultSortDirection });
		this.editSelection.changed.subscribe((change) => this.editChange.next(change.source.selected.map((s) => s.id)));
		this.selection.changed.subscribe((change) => this.selectionChange.next(change.source.selected.map((s) => s.id)));
		this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe((value) => this.searchChange.next(value.search));
		// this.dataSource.sort = this.sort; // this.sort is null here as well;
		this.scrollStrategy.setScrollHeight(this.rowHeight, this.headerHeight);
		this.autocompleteWidth = this.headerElementRef.nativeElement.offsetWidth;
		this.addForm.valueChanges
			.pipe(distinctUntilChanged((prev, curr) => prev[this.autocompleteColumn] === curr[this.autocompleteColumn]))
			.subscribe((value) => {
				this.search.emit(value[this.autocompleteColumn]);
			});

		this.rowsWithStates = combineLatest([
			this.rows,
			this.sortChange,
			this.selectionChange,
			this.filterChange,
			this.searchChange,
		]).pipe(
			tap(([rows]) => {
				this.controlDataSnapshot = rows.map((r) => ({
					id: r.id,
					groupProp: r[this.groupColumn],
					form: { valid: r[this.validityColumn] },
				}));
			}),
			map(([rows, sort, selected, filters, search]) => ({ rows, sort, selected, filters, search })),
			// sorting
			map(({ rows, sort, selected, filters, search }) => {
				const { active, direction } = sort;
				const sortPredicate = (a, b) => {
					if (sort.active !== this.validityColumn) {
						return direction === 'asc'
							? (a[active] || '').localeCompare(b[active] || '')
							: (b[active] || '').localeCompare(a[active] || '');
					} else {
						return direction === 'asc'
							? +a[this.validityColumn] - +b[this.validityColumn] ||
									(a[this.groupColumn] || '').localeCompare(b[this.groupColumn] || '')
							: +b[this.validityColumn] - +a[this.validityColumn] ||
									(a[this.groupColumn] || '').localeCompare(b[this.groupColumn] || '');
					}
				};
				return {
					rows: rows.sort((a, b) => sortPredicate(a, b)),
					selected,
					filters,
					search,
				};
			}),
			// mapping
			map(({ rows, selected, filters, search }) => {
				const mapped = rows.map((r, index) => ({
					...r,
					index,
					editing: this.allRows.find((ar) => ar.id === r.id)?.editing,
					selected: selected.includes(r.id),
					form: this.createOrUpdateTableForm(r, this.editableColumns),
				}));
				const idsForUpdateValidation = this.idsForUpdateValidation(
					mapped.map((r) => ({ id: r.id, valid: r.form.valid })),
					this.controlDataSnapshot.map((r) => ({ id: r.id, valid: r.form.valid }))
				);
				if (idsForUpdateValidation.length > 0) {
					const toUpdate = mapped.reduce((result, item) => {
						if (idsForUpdateValidation.findIndex((u) => u.id === item.id) > -1) {
							return {
								...result,
								[item.id]: { valid: item.form.valid },
							};
						}
						return result;
					}, {});
					this.updateValidation.emit(toUpdate);
				}
				return { rows: mapped, filters, search };
			}),
			// filtering
			map(({ rows, filters, search }) => {
				const filterPredicate = (row) => {
					if (filters || search.length > 0) {
						const matchFilters = !!filters ? filters.condition(row) : true;
						const matchSearch = search.length > 0 ? this.filterPhrase(row, search) : true;
						return matchFilters && matchSearch;
					} else {
						return true;
					}
				};
				return rows.filter((r) => filterPredicate(r));
			})
		);

		combineLatest([this.rowsWithStates, this.scrollStrategy.scrolledRangeChange, this.requestScroll])
			.pipe(
				map(([rows, { rangeWithBuffer, rangeInViewport }]) => {
					// Determine the start and end rendered range
					// Update the datasource for the rendered range of data
					return {
						allRows: rows,
						visibleRows: rows.slice(rangeWithBuffer.start, rangeWithBuffer.end),
						range: rangeInViewport,
					};
				})
			)
			.subscribe(({ allRows, visibleRows, range }) => {
				this.visibleRows = [...visibleRows];
				this.allRows = [...allRows];
				const isOnList = this.allRows.findIndex((r) => r.id === this.scrollToElement?.id) > -1;
				if (this.scrollToElement && !this.scrollToElement.scrolled && isOnList) {
					switch (this.scrollToElement.reason) {
						case 'edited': {
							const currIndex = this.allRows.findIndex((r) => r.id === this.scrollToElement.id);
							const prevIndex = this.scrollToElement.index;
							if (currIndex !== prevIndex) {
								this.highlighted = this.scrollToElement.id;
								if (range.start > currIndex || range.end < currIndex) {
									this.scrollToIndex(currIndex);
									this.scrollToElement = {
										...this.scrollToElement,
										scrolled: true,
									};
								}
							}
							break;
						}
						case 'showExist': {
							const currIndex = this.allRows.findIndex((r) => r.id === this.scrollToElement.id);
							this.highlighted = this.scrollToElement.id;
							if (range.start > currIndex || range.end < currIndex) {
								this.scrollToIndex(currIndex);
								this.scrollToElement = {
									...this.scrollToElement,
									scrolled: true,
								};
							}
							break;
						}
						case 'added': {
							this.highlighted = this.scrollToElement.id;
							this.scrollToId(this.scrollToElement.id);
							this.scrollToElement = {
								...this.scrollToElement,
								scrolled: true,
							};
							break;
						}
					}
				}

				this.cdr.markForCheck();
			});
		this.isSticky = true;
	}

	setLoadingRows(size) {
		this.loadingRows = [...Array(size)];
	}

	idsForUpdateValidation(
		tableData: Array<{ id: string; valid: boolean }>,
		controlData: Array<{ id: string; valid: boolean }>
	) {
		const checkData = controlData.map((c) => ({
			id: c.id,
			cValid: c.valid,
			tValid: tableData.find((t) => t.id === c.id)?.valid,
		}));
		return checkData.filter((d) => d.cValid !== d.tValid);
	}

	onSort(event: Sort) {
		let start = this.defaultSortDirection;
		if (event.direction === '') {
			if (event.active === this.defaultSortActive) {
				switch (this.defaultSortDirection) {
					case 'asc':
						start = 'asc';
						break;
					case 'desc':
						start = 'desc';
				}
			}
			this.sortRef.sort({
				id: this.defaultSortActive,
				start,
				disableClear: false,
			});
		} else {
			this.sortChange.next(event);
		}
	}

	clearSearch() {
		this.searchForm.setValue({ search: '' });
	}
	// EDITING
	isEditing(row) {
		return !!this.editSelection.selected.find((s) => s.id === row.id);
	}

	editAll() {
		const selectedIds = this.editSelection.selected.map((s) => s.id);
		const notSelected = this.controlDataSnapshot.filter((d) => !selectedIds.includes(d.id));
		this.editSelection.select(...notSelected);
	}

	saveAll() {
		const selectedRows = this.allRows.reduce((result, item) => {
			if (this.editSelection.isSelected(item) && item.form.touched) {
				return {
					...result,
					[item.id]: {
						...item.form.value,
						included: item.form.valid,
					},
				};
			}
			return result;
		}, {});
		this.editSelection.clear();
		this.rowsEdited.emit(selectedRows);
	}

	cancelAll() {
		this.editSelection.clear();
	}

	editRow(row) {
		this.editSelection.select({ id: row.id });
	}

	deleteRows(rows) {
		this.rowsDeleted.emit(rows);
		this.selection.clear();
	}

	saveRow(row) {
		// const selectedRow = this.allRows.find(s => s.id === row.id);
		this.scrollToElement = { id: row.id, index: row.index, reason: 'edited', scrolled: false };
		this.rowsEdited.emit({
			[row.id]: {
				...row.form.value,
				[this.validityColumn]: row.form.valid,
			},
		});
	}

	cancelRow(row) {
		const selectedRow = this.editSelection.selected.find((s) => s.id === row.id);
		this.editSelection.deselect(selectedRow);
		// row.form.patchValue({email: row.email, name: row.name, surname: row.surname});
	}

	addNew() {
		this.applyFilter(null);
		this.addFormValidators(this.addForm, this.editableColumns);
		const isOnListItem = this.controlDataSnapshot.find(
			(d) => d.groupProp.toLowerCase() === this.addForm.value[this.groupColumn]?.toLowerCase()
		);
		if (!isOnListItem) {
			if (this.addForm.valid) {
				const user = {
					id: createGuid(),
					...this.addForm.value,
					included: true,
				};
				this.scrollToElement = { id: user.id, reason: 'added', scrolled: false };

				this.recentlyAdded = { id: user.id, scrolled: false };
				this.userAdded.emit(user);
				this.addForm.reset();
				this.removeFormValidators(this.addForm, this.editableColumns);
			}
		} else {
			this.scrollToElement = {
				id: isOnListItem.id,
				index: this.controlDataSnapshot.indexOf(isOnListItem),
				reason: 'showExist',
				scrolled: false,
			};
			this.requestScroll.next(true);
			this.addForm.reset();
			this.removeFormValidators(this.addForm, this.editableColumns);
		}
	}

	select(option) {
		this.applyFilter(null);
		const isOnListItem = this.controlDataSnapshot.find(
			(d) => d.groupProp.toLowerCase() === option[this.groupColumn]?.toLowerCase()
		);
		if (!isOnListItem) {
			const user = {
				id: createGuid(),
				email: option.email,
				name: option.name,
				surname: option.surname,
				[this.validityColumn]: true,
			};
			this.scrollToElement = { id: user.id, reason: 'added', scrolled: false };
			this.userAdded.emit(user);
		} else {
			this.scrollToElement = {
				id: isOnListItem.id,
				index: this.controlDataSnapshot.indexOf(isOnListItem),
				reason: 'showExist',
				scrolled: false,
			};
			this.requestScroll.next(true);
		}
		this.addForm.reset();
		this.removeFormValidators(this.addForm, this.editableColumns);
	}

	// BULK
	masterToggle() {
		this.isAllSelected ? this.clearAll() : this.selectAll();
	}

	clearAll() {
		this.selection.clear();
	}

	selectAll() {
		this.selection.select(...this.controlDataSnapshot);
	}

	trackByFn = (index: number, item: any) => item.id;
	noShow = (index: number, item: any) => false;

	scrollToId(id: string) {
		const index = this.allRows.findIndex((r) => r.id === id);
		this.scrollStrategy.scrollToIndex(index);
	}

	scrollToIndex(index: number) {
		this.scrollStrategy.scrollToIndex(index);
	}

	notReadOnly = (index: number, item) => {
		return this.readOnly === false;
	};

	highlightEnds(event) {
		this.highlighted = null;
	}
}
