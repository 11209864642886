import { Component, EventEmitter, Input, Output, OnInit, ViewChild, Optional, Host, SkipSelf } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SimpleUser } from '@app/core/models/newsboard';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';
import { RdsMenuTriggerDirective } from '@rds/angular-components';
import { debounceTime, distinctUntilChanged } from 'rxjs';


@Component({
  selector: 'rh-people-picker',
  templateUrl: './people-picker.component.html',
  styleUrls: ['./people-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PeoplePickerComponent
    }
  ]
})
export class PeoplePickerComponent implements OnInit {
  @ViewChild("trigger", {static: false}) trigger: RdsMenuTriggerDirective;

  selectedUsers: Array<SimpleUser> = [];

  onChange = (users) => {
  };

  onTouched = () => {
  };

  touched = false;

  disabled = false;

  hostWidth: number;

  writeValue(suggestions: Array<SimpleUser>) {
    this.selectedUsers = suggestions;
    this.form.controls.search.updateValueAndValidity();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() selected: EventEmitter<string> = new EventEmitter<string>();
  @Output() removed: EventEmitter<string> = new EventEmitter<string>();

  _autocomplete: {suggestions: Array<SimpleUser>, loading: boolean};
  get autocomplete(): {suggestions: Array<SimpleUser>, loading: boolean} {
      return this._autocomplete;
  }
  @Input() set autocomplete(value: {suggestions: Array<SimpleUser>, loading: boolean}) {
    this._autocomplete = {suggestions: value.suggestions.filter(s => !this.restrictedUsers.some(u => u.identifier === s.identifier) ), loading: value.loading};
    if (!!this.trigger) {
      this._autocomplete.suggestions.length > 0 && !this._autocomplete.loading ? this.trigger.openMenu() : this.trigger.closeMenu();
    }
  }

  @Input() restrictedUsers: Array<SimpleUser> = [];
  @Input() formControl!: FormControl;
  @Input() formControlName!: string;

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  get required() {
    return this.control.hasValidator(Validators.required)
  }

  form: FormGroup = new FormGroup({
    search: new FormControl('')
  });

  @Input() labelFn: (args: any) => string;
  @Input() notRemovable: Array<string> = [];
  @Input() multi: boolean = false;

  canRemove(identifier) {
    return !this.notRemovable.includes(identifier);
  }

  selectedIncludesUser(identifier: string) {
    return this.selectedUsers.findIndex(a => a.identifier === identifier) > -1;
  }

  focus() {
    if (this.autocomplete.suggestions.length > 0 && !this.autocomplete.loading) {
      this.trigger.openMenu()
    }
  }

  blur() {
    this.form.controls.search.updateValueAndValidity();
  }

  select(user: SimpleUser) {
    this.multi ? this.selectedUsers = [...this.selectedUsers, user] : this.selectedUsers = [user];
    this.form.controls.search.setValue('');
    this.selected.emit();
    this.onChange(this.selectedUsers.slice())
  }

  remove(user: SimpleUser) {
    this.selectedUsers = this.selectedUsers.filter(u => u.identifier !== user.identifier);
    this.form.controls.search.setValue('');
    this.removed.emit();
    this.onChange(this.selectedUsers);
  }

  ngOnInit(): void {
    this.form.controls.search.setValidators(CustomValidators.isMasterControlValid(this.control));
    this.form.controls.search.updateValueAndValidity();

    const originalMarkAsTouched = this.control.markAsTouched;
    const that = this;
    this.control.markAsTouched = function() {
      originalMarkAsTouched.apply(this, arguments);
      that.form.controls.search.markAsTouched();
      that.form.controls.search.updateValueAndValidity();
    }

    this.control.statusChanges.pipe(debounceTime(100)).subscribe(status => {
      this.form.controls.search.updateValueAndValidity();
    });
    this.form.controls.search.valueChanges.pipe(distinctUntilChanged()).subscribe(s => this.search.emit(s));
  }

  constructor(    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,) {
  }

}
