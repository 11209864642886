import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
	AnchorBlockProperties,
	Block,
	BlockTypes,
	TableOfContentBlockProperties,
} from '@app/newsletter-new/models/block-type.enum';
import { createGuid } from '@app/shared/utils/guid';
import {
	RDS_DIALOG_DATA,
	RdsDialogRef,
	RdsSingleSelectFilterByFunc,
	RdsSingleSelectOptionComponent,
} from '@rds/angular-components';
import { SubSink } from 'subsink';

export interface TableOfContentDialogData {
	id: number;
	blockType: BlockTypes;
	settings: TableOfContentBlockProperties;
	anchors: Array<Block<AnchorBlockProperties>>;
}

@Component({
	selector: 'rh-table-of-content-dialog',
	templateUrl: './table-of-content-dialog.component.html',
	styleUrls: ['./table-of-content-dialog.component.scss'],
})
export class TableOfContentDialogComponent implements OnInit, OnDestroy {
	private subs = new SubSink();

	tableOfContentForm: FormGroup = new FormGroup({
		title: new FormControl('Table of Content'),
		anchors: new FormArray([]),
		settings: new FormControl(null),
	});

	get anchorsArray() {
		return this.tableOfContentForm.controls.anchors as FormArray;
	}

	constructor(
		private dialogRef: RdsDialogRef<TableOfContentDialogComponent>,
		@Inject(RDS_DIALOG_DATA) public data: TableOfContentDialogData,
		private cdr: ChangeDetectorRef
	) {}

	ngOnDestroy(): void {
		this.subs.unsubscribe();
	}

	ngOnInit() {
		this.tableOfContentForm.setValue({
			title: this.data.settings.title,
			settings: this.data.settings,
			anchors: [],
		});
		if (this.data.anchors.length) {
			this.data.anchors.map((a) =>
				this.anchorsArray.push(
					new FormGroup({
						label: new FormControl(a.settings.label),
					})
				)
			);
		} else {
			this.createAnchorsForm();
		}
	}

	newsChange() {
		this.cdr.detectChanges();
	}

  submit() {
    this.dialogRef.close({
      ...this.data,
      tableOfContentSettings: {
        title: this.tableOfContentForm.controls.title.value,
      } as TableOfContentBlockProperties,
      anchors: this.tableOfContentForm.controls.anchors.value.map(a => ({
        ...a.block,
        settings: {
          ...a.block.settings,
          label: a.label
        }
      } as Block<AnchorBlockProperties>))
    });
  }
     
  filterBy: RdsSingleSelectFilterByFunc<string> = (
    text: string | null,
    item: RdsSingleSelectOptionComponent<string>
  ) => {
    if (text === null || text === "") {
      return true;
    } else {
      return item.viewValue.toLowerCase().includes(text.toLowerCase());
    }
  };
  
  createAnchorsForm(block: Block<AnchorBlockProperties> = {
    id: createGuid(),
    settings: {
      label: ''
    },
    type: BlockTypes.ANCHOR
  }) {
    this.anchorsArray.push(
      new FormGroup({
        label: new FormControl(block.settings.label),
        block: new FormControl(block)
      })
    );
  }
}
