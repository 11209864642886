
<div class="table-filters" [formGroup]="filters">
  <p *ngIf="showLabel" class="table-filters__label">Filter by:</p>
  <ng-container *ngFor="let filter of filters.controls | keyvalue: showFiltersInOrder; trackBy: trackByFn">
    <ng-container *ngIf="filter.value.controls.changable.value" [formGroup]="filters.get(filter.key)">
      <ng-container [ngSwitch]="filter.value.controls.type.value">
        <rds-form-field *ngSwitchCase="FilterTypeEnum.SELECT" class="table-filters__control--select">
          <rds-control-label>
            {{filter.value.controls.label.value}}
            <span *ngIf="filter.value.controls.multiple.value && filter.value.controls.value.value?.length as count" class="c-600-roche-blue">
              ({{count > 99 ? '99+' : count}})
            </span>
          </rds-control-label>
          <ng-container *ngIf="filter.value.controls.multiple.value">
            <rds-multi-select formControlName="value" [hideValue]="filter.value.controls.multiple.value">
              <rds-multi-select-all-option *ngIf="filter.value.controls.options.value.length > 5"></rds-multi-select-all-option>
              <rds-multi-select-option *ngFor="let option of filter.value.controls.options.value" [value]="option.value">{{option.label}}</rds-multi-select-option>
            </rds-multi-select>
          </ng-container>
          <ng-container *ngIf="!filter.value.controls.multiple.value">
            <rds-select formControlName="value">
              <rds-select-all-option *ngIf="filter.value.controls.options.value.length > 5"></rds-select-all-option>
              <rds-select-option *ngFor="let option of filter.value.controls.options.value" [value]="option.value">{{option.label}}</rds-select-option>
            </rds-select>
          </ng-container>

        </rds-form-field>


        <rds-form-field *ngSwitchCase="FilterTypeEnum.SEARCH" class="table-filters__control--search">
          <rds-control-label>Search</rds-control-label>
          <rds-icon
              rds-control-prefix
              namespace="outlined"
              icon="search"
          ></rds-icon>
          <input
              rdsInput
              formControlName="value"
          />
          <div class="table-filters__control--search-clear">
            <button *ngIf="filter.value.controls.value.value.length" rds-icon-button rds-control-suffix (click)="clearSearch()">
              <rds-icon
                rds-control-suffix
                namespace="outlined"
                icon="close"
              ></rds-icon>
            </button>
          </div>
      </rds-form-field>

      <ng-container *ngSwitchCase="FilterTypeEnum.SUGGESTION">
        <ng-container [ngSwitch]="filter.value.controls.suggestion.value">
          <rh-people-picker 
            *ngSwitchCase="SuggestionTypeEnum.USER"
            (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
            [multi]="filter.value.controls.multiple.value"
            formControlName="value"
            [autocomplete]="suggestions[filter.key] | async">
          </rh-people-picker>

          <rh-entity-picker 
            *ngSwitchCase="SuggestionTypeEnum.CHANNEL"
            (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
            [multi]="filter.value.controls.multiple.value"
            label="Search for channel"
            chipPlaceholder="Selected channels appear here"
            formControlName="value"
            [autocomplete]="suggestions[filter.key] | async">
          </rh-entity-picker>

          <rh-entity-picker 
            *ngSwitchCase="SuggestionTypeEnum.CATALOG"
            (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
            [multi]="filter.value.controls.multiple.value"
            label="Search for catalog"
            chipPlaceholder="Selected catalogs appear here"
            formControlName="value"
            [autocomplete]="suggestions[filter.key] | async">
          </rh-entity-picker>

          <rh-topic-picker 
          *ngSwitchCase="SuggestionTypeEnum.TOPIC"
          (search)="getAutocompletes(filter.value.controls.suggestion.value, filter.key, $event)"
          [multi]="filter.value.controls.multiple.value"
          formControlName="value"
          [autocomplete]="suggestions[filter.key] | async">
        </rh-topic-picker>

        </ng-container>
      </ng-container>

      <rh-advanced-filters 
      *ngSwitchCase="FilterTypeEnum.ADVANCED"
      [advancedFilters]="tableFilters.advancedFilters.filters"
      [title]="advancedFiltersTitle"
      [defaultFilters]="defaultFilters.advancedFilters.filters"
      (filtersChanged)="advancedFiltersChanged($event)"
      >
      </rh-advanced-filters>

      <rh-dropdown
        *ngSwitchCase="FilterTypeEnum.DICTIONARY"
        class="table-filters__control--select"
        formControlName="value"
        size="small"
        [closeOnClickOutside]="true"
        [floatingOptions]="true"
        [showChips]="false"
        [isMultiple]="true"
        [optionsDictionary]="dictionaries[filter.value.controls.dictionary.value].dict | async"
        [optionsModel]="dictionaries[filter.value.controls.dictionary.value].model"
      >
        <span label>
          {{filter.value.controls.label.value}}
          <span *ngIf="filter.value.controls.value.value?.length as count" class="c-600-roche-blue">
            ({{count > 99 ? '99+' : count}})
          </span>
        </span>
      </rh-dropdown>

      <ng-container *ngSwitchCase="FilterTypeEnum.DATE">
        <rds-form-field *ngIf="filter.value.controls.range.value" class="table-filters__control--select">
            <rds-control-label>Date range</rds-control-label>
            <rds-date-range-input [rangePicker]="modifiedPicker" [formGroup]="filter.value.controls.value">
              <input #modifiedStartInput  rdsStartDate placeholder="Start date" (focus)="modifiedPicker.open()" formControlName="start" />
              <input #modifiedEndInput  rdsEndDate placeholder="End date" (focus)="modifiedPicker.open()" formControlName="end" />
            </rds-date-range-input>
            <rds-datepicker-toggle
              rds-control-suffix
              [forDatepicker]="modifiedPicker"
            ></rds-datepicker-toggle>
            <rds-date-range-picker #modifiedPicker (closedStream)="modifiedStartInput.blur();modifiedEndInput.blur()">
              <rds-datepicker-actions>
                <button
                  rds-text-button
                  rdsDatepickerCancel
                >Cancel</button>
                <button
                  rds-secondary-button
                  (click)="clearRange(filter.value.controls.value, modifiedPicker)"
                >Clear</button>
                <button
                  rds-primary-button
                  rdsDatepickerApply
                >Apply</button>
              </rds-datepicker-actions>
            </rds-date-range-picker>
          </rds-form-field>

          <rds-form-field *ngIf="!filter.value.controls.range.value" class="table-filters__control--select">
            <rds-control-label>Date</rds-control-label>
            <input
              #singlePickerInput
              rdsInput
              [min]="now"
              formControlName="value"
              (focus)="singlePicker.open()"
              [rdsDatepicker]="singlePicker"
            />
            <rds-datepicker-toggle
              rds-control-suffix
              [forDatepicker]="singlePicker"
            ></rds-datepicker-toggle>
            <rds-datepicker
              #singlePicker
              (closedStream)="singlePickerInput.blur()"
              [touchUi]="false"
            ></rds-datepicker>
          </rds-form-field>
      </ng-container>
      
      </ng-container>
    </ng-container>
  </ng-container>
  <button *ngIf="showLabel" class="table-filters__clear-button" [disabled]="disableClear" rds-text-button (click)="clearAll()">Reset all</button>
</div>


