import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Autocomplete } from '@app/root-store/suggestions';
import { Store, select } from '@ngrx/store';
import { RDS_DIALOG_DATA } from '@rds/angular-components';
import { Observable } from 'rxjs';
import * as fromSuggestions from '@app/root-store/suggestions';
import { NewsboardAuthService, SimpleUser } from '@app/core/auth/services/newsboard-auth.service';
import { CustomValidators } from '@app/shared/form-controls/validators/validator.function';

export interface SendCopyDialogData {
  title: string;
  message?: string;
  preventAddYourself?: boolean;
  confirmButtonLabel: string;
  initialUsers?: Array<SimpleUser>;
  notRemovable?: Array<string>;
}
@Component({
  selector: 'rh-send-copy-dialog',
  templateUrl: './send-copy-dialog.component.html',
  styleUrls: ['./send-copy-dialog.component.scss']
})
export class SendCopyDialogComponent {
  autocomplete$: Observable<Autocomplete> = this.store$.pipe(select(fromSuggestions.selectAutocomplete('send-copy')))
  initialUsers = [];
  notRemovable = [];
  currentUser = this.auth.currentSimpleUser;

  public form: FormGroup = new FormGroup({
    users: new FormControl([], CustomValidators.notEmptyList)
  });

  constructor(@Inject(RDS_DIALOG_DATA) public data: SendCopyDialogData, private auth: NewsboardAuthService, private store$: Store<any>) {
    this.store$.dispatch(fromSuggestions.initSuggestion({suggestionType: 'user', prop: 'send-copy'}));
    if (!!data.notRemovable) {
      this.notRemovable = data.notRemovable;
    }
    if ( !!data.initialUsers) {
      this.initialUsers = data.initialUsers;
      this.form.controls.users.patchValue(data.initialUsers);
    }
   }
   
  getAutocompletes(prop, event) {
    this.store$.dispatch(fromSuggestions.loadSuggestion({suggestionType: 'user', prop, phrase: event}))
  }
}
