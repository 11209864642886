import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {EndpointsService} from './endpoints/endpoints.service';
import * as fromAuth from '@core/auth/store/auth.actions';
import * as fromCloudSearch from '../../search/store/cloud-search';
import {Store} from '@ngrx/store';
import {from, Observable, of} from 'rxjs';
import {GCSQueryRequest, GCSQueryResponse} from '@app/search/models/gcs';
import {catchError, map} from 'rxjs/operators';
import {RhEvent} from '@app/events/models/event';
import {zonedTimeToUtc} from 'date-fns-tz';
import {format, formatISO} from 'date-fns';

declare let gapi: any;

export enum ConstGapiService {
  calendarId = 'c_09d10834279df8a0852eddd151f0dc9e2d0bbf66c25ac64d6f1ca628bc3da054@group.calendar.google.com'
}

@Injectable()
export class GapiService {

  public userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  private requestOptions = {
    languageCode: 'en',
    debugOptions: {
      enableDebugging: true
    },
    timeZone: 'Europe/Warsaw',
    searchApplicationId: 'searchapplications/default'
  };

  setToken(token) {
    gapi.client.setToken({access_token: token})
  }

  init(token?) {
    gapi.load('client', () => {
      gapi.client.load('cloudsearch', 'v1', () => {
        gapi.client.setToken({access_token: token});
        this.store$.dispatch(fromAuth.initQueryAPISuccess());
        // gapi.client.cloudsearch.query.sources.list({
        //   'requestOptions.languageCode': 'en',
        //   'requestOptions.debugOptions.enableDebugging': true,
        //   'requestOptions.timeZone': 'Europe/Warsaw',
        //   'requestOptions.searchApplicationId': 'searchapplications/default',
        //   'pageToken': '15'
        // }).then(res => console.log(res));
      })
      gapi.client.load('calendar', 'v3', () => {
        this.store$.dispatch(fromAuth.initGcalAPISuccess());
      })
    })
  }

  query(request: Partial<GCSQueryRequest>): Observable<any> {
    return from(gapi.client.cloudsearch.query.search({
      ...request,
      requestOptions: this.requestOptions
    })).pipe(
      map((response: { result: GCSQueryResponse }) => response.result),
      catchError((response) => {

        if (response.status === 403) {
          if (response?.result?.error?.status === 'PERMISSION_DENIED') {
            this.store$.dispatch(fromCloudSearch.setNoPermissions());
          }
        }
        if (response.status === 401) {
          if (response?.result?.error?.status === 'UNAUTHENTICATED') {
            this.store$.dispatch(fromAuth.refreshTokenRequest({}));
          }
        }
        return of(response.result);
      }))
  }

  getIncomingEvents() {
    const req = {
      'calendarId': 'primary',
      'timeMin': new Date().toISOString(),
      'timeMax': new Date(new Date().setHours(23, 59, 59, 59)).toISOString(),
      'showDeleted': false,
      'singleEvents': true,
      'maxResults': 3,
      'orderBy': 'startTime',
    };

    return from(gapi.client.calendar.events.list(req)).pipe(
      map((response: any) => response.result.items.filter(i => i.hangoutLink).map((i): Partial<RhEvent> => ({
        title: i.summary,
        date: new Date(i.start.date),
        meetingUrl: i.hangoutLink,
        location: "Google Meet"
      })))
    )
  }

  getAddEventToGcalUrl(event: Partial<RhEvent>) {
    let startDate, endDate;
    if (event.allDay) {
      const newDate = new Date(event.date);
      startDate = endDate = format(newDate, 'yyyyMMdd');
    } else {
      const startDateTmp = event.startDate || event.startTime || event.date;
      const startDateObject = zonedTimeToUtc(new Date(startDateTmp), event.timeZone);
      const endDateTmp = event.endDate || event.endTime || event.date;
      const endDateObject = zonedTimeToUtc(new Date(endDateTmp), event.timeZone);
      startDate = formatISO(startDateObject, {format: 'basic'});
      endDate = formatISO(endDateObject, {format: 'basic'});
    }
    return `https://calendar.google.com/calendar/u/0/r/eventedit?text=${event.title}&dates=${startDate}/${endDate}&location=${event.location}&details=${event.description}`
  }

  addEventToGcal(event: Partial<RhEvent>) {
    const addEventUrl = this.getAddEventToGcalUrl(event);
    window.open(addEventUrl)
    return of(null)

    // const details = event.eventUrl ? event.description + `<a href="${event.eventUrl}" _blank>Visit page event </a>` : event.description;
    // let req = {}
    // req = {
    //   ...req,
    //   calendarId: 'primary'
    // }
    //
    // if (event.meetingUrl) {
    //   req = {
    //     ...req,
    //     conferenceDataVersion: 1
    //   }
    // }
    // let body = {};
    //
    //
    // body = {
    //
    //   description: event.description,
    //   // id: event.id,
    //   location: event.location,
    //   summary: event.title
    // }


    // if (event.meetingUrl) {
    //   const conferenceId = event.meetingUrl.split('https://meet.google.com/')[1];
    //
    //   if (conferenceId) {
    //     body = {
    //       ...body,
    //       conferenceData: {
    //         conferenceId,
    //         conferenceSolution: {
    //           key: {
    //             type: "hangoutsMeet"
    //           },
    //           name: "Google Meet",
    //           iconUri: "https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v6/web-512dp/logo_meet_2020q4_color_2x_web_512dp.png"
    //         },
    //         entryPoints: [
    //           {
    //             entryPointType: "video",
    //             uri: `https://meet.google.com/${conferenceId}`,
    //             label: `meet.google.com/${conferenceId}`
    //           }
    //         ]
    //       }
    //     }
    //   } else {
    //     body = {
    //       ...body,
    //       description: event.description + ' ' + event.meetingUrl,
    //       source: {
    //         url: event.meetingUrl,
    //         title: event.title
    //       }
    //     }
    //   }
    // }
    // return from(gapi.client.calendar.events.insert({
    //   ...req,
    //   resource: body
    // })).pipe(
    //   map((response: any) => response),
    //   catchError((response) => {
    //
    //     if (response.status === 403) {
    //       if (response?.result?.error?.status === 'PERMISSION_DENIED') {
    //         this.store$.dispatch(fromAuth.addPermissionCalendar());
    //       }
    //     }
    //     if (response.status === 401) {
    //       if (response?.result?.error?.status === 'UNAUTHENTICATED') {
    //         const actionToRetry = fromEvents.addToCalendarWithGetDetailsRequest({eventId: event.id});
    //         this.store$.dispatch(fromAuth.refreshTokenRequest({actionToRetry}));
    //       }
    //     }
    //     return response;
    //   }))
  }

  iCalUID = '';
  id = '';

  event = {
    "kind": "calendar#event",
    "etag": "\"3333206629042000\"",
    "id": "caroq8c93rvtaj3kmjkotfmsus",
    "status": "confirmed",
    "htmlLink": "https://www.google.com/calendar/event?eid=Y2Fyb3E4YzkzcnZ0YWoza21qa290Zm1zdXMgY18wOWQxMDgzNDI3OWRmOGEwODUyZWRkZDE1MWYwZGM5ZTJkMGJiZjY2YzI1YWM2NGQ2ZjFjYTYyOGJjM2RhMDU0QGc",
    "created": "2022-10-24T09:21:54.000Z",
    "updated": "2022-10-24T09:21:54.521Z",
    "summary": "Event to share",
    "description": "A chance to hear more about Google's developer products.",
    "location": "Test location",
    "creator": {
      "email": "radoslaw.szok@contractors.roche.com",
      "displayName": "Radoslaw Szok"
    },
    "organizer": {
      "email": "c_09d10834279df8a0852eddd151f0dc9e2d0bbf66c25ac64d6f1ca628bc3da054@group.calendar.google.com",
      "displayName": "RH test calendar",
      "self": true
    },
    "start": {
      "dateTime": "2022-10-24T12:00:00+02:00",
      "timeZone": "America/Los_Angeles"
    },
    "end": {
      "dateTime": "2022-10-24T13:00:00+02:00",
      "timeZone": "America/Los_Angeles"
    },
    "iCalUID": "caroq8c93rvtaj3kmjkotfmsus@google.com",
    "sequence": 0,
    "reminders": {
      "useDefault": true
    },
    "eventType": "default"
  }


  // addEventToGlobalCalendar() {
  //   const event = {
  //     'summary': 'Event to share',
  //     'location': 'Test location',
  //     'description': 'A chance to hear more about Google\'s developer products.',
  //     'start': {
  //       'dateTime': '2022-10-24T12:00:00+02:00',
  //       'timeZone': 'America/Los_Angeles'
  //     },
  //     'end': {
  //       'dateTime': '2022-10-24T13:00:00+02:00',
  //       'timeZone': 'America/Los_Angeles'
  //     },
  //     'conferenceDataVersion': 1,
  //     'conferenceData': {
  //       'createRequest': {'requestId': "rhevent20221024meeting"}
  //     }
  //   };
  //
  //   return from(gapi.client.calendar.events.insert({
  //     'calendarId': ConstGapiService.calendarId,
  //     'resource': event
  //   })).pipe(
  //     tap((response: any) => {
  //       this.iCalUID = response.iCalUID;
  //       this.id = response.id;
  //     }),
  //     map((response: any) => response)
  //   )
  // }


  updateEventToGlobalCalendar() {
    gapi.client.calendar.events.get({
      'calendarId': ConstGapiService.calendarId,
      'eventId': this.id
    }, (error, result) => {
      if (error) {
        console.log("Something went wrong: ", error); // If there is an error, log it to the console
      } else {
      }
    })
  }

  addToUserCalendar() {

    return from(gapi.client.calendar.events.import({
      'calendarId': 'primary',
      'resource': this.event
    })).pipe(
      map((response: any) => response)
    )
  }

  getCalendarList() {
    const req = {
      'calendarId': ConstGapiService.calendarId,
      'timeMin': new Date().toISOString(),
      'showDeleted': false,
      'singleEvents': true,
      'maxResults': 10,
      'orderBy': 'startTime',
      'iCalUID': ['0a3tsbgd3himd7i59rj9bp3ta6@google.com', '61utngi1g8rc4bujo23h80cema@google.com']
    };

    return from(gapi.client.calendar.events.list(req)).pipe(
      map((response: any) => response)
    )
  }

  constructor(private store$: Store, private http: ApiService, private endpoints: EndpointsService) {
  }
}
