import {
	CampaignTableModel,
	RecipientListsTableModel,
	SentRecipientsListModel,
} from '@app/core/models/newsletter.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './mock.actions';
import {
	RECIPIENT_LIST_MOCK,
	RECIPIENTS_LIST_DETAILS_MOCK,
	RECIPIENTS_LIST_SENT_MOCK,
} from '@app/core/services/newsletter-mock.service';
import { RhRecipientsListForm } from '@app/newsletter-new/models/recipients-list';

export const campaignAdapter: EntityAdapter<CampaignTableModel> = createEntityAdapter<CampaignTableModel>({
	selectId: (e) => e.id,
});

export const campaignDetailsAdapter: EntityAdapter<CampaignTableModel> = createEntityAdapter<CampaignTableModel>({
	selectId: (e) => e.id,
});

export const recipientListAdapter: EntityAdapter<RecipientListsTableModel> =
	createEntityAdapter<RecipientListsTableModel>({
		selectId: (e) => e.id,
	});

export const recipientListDetailsAdapter: EntityAdapter<Partial<RhRecipientsListForm>> = createEntityAdapter<
	Partial<RhRecipientsListForm>
>({
	selectId: (e) => e.id,
});

export const recipientSentToMeAdapter: EntityAdapter<SentRecipientsListModel> =
	createEntityAdapter<SentRecipientsListModel>({
		selectId: (e) => e.id,
	});

export interface State {
	initialized: boolean;
	campaign: EntityState<CampaignTableModel>;
	recipientList: EntityState<RecipientListsTableModel>;
	recipientListDetails: EntityState<Partial<RhRecipientsListForm>>;
	recipientSentToMe: EntityState<SentRecipientsListModel>;
}

export const initialState: State = {
	initialized: false,
	campaign: campaignAdapter.getInitialState(),
	recipientList: recipientListAdapter.getInitialState(),
	recipientListDetails: recipientListDetailsAdapter.getInitialState(),
	recipientSentToMe: recipientSentToMeAdapter.getInitialState(),
};

const contentPageReducer = createReducer(
	initialState,
	on(fromActions.mock.init, (state) => {
		if (state.initialized) return { ...state };
		return {
			initialized: true,
			campaign: campaignAdapter.addMany([], state.campaign),
			recipientList: recipientListAdapter.addMany(RECIPIENT_LIST_MOCK, state.recipientList),
			recipientListDetails: recipientListDetailsAdapter.addMany(
				RECIPIENTS_LIST_DETAILS_MOCK,
				state.recipientListDetails
			),
			recipientSentToMe: recipientSentToMeAdapter.addMany(RECIPIENTS_LIST_SENT_MOCK, state.recipientSentToMe),
		};
	}),
	// campaign
	on(fromActions.campaign.delete, (state, { id }) => ({
		...state,
		campaign: campaignAdapter.removeOne(id, state.campaign),
	})),
	on(fromActions.campaign.create, (state, { data }) => ({
		...state,
		campaign: campaignAdapter.addOne(
			{
				...data,
				id: Math.max(...state.campaign.ids.map((id) => +id)) + 1,
			},
			state.campaign
		),
	})),
	on(fromActions.campaign.edit, (state, { id, data }) => ({
		...state,
		campaign: campaignAdapter.upsertOne(data, state.campaign),
	})),
	// recipient list
	on(fromActions.recipientList.delete, (state, { id }) => ({
		...state,
		recipientList: recipientListAdapter.removeOne(id, state.recipientList),
		recipientListDetails: recipientListDetailsAdapter.removeOne(id, state.recipientListDetails),
	})),
	on(fromActions.recipientList.create, (state, { data }) => ({
		...state,
		recipientListDetails: recipientListDetailsAdapter.addOne(
			{
				...data,
				id: Math.max(...state.recipientList.ids.map((id) => +id)) + 1,
			},
			state.recipientListDetails
		),
		recipientList: recipientListAdapter.addOne(
			{
				...data,
				name: data.name,
				edited: new Date(),
				id: Math.max(...state.recipientList.ids.map((id) => +id)) + 1,
				recipients: [],
				blockedRecipients: [],
				sharedBy: null,
			},
			state.recipientList
		),
	})),
	on(fromActions.recipientList.edit, (state, { id, data }) => ({
		...state,
		recipientListDetails: recipientListDetailsAdapter.upsertOne(data, state.recipientListDetails),
		recipientList: recipientListAdapter.upsertOne(
			{
				id: data.id,
				edited: new Date(),
				name: data.name,
				recipients: [],
				blockedRecipients: [],
				sharedBy: null,
			},
			state.recipientList
		),
	})),
	// recipient list sent to me
	on(fromActions.recipientListSentToMe.reject, (state, { id }) => ({
		...state,
		recipientListSentToMe: recipientSentToMeAdapter.removeOne(id, state.recipientSentToMe),
	}))
);

export function reducer(state: State | undefined, action: Action) {
	return contentPageReducer(state, action);
}

export const getCampaign = (state: State) => state.campaign;
export const getCampaignData = (state: State) => campaignAdapter.getSelectors().selectAll(state.campaign);

export const getRecipientList = (state: State) => state.recipientList;
export const getRecipientListData = (state: State) =>
	recipientListAdapter.getSelectors().selectAll(state.recipientList);

export const getRecipientListDetails = (state: State) => state.recipientListDetails;
export const getRecipientListDetailsData = (state: State) =>
	recipientListDetailsAdapter.getSelectors().selectAll(state.recipientListDetails);
