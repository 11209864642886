import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum FormStepStatus {
  NOT_DEFINED = 'NOT_DEFINED',
  IN_PROGRESS = 'IN_PROGRESS',
  INCOMPLETE = 'INCOMPLETE',
  COMPLETED = 'COMPLETED',
}

@Component({
  selector: 'rh-form-status-badge',
  templateUrl: './form-status-badge.component.html',
  styleUrls: ['./form-status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormStatusBadgeComponent {

  @Input() status: FormStepStatus;
  FormStepStatus = FormStepStatus;
  constructor() {}
}
