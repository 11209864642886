import { createSelector } from '@ngrx/store';
import * as fromNewsletter from '@app/newsletter-new/store/newsletter.reducer';
import * as fromReducer from '@app/newsletter-new/store/campaign-table/campaign-table.reducer';

export const selectCampaignTable = createSelector(fromNewsletter.selectState, fromNewsletter.getCampaignTable);

export const selectSelectionListState = createSelector(selectCampaignTable, fromReducer.getSelectionListState);

export const selectSelectionListStatus = createSelector(selectSelectionListState, fromReducer.getSelectionListStatus);

export const selectSelectionListLoaded = createSelector(selectSelectionListState, (list) => list.status === 'LOADED');

export const selectSelectionListData = createSelector(selectSelectionListState, fromReducer.getSelectionListData);
export const selectCampaignById = createSelector(selectSelectionListData, fromReducer.getEntityById);
export const selectSelectionListPagination = createSelector(
	selectSelectionListState,
	fromReducer.getSelectionListPagination
);

export const selectTableState = createSelector(selectCampaignTable, fromReducer.getTableState);

export const selectTableRequestData = createSelector(selectTableState, fromReducer.getRequestData);

export const selectTableData = createSelector(selectTableState, fromReducer.getTableData);

export const selectTablePagination = createSelector(selectTableState, fromReducer.getPagination);

export const selectTableTotal = createSelector(selectTablePagination, fromReducer.getTotalCount);

export const selectTableSort = createSelector(selectTableState, fromReducer.getSort);

export const selectTableDefaultSort = createSelector(selectTableState, fromReducer.getDefaultSort);

export const selectTableFilters = createSelector(selectTableState, fromReducer.getFilters);

export const selectTableDefaultFilters = createSelector(selectTableState, fromReducer.getDefaultFilters);

export const selectTableSearch = createSelector(selectTableFilters, fromReducer.getSearch);

export const selectTableIsLoading = createSelector(selectTableState, fromReducer.getIsLoading);
