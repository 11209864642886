<div #formField class="picker">
    <div class="picker__input">
        <rds-form-field [formGroup]="form">
            <rds-control-label>
                <span requireAsterisk>{{label}}</span>
            </rds-control-label>
            <rds-icon rds-control-prefix namespace="outlined" icon="search"></rds-icon>
            <input
                    rdsInput

      (focus)="focus()"
      (blur)="blur()"
      (keyup.enter)="form.controls.search.value.length > 2 ? select(form.controls.search.value) : {}"
      formControlName="search"
    />
  </rds-form-field>
  <div rdsDropdown>
    <button rds-dropdown-toggle-button size="l" class="topic__value-container--autocomplete-trigger"
            [rdsMenuTriggerFor]="menu"
            #trigger="rdsMenuTrigger"
            [isOpen]="trigger.menuOpen"
    >Toggle
    </button>
    <rds-menu #menu="rdsMenu" class="topic__value-container--autocompletes">
      <ng-container *ngIf="autocomplete.loading">
        <div [ngStyle]="{'width.px': formField.offsetWidth}" class="topic__value-container--autocompletes__row loading">
          <rds-progress-bar></rds-progress-bar>
        </div>
      </ng-container>
      <ng-container *ngIf="!autocomplete.loading">
        <div *ngFor="let option of autocomplete.suggestions" rds-menu-item
             (click)="selectedIncludesTopic(option)? remove(option) : select(option)"
             [ngStyle]="{'width.px': formField.offsetWidth}">
          <span>{{option}}</span>
        </div>
      </ng-container>
    </rds-menu>
  </div>

    <div class="picker__selected">
        <rds-chip-list size="s" type="filter">
            <rds-chip *ngIf="selectedTopics.length === 0" [disabled]="true">{{chipPlaceholder}}</rds-chip>
            <rds-chip *ngFor="let selected of selectedTopics; let index = index;" rdsChipInput
                      [closable]="!disabled ? true : null" (closeChip)="remove(index)">{{selected}}</rds-chip>
        </rds-chip-list>
    </div>
</div>
<rh-subscript-wrapper *ngIf="!!control" [hasError]="!control.valid && control.touched" [disabled]="disabled">
    <ng-content select="rds-control-hint" ngProjectAs="rds-control-hint"></ng-content>
    <ng-content select="rds-control-error" ngProjectAs="rds-control-error"></ng-content>
</rh-subscript-wrapper>
