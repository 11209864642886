import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NewsletterService} from '@core/services/newsletter.service';
import * as fromActions from '@app/newsletter/store/newsletter-form/newsletter-form.actions';
import * as fromSelectors from '@app/newsletter/store/newsletter-form/newsletter-form.selectors';
import * as fromFormOther from '@app/newsletter/store/newsletter-other/newsletter.actions';
import {catchError, filter, first, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromUiReducer from '@app/root-store/ui/ui.reducer';
import {WorkdayService} from '@app/core/services/workday.service';
import {UserProfileService} from '@core/services/userProfile.service';
import * as fromDepartmentsFlat from '@core/core-store/departments-flat';
import * as fromFunctionsFlat from '@core/core-store/functions-flat';
import * as fromLocationsFlat from '@core/core-store/locations-flat';
import * as fromLanguagesDict from '@core/core-store/languages-dict';
import * as fromNewsletter from '@app/newsletter/store/newsletter-other';
import * as fromRouter from '@app/root-store/router/router.actions';
import {ROUTER_REQUEST} from '@ngrx/router-store';
import * as fromGlobalSettings from '@app/user-settings/store/global-settings';

@Injectable()
export class NewsletterFormEffects {
    public formUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.formUpdated),
            map((data) => data)
        ), {dispatch: false}
    );

    public getEmailFrom$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getEmailFromNewsletter),
            mergeMap(() =>
                this.newsletterService.getEmailFrom().pipe(
                    map((fromEmail) => fromActions.getEmailFromNewsletterSuccess({fromEmail})),
                    catchError(() => of(fromActions.getEmailFromNewsletterFailure())
                    )))
        ), {dispatch: true}
    );

    public refreshNewsletterInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.refreshNewsletterInit),
            mergeMap((data) => {
                for (const channel of data.channelsId) {
                    this.store$.dispatch(fromFormOther.updateNewsForChannel({id: channel, language: data.language}));
                }
                return [fromActions.refreshNewsletterSuccess()];
            })
        ), {dispatch: true}
    );

    public putSearchingPreferencesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.putSearchingPreferencesCountRequest),
            mergeMap(({preferencesIds}) => {
                    const mappedPreferencesIds = {...preferencesIds};
                    if (!preferencesIds.locations.length) {
                        this.store$.pipe(select(fromLocationsFlat.selectRoot),
                            first(allLocations => allLocations.length > 0)).subscribe((allLocations) =>
                            mappedPreferencesIds.locations = [allLocations[0].id]);
                    }
                    if (!preferencesIds.departments.length) {
                        this.store$.pipe(select(fromDepartmentsFlat.selectRoot),
                            first(allDepartments => allDepartments.length > 0)).subscribe((allDepartments) =>
                            mappedPreferencesIds.departments = [allDepartments[0].id]);
                    }
                    if (!preferencesIds.functions.length) {
                        this.store$.pipe(select(fromFunctionsFlat.selectRoot),
                            first(allFunction => allFunction.length > 0)).subscribe((allFunction) =>
                            mappedPreferencesIds.functions = [allFunction[0].id]);
                    }

                    return this.userProfileService.putSearchingPreferencesCount(mappedPreferencesIds).pipe(
                        map((usersCount) => fromActions.putSearchingPreferencesCountSuccess({usersCount})),
                        catchError(({message}) => of(fromActions.putSearchingPreferencesCountFailure({error: message})))
                    );
                }
            )
        ), {dispatch: true}
    );

    public currentNewsMove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.reorderNews),
            switchMap(() => [fromActions.currentNewsMoved()])
        ), {dispatch: true}
    );

    public loadDynamicLinks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.loadDynamicLinks),
            switchMap(() => this.store$.pipe(
                select(fromSelectors.selectAllNewsFromSection),
                take(1),
                map((news) => ({news}))
            )),
            tap(({news}) => {
                news.forEach(n =>
                    this.newsletterService.getDynamicLink(n.id, n.title).pipe(
                        map(link => this.store$.dispatch(fromFormOther.newsDynamicLinkUpsertOne({link})))
                    ).subscribe());
            })
        ), {dispatch: false}
    );

    public getNewsletterById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsletterById),
            mergeMap((data) =>
                this.newsletterService.getNewsletter(data.id).pipe(
                    map((result) => this.newsletterService.parseContentData(result)),
                    mergeMap((loadedForm) => [fromActions.getNewsletterByIdSuccess({loadedForm})]),
                    catchError(() => of(fromActions.getNewsletterByIdWithChannelsFailure())
                    )))
        ), {dispatch: true}
    );

    public getNewsletterByIdWithChannels$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsletterByIdWithChannels),
            mergeMap((data) =>
                this.newsletterService.getNewsletter(data.id).pipe(
                    filter(result => result.templateId === 'NewsletterTemplateWithSections' || result.templateId === 'NewsletterTemplateWithoutSections'),
                    map((result) => this.newsletterService.parseContentData(result)),
                    mergeMap((loadedForm) => [fromActions.getNewsletterByIdSuccess({loadedForm}),
                        fromActions.getAllNeededNews({loadedForm}),
                        fromActions.getEmailFromNewsletter()]),
                    catchError(() => of(fromActions.getNewsletterByIdWithChannelsFailure())
                    )))
        ), {dispatch: true}
    );

    public getNewsletterByIdWithChannelsFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getNewsletterByIdWithChannelsFailure),
            map(() => fromRouter.go({path: 'newsletter', queryParams: {}}))
        ), {dispatch: true}
    );

    public getAllNeededNews$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromActions.getAllNeededNews),
            switchMap(({loadedForm}) => this.storeGlobalSettings$.pipe(select(fromLanguagesDict.selectEntityById,
                    {id: loadedForm.languageId}), filter(language => !!language), take(1),
                mergeMap((language) => [{language, loadedForm}]))),
            switchMap((results) => {
                const language = results.language;
                const loadedForm = results.loadedForm;
                const arr = [];
                const arrIds = [];
                if (loadedForm.content.sections && loadedForm.content.sections.length) {
                    for (const section of loadedForm.content.sections) {
                        for (const news of section.news) {
                            if (news && news.channelId && news.channelId[0]) {
                                const channelId = news.channelId[0];
                                if (arrIds.includes(channelId)) {
                                    continue;
                                }
                                arrIds.push(channelId);
                                this.store$.dispatch(fromNewsletter.getNewsForChannel({
                                    id: channelId,
                                    language: language.code
                                }));
                            }
                        }
                    }
                }
                return arr;
            })
        ), {dispatch: false}
    );

    public clearLoadedNewsletterPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_REQUEST),
            filter((action: any) => !action.payload.event.url.includes('/form')),
            mergeMap(() => [
                fromActions.clearLoadedForm()
            ]),
        ), {dispatch: true}
    );

    constructor(
        private actions$: Actions,
        private store$: Store<fromUiReducer.State>,
        private newsletterService: NewsletterService,
        private userProfileService: UserProfileService,
        private workdayService: WorkdayService,
        private storeGlobalSettings$: Store<fromGlobalSettings.State>,
    ) {
    }
}
