import {Component, EventEmitter, Host, Input, Optional, Output, SkipSelf} from '@angular/core';
import {ControlContainer, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as CKEDITOR from '../../../../libs/ckeditor5-build-inline';
import {RdsDialogService} from '@rds/angular-components';
import {filter, Observable} from 'rxjs';
import {
  StreamingMediaDialogComponent,
  StreamingMediaDialogData
} from './streaming-media-dialog/streaming-media-dialog.component';
import {UploadService} from '@app/core/services/newsboard/upload.service';

@Component({
  selector: 'rh-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EditorComponent
    }
  ]
})
export class EditorComponent {
  public Editor = CKEDITOR.NewsEditor;
  public CKEditorInspector = CKEDITOR.CKEditorInspector;

  html: string;

  @Input() formControl!: FormControl;
  @Input() formControlName!: string;

  @Output() uploadStarted: EventEmitter<string> = new EventEmitter();
  @Output() uploadEnded: EventEmitter<string> = new EventEmitter();
  @Output() uploadAborted: EventEmitter<string> = new EventEmitter();
  @Output() uploadFailed: EventEmitter<string> = new EventEmitter();

  onChange = (file) => {};

  onTouched = () => {};

  touched = false;
  disabled = false;

  writeValue(html: string) {
    this.html = html;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  get control() {
    return this.formControl || this.controlContainer.control?.get(this.formControlName);
  }

  constructor(
    private dialogService: RdsDialogService,
    private uploadService: UploadService,
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {
  }


  onEditorReady(editor) {
    if (!!this.CKEditorInspector) {
      this.CKEditorInspector.attach(editor);
    }
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      loader.on('change:status', (evt, name, val, oldVal) => {
        if (oldVal === undefined && val === 'idle') {
          this.uploadStarted.emit(evt.source.id);
        }
        if (oldVal === 'uploading' && val === 'idle') {
          this.uploadEnded.emit(evt.source.id);
        }
        if (val === 'aborted') {
          this.uploadAborted.emit(evt.source.id);
        }
        if (val === 'error') {
          this.uploadFailed.emit(evt.source.id);
        }
      });
      loader.on('change:uploaded', (evt, name, val, oldVal) => {

      })
      return new MyUploadAdapter(loader, this.uploadService);
    };
    editor.plugins.get('Notification').on('show:warning:upload', (evt, data) => {
      // this.store$.dispatch(fromForm.uploadImageFailure({error: data.title}))
      // evt.stop();
    });

    editor.commands.get('mediaEmbed').on('execute', (evt, urls) => {
      if (urls[0].includes('streamingmedia.roche.com')) {
        const data: StreamingMediaDialogData = {
          url: urls[0]
        }
        const dialog = this.dialogService.open(StreamingMediaDialogComponent, {
          data,
        });

        dialog.afterClosed().pipe(
          filter(confirmed => !confirmed)
        ).subscribe(() => {
          editor.execute('delete')
        });
      }
    })
    editor.listenTo(editor.plugins.get('ClipboardPipeline'), 'inputTransformation', () => {
      const modelDocument = editor.model.document;
      const firstRange = modelDocument.selection.getFirstRange();

      const leftLivePosition = CKEDITOR.LivePosition.fromPosition(firstRange.start);
      leftLivePosition.stickiness = 'toPrevious';

      const rightLivePosition = CKEDITOR.LivePosition.fromPosition(firstRange.end);
      rightLivePosition.stickiness = 'toNext';
      modelDocument.once('change:data', () => {
        const urlRange = new CKEDITOR.LiveRange(leftLivePosition, rightLivePosition);
        const walker = urlRange.getWalker({ignoreElementEnd: true});

        let url = '';

        for (const node of walker) {
          if (node.item.is('$textProxy')) {
            url += node.item.data;
          }
        }

        url = url.trim();
        if (url.includes('streamingmedia.roche.com')) {
          const data: StreamingMediaDialogData = {
            url
          }
          const dialog = this.dialogService.open(StreamingMediaDialogComponent, {
            data,
          });

          dialog.afterClosed().pipe(
            filter(confirmed => !confirmed)
          ).subscribe(() => {
            editor.execute('delete')
          });
        }

      }, {priority: 'high'});
    });

    // if (!!this.content.controls.content.value) {
    //   editor.setData(this.content.controls.content.value)
    // }
  }
}


// TODO: move to own file and find better way to integrate
class MyUploadAdapter {
  constructor(private loader: any, private service: UploadService) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    // Update the loader's progress.

    // Return a promise that will be resolved when the file is uploaded.
    return this.loader.file.then((file) => {
      return new Observable((obs) => {
        this.service.uploadFile(file).subscribe((resp) => {
          obs.next({default: resp.url});
          obs.complete();
        });
      }).toPromise();
    });
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
  }

}
