import { Base64 } from 'js-base64';

export default function AddSesTagsToLinks( editor ) {
    editor.conversion.for( 'downcast' ).add( dispatcher => {
        dispatcher.on( 'attribute:linkHref', ( evt, data, conversionApi ) => {
            const viewWriter = conversionApi.writer;
            const viewSelection = viewWriter.document.selection;
            const encodedName = Base64.encodeURI(data.item.data);
            console.log(viewWriter, viewSelection, encodedName, data, evt, conversionApi);
            const viewElement = viewWriter.createAttributeElement( 'a', 
                { 'ses:tags': `type:4;name:${encodedName};linkId:GUID_GOES_HERE` },
                { priority: 5 } 
            );

            if ( data.item.is( 'selection' ) ) {
                viewWriter.wrap( viewSelection.getFirstRange(), viewElement );
            } else {
                viewWriter.wrap( conversionApi.mapper.toViewRange( data.range ), viewElement );
            }
        }, { priority: 'low' } );
    } );
}