// import { createAction, props } from '@ngrx/store';

import { Pagination } from '@app/core/models';
import { SimpleUser } from '@app/core/models/newsboard';
import { Template, TemplatesListType, TemplatesRefresh, TemplateUpdate } from '@app/core/models/newsboard/template';
import { BackButton } from '@app/root-store/ui/back-button';
import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { TemplatePreviewDialogComponent } from '@app/newsletter-new/dialogs/template-preview-dialog/template-preview-dialog.component';

TemplatePreviewDialogComponent;

export const preview = createActionGroup({
	source: 'TEMPLATES LIST preview',
	events: {
		dialog: props<{
			id: number;
			backButton?: BackButton;
		}>(),
	},
});

export const deleteTemplate = createActionGroup({
	source: 'TEMPLATES LIST delete',
	events: {
		dialog: props<{
			id: number;
			listType: TemplatesListType;
		}>(),
		request: props<{
			id: number;
			listType: TemplatesListType;
		}>(),
		success: props<{
			id: number;
			listType: TemplatesListType;
		}>(),
		failure: props<{
			message: string;
		}>(),
	},
});

export const lists = createActionGroup({
	source: 'TEMPLATES LIST',
	events: {
		init: emptyProps(),
		getMy: props<{
			refresh?: TemplatesRefresh;
		}>(),
		getShared: props<{
			refresh?: TemplatesRefresh;
		}>(),
		getReadonly: props<{
			refresh?: TemplatesRefresh;
		}>(),
		getPredefined: props<{
			refresh?: TemplatesRefresh;
		}>(),
		getSentToMe: props<{
			refresh?: TemplatesRefresh;
		}>(),
		request: props<{
			listType: TemplatesListType;
			refresh?: TemplatesRefresh;
			pagination: Pagination;
		}>(),
		success: props<{
			listType: TemplatesListType;
			data: Array<Template>;
			pagination: Pagination;
			refresh?: TemplatesRefresh;
		}>(),
		failure: props<{
			listType: TemplatesListType;
			error: any;
		}>(),
		loadMore: props<{
			listType: TemplatesListType;
		}>(),
		incrementTotalCount: props<{
			listType: TemplatesListType;
			by: number;
		}>(),
	},
});
export const acceptSentToMe = createActionGroup({
	source: 'TEMPLATES LIST accept',
	events: {
		request: props<{
			template: Template;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: any;
		}>(),
	},
});

export const rejectSentToMe = createActionGroup({
	source: 'TEMPLATES LIST reject',
	events: {
		request: props<{
			template: Template;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: any;
		}>(),
	},
});
export const sendCopy = createActionGroup({
	source: 'TEMPLATES LIST send copy',
	events: {
		dialog: props<{
			template: Template;
		}>(),
		request: props<{
			id: number;
			users: Array<SimpleUser>;
		}>(),
		success: emptyProps(),
		failure: props<{
			error: any;
		}>(),
	},
});

export const updateTemplate = createActionGroup({
	source: 'TEMPLATES LIST update',
	events: {
		renameDialog: props<{
			template: Template;
			listType: TemplatesListType;
		}>(),
		request: props<{
			id: number;
			templateUpdate: Partial<TemplateUpdate>;
			updateContext: string;
			listType: TemplatesListType;
			listsToRefresh: Array<{
				list: TemplatesListType;
				refresh: TemplatesRefresh;
			}>;
		}>(),
		success: props<{
			updateContext: string;
			listType: TemplatesListType;
			listsToRefresh: Array<{
				list: TemplatesListType;
				refresh: TemplatesRefresh;
			}>;
		}>(),
		failure: props<{
			error: any;
		}>(),
	},
});

export const shareTemplate = createActionGroup({
	source: 'TEMPLATES LIST share template',
	events: {
		request: props<{
			id: number;
			users: any[];
			listType: TemplatesListType;
			listsToRefresh: Array<{
				list: TemplatesListType;
				refresh: TemplatesRefresh;
			}>;
			updateContext: string;
		}>(),
		success: props<{
			updateContext: string;
			listType: TemplatesListType;
			listsToRefresh: Array<{
				list: TemplatesListType;
				refresh: TemplatesRefresh;
			}>;
		}>(),
		failure: props<{
			error: any;
		}>(),
		dialog: props<{
			template: Template;
			listType: TemplatesListType;
		}>(),
	},
});

export const useTemplate = createAction(
	'[NEWSLETTER TEMPLATES] Use template',
	props<{
		id: number;
		listType: TemplatesListType;
	}>()
);

export const clear = createAction('[NEWSLETTER TEMPLATES] Clear');
