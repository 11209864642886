import {Action, createReducer, on} from '@ngrx/store';
import { NewsletterValueModel} from '@app/newsletter/models';
import * as fromActions from '@app/newsletter/store/newsletter-form/newsletter-form.actions';
import * as fromDashboard from '@app/newsletter/store/newsletter-dashboard/newsletter-dashboard.actions';
import { NewsletterEditType } from '@app/newsletter/models/newsletter-edit-type.enum';
import { moveItemInArray } from '@angular/cdk/drag-drop';

export interface State {
  editType: NewsletterEditType;
  form: NewsletterValueModel;
  fromEmail: string;
  refreshed: true | false;
  usersCount: number;
  newsMoved: true | false;
  isLoaded: true | false;
  loadedForm: NewsletterValueModel;
}

export const initialState: State = {
  editType: null,
  form: null,
  fromEmail: '',
  refreshed: false,
  usersCount: null,
  newsMoved: false,
  loadedForm: null,
  isLoaded: false
};

export const newsletterFormReducer = createReducer(
  initialState,
  on(fromActions.formUpdated, (state, {form}) => ({
    ...state,
    form: {...state.form, ...form}
  })),
  on(fromActions.changeTemplate, (state, {templateId}) => ({
    ...state,
    form: {...state.form, templateId}
  })),
  on(fromDashboard.changeColor, (state, {selectedColor}) => ({
    ...state,
    form: {...state.form, color: selectedColor.label, colorDark: selectedColor.valueDark, colorLight: selectedColor.valueLight}
  })),
  on(fromActions.getEmailFromNewsletterSuccess, (state, {fromEmail}) => ({
    ...state,
    fromEmail
  })),
  on(fromActions.refreshNewsletterInit, (state, {}) => ({
    ...state,
    refreshed: false
  })),
  on(fromActions.refreshNewsletterSuccess, (state, {}) => ({
    ...state,
    refreshed: true
  })),
  on(fromActions.putSearchingPreferencesCountSuccess, (state, {usersCount}) => ({
    ...state,
    usersCount
  })),
  on(fromActions.reorderNews, (state, { sectionIndex, oldNewsIndex, newNewsIndex }) => {
    const sections = state.form.content.sections;
    moveItemInArray(sections[sectionIndex].news, oldNewsIndex, newNewsIndex);

    return {
      ...state,
      form: {
        ...state.form,
        sections: [...sections]
      }
    };
  }),
  on(fromActions.currentNewsMoved, (state) => ({
    ...state,
    newsMoved: true
  })),
  on(fromActions.clearMoved, (state) => ({
    ...state,
    newsMoved: false
  })),
  on(fromActions.clearLoadedForm, (state) => ({
    ...state,
    loadedForm: null,
    isLoaded: false,
    form: null
  })),
  on(fromActions.clearForm, (state) => ({
    ...state,
    form: null
  })),
  on(fromActions.getNewsletterByIdSuccess, (state, {loadedForm}) => ({
    ...state,
    loadedForm,
    isLoaded: true
  })),
  on(fromActions.setEditType, (state, {editType}) => ({
    ...state,
    editType
  })),
  on(fromActions.clearEditType, (state) => ({
    ...state,
    editType: null
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return newsletterFormReducer(state, action);
}

export const getForm = (state: State) => state.form;
export const getEditType = (state: State) => state.editType;
export const getSections = (form: NewsletterValueModel) => form.content && form.content.sections;
export const getFromEmail = (state: State) => state.fromEmail;
export const getRefreshed = (state: State) => state.refreshed;
export const getUsersCount = (state: State) => state.usersCount;
export const getNewsMoved = (state: State) => state.newsMoved;
export const getLoaded = (state: State) => state.loadedForm;
export const getIsLoaded = (state: State) => state.isLoaded;
